.pt_account {
    .inner-block {
        &.check-order {
            h2 {
                font-size: $base-font + 13px;
                text-transform: uppercase;
                @include Univers($UniversBoldCondensed);
            }
        }
    }
    .col-2 {
        h2 {
            @include Univers($UniversRoman);
            font-size: $base-font + 2px;
        }
    }
    .create-login {
        .inner-block {
            .login-box {
                .error-form {
                    background-color: transparent;
                    border: none;
                }
                h1 {
                    color: $navy-blue;
                    text-transform: uppercase;
                    @include Univers($UniversBoldCondensed);
                }
                h2 {
                    color: $navy-blue;
                }
                .login-data {
                    span {
                        color: $navy-blue;
                    }
                    .login-email {
                        text-transform: uppercase;
                        font-size: $base-font;
                    }
                }
                .form-row-button {
                    .input-focus {
                        span {
                            &.label-text {
                                font-size: $base-font;
                                color: $KS-dark-grey;
                            }
                        }
                    }
                }
            }
        }
        button {
            font-size: $base-font + 6px;
        }
    }
    .check-order {
        p {
            font-size: $base-font + 2px;
            @include Univers($UniversRoman);
            color: $navy-blue;
        }
        .ordererror {
            border: none;
            background-color: $wild-sand;
            padding: 0 0 10px 0;
            color: $red;
        }
    }
    .registration-reward {
        h3 {
            letter-spacing: 1.5px;
        }
        p {
            margin: 15px 0 30px 0;
        }
    }
    .create-login {
        .login-create-account {
            .legal {
                @include Univers($UniversCondensed);
                font-size: $base-font + 1px;
            }
            .form-row {
                &.addtoemaillist {
                    label {
                        @media screen and (max-width: 767px) {
                            width: 83%;
                        }
                    }
                }
            }
        }
        .login-create {
            h1 {
                color: $navy-blue;
                text-transform: uppercase;
                @include Univers($UniversBoldCondensed);
            }
            h2 {
                color: $navy-blue;
            }
            .registration-reward {
                p {
                    @include Univers($UniversRoman);
                    color: $navy-blue;
                }
            }
        }
    }
    .registration-reward {
        color: $navy-blue;
        h2 {
            font-size: $base-font + 10px;
            @include Univers($UniversBoldCondensed);
            text-transform: capitalize;
        }
        h3 {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 4px;
            text-transform: uppercase;
        }
        p {
            font-size: $base-font + 2px;
        }
    }
}
.create-login {
    .form-caption {
        @include Univers($UniversRoman);
        margin-left: 0;
    }
}
@media screen and (max-width: 767px) {
    .pt_account {
        .create-login {
            button {
                padding: 14px 25px;
            }
            h1 {
                .dialog-required {
                    display: none;
                }
            }
        }
        .col-2 {
            .label-inline {
                .field-wrapper {
                    margin: 0 10px 0 0;
                }
            }
        }
        .benfits-content {
            .registration-reward {
                h2 {
                    font-size: $base-font + 10px;
                }
            }
        }
        .registration-reward {
            p {
                margin: 0 0 30px 0;
            }
            h3 {
                margin: 0;
            }
        }
    }
    .create-login {
        .inner-block {
            .login-box {
                &.login-create-account {
                    .create-account {
                        padding: 14px 48px;
                    }
                }
            }
        }
    }
}
