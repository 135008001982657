@media screen and (max-width: 1023px) {
    .slider-menu {
        #navigation {
            .level-1 {
                .has-sub-menu-parent-level3 {
                    &.active {
                        .parent-level3 {
                            .has-sub-menu-level2-heading {
                                background: $navy-blue;
                                color: $white;
                            }
                        }
                    }
                }
                .has-sub-menu-parent-level-4 {
                    &.active {
                        .parent-level4 {
                            .has-sub-menu-level3-heading {
                                background: $navy-blue;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .menu-category .hoverIntent .has-sub-menu::after {
        border: 2px solid $navy-blue;
        border-radius: 0;
    }
}
.menu-category {
    a {
        @include WorkSansMedium($WorkSans);
        @media (min-width: 1024px) {
            &[title="Clearance"],
            &[title="Final Sale"] {
                color: $guardsman-Red !important;
            }
        }
    }
}
.level-2 {
    @media screen and (min-width: 1024px) {
        a {
            &:hover {
                color: $navy-blue;
            }
        }
        > ul {
            > li {
                .boldCat {
                    a {
                        @include WorkSansMedium($WorkSans);
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .menu-footer
        .email-signup-footer-form
        .form-row
        .field-wrapper
        input[type="text"].email {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border: 2px solid #002953;
        height: 46px;
    }
    .footer-email-signup
        .email-signup.make-label-absolute
        .form-row.form-row-button
        button {
        height: 46px;
    }
    .slider-menu {
        #navigation {
            .menu-utility {
                .shopall-brands {
                    font-family: $Lora;
                }
                .navigation-scroll-inner {
                    background: $wild-sand;
                }
                .menu-footer {
                    .footer-nav {
                        .footer-email-heading {
                            font-family: $Lora;
                        }
                        p {
                            font-family: $Lora;
                        }
                        .footer-email-signup {
                            .email-signup {
                                input[type="text"] {
                                    font-family: $WorkSans;
                                }
                                button {
                                    background-color: $navy-blue;
                                    font-family: $WorkSans;
                                }
                            }
                        }
                        .footer_list_wrapper {
                            .footer_item {
                                &:nth-child(1) {
                                    .footer-heading {
                                        @include WorkSansBold($WorkSans);
                                    }
                                    ul {
                                        li {
                                            a {
                                                font-family: $WorkSans;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .slider-menu {
            #navigation {
                .mobile-sign-in {
                    .right-section {
                        .info {
                            font-family: "Helvetica Neue LT W01_65 Md";
                        }
                        .user-info {
                            .login-user {
                                @include WorkSansMedium($WorkSans);
                                .my-account {
                                    @include WorkSansMedium($WorkSans);
                                }
                            }
                            span {
                                &.username {
                                    @include WorkSansMedium($WorkSans);
                                }
                            }
                        }
                    }
                }
                .menu-footer {
                    .primary-links {
                        h2 {
                            @include WorkSansMedium($WorkSans);
                        }
                        span {
                            font-family: $WorkSans !important;
                        }
                        .bcText {
                            font-family: "Work Sans", sans-serif;
                        }
                    }
                    .cs-flyout-quick-links {
                        .links {
                            font-family: $Lora;
                        }
                        a {
                            @include WorkSansMedium($WorkSans);
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .nonBoldCat {
            font-family: $Helvetica;
            a {
                font-family: $WorkSans !important;
            }
        }
        .boldCat {
            font-family: $HelveticaBold;
            a {
                font-family: $HelveticaBold !important;
            }
        }
    }
    /* Navigation for responsive */
    @media screen and (min-width: 1024px) {
        .product-list-page {
            #navigation {
                .menu-category {
                    li {
                        &.selected {
                            > a {
                                color: $navy-blue;
                                border-bottom: 4px solid $navy-blue;
                            }
                            .level-2 {
                                > a {
                                    color: $black;
                                    border-bottom: 4px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        .slider-menu {
            #navigation {
                .menu-category {
                    a {
                        @include WorkSansSemiBold($WorkSans);
                    }
                }
                .level-1 {
                    li {
                        .has-sub-menu {
                            font-family: $Lora;
                        }
                        .has-sub-menu {
                            background: $wild-sand;
                            @include WorkSansSemiBold($WorkSans);
                        }
                    }
                }
            }
        }
    }
}
#wrapper.menu-active {
    @media screen and (max-width: 1023px) {
        .top-banner .header-mian-banner {
            .menuUnderLay {
                &:after {
                    background: $wild-sand;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .slider-menu {
            background: $wild-sand;
        }
    }
}
//act-menu css
@media screen and (max-width: 767px) {
    #wrapper.menu-active.account-active {
        #header .top-menu-utility {
            .menu-utility .menu-utility-user {
                .right-section .user-info {
                    .user-panel.registered-user {
                        .my-account.heading {
                            font-family: $Lora;
                        }
                    }
                }
            }
        }
    }
}
//SC-3513
@media screen and (min-width: 1024px) {
    .menu-category {
        .hoverIntent {
            .level-2 {
                &.new-mega-menu-L2 {
                    background: $white;
                    border-bottom: 4px solid $navy-blue;
                }
                .sub-cat-wrapper {
                    &.new-mega-menu {
                        border-bottom: 0;
                        background: $white;

                        .nonBoldCat {
                            a {
                                color: $black;
                                &:hover {
                                    color: $navy-blue;
                                }
                            }
                        }
                        .boldCat {
                            a {
                                color: $navy-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}
