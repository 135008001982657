/* Body */
body {
    @include Univers($UniversRoman);
    font-size: $base-font + 1px;
    a {
        color: $navy-blue;
    }
}
/* Navigation */
nav a {
    @include Univers($UniversCondensed);
    font-size: $base-font + 1px;
    color: $navy-blue;
}
/* Form */
form label {
    font-family: $UniversCondensed;
    font-size: $base-font + 1px;
    color: $nobel;
}
input[type="checkbox"] {
    &:checked {
        background: url("../../../images/check.png") no-repeat center;
    }
}
textarea {
    resize: none;
}
/* Table */
table {
    width: 100%;
}
th {
    text-align: left;
}
td {
    padding: 1em;
    vertical-align: top;
}
/* CTA */
button.solid-wine-berry,
button.solid-wine-berry:hover,
button.button.solid-wine-berry:hover,
button.buttonstyle.solid-wine-berry:hover,
.button.solid-wine-berry,
.button.solid-wine-berry:hover,
input.button[type="button"].solid-wine-berry:hover,
.buttonstyle.solid-wine-berry,
.buttonstyle.solid-wine-berry:hover,
input.buttonstyle[type="button"].solid-wine-berry:hover,
input[type="button"].solid-wine-berry,
input[type="button"].solid-wine-berry:hover,
button:not(.btn-ds):not([class*="tt-o-button"]),
.button,
.buttonstyle,
input[type="button"] {
    background: $navy-blue;
    border-color: $navy-blue;
    border-style: solid;
    border-width: 2px;
    border-radius: $KS-Btn-radius;
    color: $white;
    cursor: pointer;
    display: inline-block;
    padding: 14px 30px;
    text-align: center;
    transition: all 0.3s ease;
    text-transform: uppercase;
    outline: 0;
    @include Univers($UniversBoldCondensed);
    letter-spacing: 1px;
    font-size: $base-font + 6px;
    &[disabled],
    &.disabled,
    &.atb-disabled {
        color: $white;
    }
    &:hover {
        background: $KS-sky-blue;
        border-color: $KS-sky-blue;
    }
    &.simple {
        background-color: $white;
        color: darken($citrus, 10%);
    }
    a {
        color: $white;
        &:hover {
            color: $citrus;
        }
    }
    &.pink {
        background: $navy-blue;
        border-color: $navy-blue;
        color: $white;
    }
    &.transparent {
        background: transparent;
        border-color: $white;
        color: $white;
    }
    &.white {
        border-color: $white;
    }
    &.transperent-site-specific {
        background: transparent;
        border-color: $navy-blue;
        color: $navy-blue;
        &:hover {
            @extend .transperent-site-specific;
        }
    }
}
.buttonOulined {
    background: $white;
    border: 1px solid #aaaaaa;
    color: #002953;
}
.button-text {
    color: $black;
}
/* Back To Top */
.pt_product-search-result {
    a {
        &.back-to-top {
            width: 50px;
            height: 48px;
            right: 15px;
            bottom: 25%;
            background: url("../../../images/ks/TopIcon_Blue.svg") no-repeat
                center 43% transparent;
            background-size: 100%;
        }
    }
}
@media only screen and (max-width: 767px) {
    button:not(.btn-ds):not([class*="tt-o-button"]),
    .button,
    .buttonstyle,
    input[type="button"] {
        &.solid-wine-berry,
        &.transperent-site-specific {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }
}
