$sans-serif: "Inter Tight", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$serif: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$Univers: "Barlow", $sans-serif;
$UniversLightOblique: "Barlow", $sans-serif;
$UniversRoman: "Barlow", $sans-serif;
$UniversRomanOblique: "Barlow", $sans-serif;
$UniversBold: "Barlow", $sans-serif;
$UniversBoldOblique: "Barlow", $sans-serif;
$UniversLightCondensed: "Barlow Condensed", $sans-serif;
$UniversLightCondensedOblique: "Barlow Condensed", $sans-serif;
$UniversCondensed: "Barlow Condensed", $sans-serif;
$UniversCondensedOblique: "Barlow Condensed", $sans-serif;
$UniversBoldCondensed: "Barlow Condensed", $sans-serif;
$UniversBoldCondensedOblique: "Barlow Condensed", $sans-serif;

$Helvetica : $sans-serif;
$HelveticaBold: $sans-serif;
$HelveticaMedium: $sans-serif;
$Lora: $serif;
$WorkSans: $sans-serif;
$sans-serif-alt: $sans-serif;
$BauerBodoniRegular: $sans-serif;
$ImperialRegular: $serif;
$ImperialMedium: $serif;
$HurmeBlack: $sans-serif;
$HurmeLight: $sans-serif;
