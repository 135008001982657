footer {
    background-color:  $white;
    float: none;
}
footer .footer-container {
    
    &.success-message {
    	.footer-email-signup-message {
    		display: none;
    	}
    	
    	.footer-email-signup {
    		width: auto;
    	}
    	
    	.email-signup {
    		&.make-label-absolute {
    			margin: 40px 0;
    		}
    	}
    	
        .email-signup-footer-success {
        	text-align: center;
        	
        	.email-signup-footer-success-heading {
        		font-size: $base-font + 6px;
                @include Univers($UniversCondensed);
                letter-spacing: 1px;
        		margin: 0 auto 15px;
                float:none;
        		color: $navy-blue;
        	}
        	
        	.promo-code {
        		font-size: $base-font + 2px;
        		@include Univers($UniversRoman);
        		margin: 0 0 13px 0;
        		color: $navy-blue;
                float:none;
                b{
                    @include Univers($UniversBold);
                }
        	}
        	
        	p {
        		font-size: $base-font + 2px;
        		@include Univers($UniversRoman);
        		color: $navy-blue;
        	}
        }
    }
    
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: outside none none;
            margin: 0;
            padding: 0;
            line-height: normal;
            a {
                margin: 0;
                padding: 0;
            }
        }
    }
    p {
        margin: 0;
        padding: 0;
        line-height: normal;
    }
    .footer-email-signup-message {
        float: none;
        width: 100%;
        color:$navy-blue;
        .footer-email-heading {
            text-align: center;
            @include Univers($UniversBoldCondensed);
            letter-spacing:1px;
            font-size: $base-font + 16px;
            text-transform: uppercase;
            margin: 72px 0 8px 0;
        }
        p {
            text-align: center;
            @include Univers($UniversCondensed);
            color: $navy-blue;
            font-size: $base-font + 10px;
            margin: 13px auto;
        }
    }
    .footer-email-signup {
        display: table;
        margin: auto;
        width:100%;
        max-width: 435px;
        .email-signup {
            margin: 20px 0;
            .form-row {
                input[type="text"] {
                    width: 100%;
                    height: 50px;
                    padding: 15px;
                    border: 1px solid $navy-blue;
                    border-right: none;
                    -webkit-border-top-left-radius: $KS-Btn-radius;
                    -webkit-border-bottom-left-radius: $KS-Btn-radius;
                    -moz-border-radius-topleft: $KS-Btn-radius;
                    -moz-border-radius-bottomleft: $KS-Btn-radius;
                    border-top-left-radius: $KS-Btn-radius;
                    border-bottom-left-radius: $KS-Btn-radius;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    font-family: $WorkSans;
                    &.focus-outline:focus{
                        margin: 2px;                     
                    }
                }
                .error {
                    clear: both;
                    padding-top: 5px;
                }
                span {
                    @include Univers($UniversRoman);
                    font-size: 14px;
                    padding:0 0 0 26px;
                }
            }
            .form-row-button {
                width:auto;
                background:$navy-blue;
                max-width:125px;
                -webkit-border-top-right-radius: $KS-Btn-radius;
                -webkit-border-bottom-right-radius: $KS-Btn-radius;
                -moz-border-radius-topright: $KS-Btn-radius;
                -moz-border-radius-bottomright: $KS-Btn-radius;
                border-top-right-radius: $KS-Btn-radius;
                border-bottom-right-radius: $KS-Btn-radius;
                button {
                    background-color: transparent;
                    text-transform: uppercase;
                    -webkit-border-top-right-radius: $KS-Btn-radius;
                    -webkit-border-bottom-right-radius: $KS-Btn-radius;
                    -moz-border-radius-topright: $KS-Btn-radius;
                    -moz-border-radius-bottomright: $KS-Btn-radius;
                    border-top-right-radius: $KS-Btn-radius;
                    border-bottom-right-radius: $KS-Btn-radius;
                    font-size: $base-font + 6px;
                    letter-spacing: 1px;
                    &.focus-outline:focus{
                        margin: 2px;
                        padding-top: 12.5px;
                        padding-bottom: 12.5px;
                        width: 70%;
                        padding-left: 22px;
                    }
                }
            }
        }
    }
    .footer-social-icon {
        position: relative;
        float: none;
        margin:40px 0 0;
        .fo-social {
            ul {
                li {	
                    &.instagram {
                        a {
                            background-position: -17px -16px;
                        }
                    }
                    &.pintrest {
                        a {
                            background-position: -53px -15px;
                        }
                    }
                    &.facebook {
                        a {
                            background-position: -83px -16px;
                        }
                    }
                    &.twitter {
                        a {
                            background-position: -114px -16px;
                        }
                    }
                    &.youtube {
                        a {
                            background-position: -157px -14px;
                        }
                    }
                }
            }
        }
    }
    .footer-links {
        width: 100%;
        border-bottom: 1px solid $KS-dark-grey;
        float: none;
        .footer_list_wrapper {
            margin: auto;
            width: 100%;
            display:table;
            border-spacing:20px 0;
            .footer_item {
                display:table-cell;
                width: 25%;
                vertical-align:top;
                float:none;
                ul {
                    margin: 0 0 32px;
                    padding: 0;
                    li {
                        padding-top: 7px;
                    }
                }
                a {
                    text-transform: capitalize;
                    font-size: $base-font + 2px;
                    font-weight: normal;
                    @include Univers($UniversRoman);
                    color:$navy-blue;
                    line-height: 30px;
                    &:hover {
                        color: $KS-dark-grey;
                    }
                }
                .footer-heading , .footer-heading a{
                     @include Univers($UniversBoldCondensed);
                    text-transform: uppercase;
                    font-size: $base-font + 6px;
                    margin: 0;
                    border-bottom:1px solid $KS-dark-grey;
                    color:$navy-blue;
                    padding:0 0 15px;
                    max-width:260px;
                }
                .footer-heading a{
                    border-bottom: none;
                    padding: 0;
                    line-height: normal;
                }
                
            }
            @media screen and (max-width: 768px) {
                .footer_item {
                    a {
                        font-size: $base-font + 1px;
                    }
                }
            }
            @media screen and (max-width: 767px){
                display:block;
                padding: 0 0 20px 0;
                .footer_item {
                    display:block;
                    width:100%;
                    .footer-heading {
                        display: table;
                        margin: 0 auto;
                        width:auto;
                    }
                }
                
            }
        }
    }
    .footer-item {
        box-sizing: border-box;
        display: block;
        padding: .5em 1.5em;
        width: 100%;
        @media screen and (min-width: 768px) {
            display: table-cell;
            padding: 1.5em;
            width: 25%;
        }
    }
    h3 {
        text-transform: uppercase;
    }
    .menu-footer {
        float: none;
        margin: .5em;
        padding: 0;
        @media screen and (min-width: 768px) {
            margin: 1em 0;
        }
        li {
            float: none;
            line-height: 2.5em;
            list-style: none !important;
        }
    }
    a {
        &:hover {
            color: $very-light-gray;
            text-decoration: none;
        }
    }
    .footer-brand-selector {
        width: 100%;
        float: none;
        .footer-brand-heading {
            @include Univers($UniversBoldCondensed);
            text-transform: uppercase;
            font-size: $base-font + 6px ;
            margin: 0;
            padding-bottom: 23px;
            padding-top: 27px;
            text-align: center;
            width: 100%;
            color: $navy-blue;
        }
        .brands-list {
            ul {
                li {
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .footer-legal-copyrights {
        width: 100%;
        float: none;
        .static-links {
            display: table;
            margin: auto auto 15px;
            text-align: center;
            ul {
                li {
                    display: inline-block;
                    margin-right: 10px;
                    a {
                        text-decoration: underline;
                        @include Univers($UniversRoman);
                        color:$navy-blue;
                        &:hover {
                            color: $dim-gray;
                        }
                    }
                }
            }
        }
        .copyrights-wrapper {
            width: 100%;
            margin-bottom: 25px;
            float:none;
            p {
                text-align: center; 
                width: 100%;
                @include Univers($UniversRoman);
                color:$navy-blue;
                font-size: $base-font;
                letter-spacing: 0.025em;
                line-height: 20px;
                float:none;
            }
            .copy-arr {
                margin-bottom: 1px;
                @include Univers($UniversRoman);
                color:$navy-blue;
            }
        }
        .content-asset{
            float: none;
        }
    }
}
.footer-container {
    border-top:1px solid $KS-dark-grey;
    &.footer-down {
        border-top: inherit;
        background-color: $white;
        width: 100%;
        margin: 70px auto auto;
        display: inline-block;
        .footer-down-inner {
            max-width: 1260px;
            width:auto;
            margin:0  auto;
        }
    }
    .footer-social-icon {
        .fo-border{
            border-bottom:1px solid $KS-dark-grey;
        }
    }
    .email-signup {
        &.make-label-absolute {
            width:100%;
        }
    }
}
@media screen and (max-width: 767px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 96%;
            }
            &.footer-down{ 
            	margin-top: 0;
            	.footer-down-inner {
	    				 margin-top: 0;
	    			}
			}
            .footer-legal-copyrights{
                .static-links{
                    ul {
                        li {
                            display: block;
                            margin:0 auto 20px; 
                            img{
                                display: none;
                                border:1px solid;
                            }
                        }
                    }
                } 
            }
            &.footer-down{
            .footer-brand-selector .brands-list ul{
                display: grid;
                grid-template-columns: auto auto auto auto;
                &:before,&:after{
                    display: none;
                }
                li{
                    // all !important properties will be refactored in the common file after sitewide launch. - DGrant
                    margin: 0 !important;
                    padding:0 !important;
                    a {
                        padding: 0 0 30px;
                        img{
                        margin:0 auto;
                        display: block;
                        height:auto !important;
                    }
                }
                }
                @media screen and (max-width: 480px) {
                grid-template-columns: auto auto;
                }
            }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    footer {
        .footer-container {
            .footer-email-signup {
                width: 52%;
                .email-signup {
                    margin: 14px 0;
                }
            }
            &.footer-down{ 
            	.footer-down-inner {
	    				 margin: 0 13px 0;
	    			}
			}
            .footer-links {
                .footer_list_wrapper {
                    border-spacing: 15px 0;
                    .footer_item {
                        ul {
                            margin: 0 0 22px;
                            li {
                                padding-top: 11px;
                            }
                        }
                       .footer-heading {
                       		font-size: $base-font + 4px;
                       		a {
                       			font-size: $base-font + 4px;
                       		}
                       }
                    }
                }
            }
            .footer-brand-selector {
                margin-bottom: 18px;
                .footer-brand-heading {
                    padding-bottom: 37px;
                    padding-top: 34px;
                }
            }
        }
    }
    
    .footer-nav .footer-links .footer_list_wrapper .footer_item .footer-heading a{
        text-transform: uppercase;
        @include Univers($UniversCondensed);
   } 
}
@media screen and (min-width: 1260px) {
    .footer-container {
    	.footer-links {
    		 .footer_list_wrapper {
				.footer_item { 
                    min-width: 260px;
					&:nth-child(1) {
						margin-left: auto;
						width: auto;
					}
					&:nth-child(2) {
						width: auto;
					}
					&:nth-child(3) {
						 width: auto;
					}
					&:nth-child(4) {
						 width: auto;
					}
				}
			}
		}
	}			
}
// WCAG focus outline issue on Firefox
@-moz-document url-prefix() { 
    .footer-plcc .section.left-section .text-section a {
        &.focus-outline:focus{
            line-height: 23px;
            span{margin-top: 4px !important;}
        }
    }
}