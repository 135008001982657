.category-refinement {
    #category-level-3 li.expandable {
        &.non-active {
            ul {
                display: none !important;
            }
        }
    }
}
#category-level-1 {
    li {
        .refinement-link {
            @include Univers($UniversCondensed);
            font-size: $base-font + 2px;
            color: $navy-blue;
            &.active {
                color: $navy-blue;
                @include Univers($UniversBoldCondensed);
                letter-spacing: 1px;
            }
            &:hover {
                color: $navy-blue;
                @include Univers($UniversBoldCondensed);
                letter-spacing: 1px;
            }
        }
    }
    > li {
        > .refinement-link {
            font-size: $base-font + 2px;
        }
    }
}
#category-level-2 {
    > li {
        &.expandable {
            font-weight: bold;
            color: $navy-blue;
        }
    }
    .currCategory .cr-section a.active {
        color: $navy-blue !important;
    }
}
.category-refinement {
    #category-level-1 {
        li {
            a {
                font-size: $base-font + 2px;
            }
            .cr-section {
                a {
                    &.active {
                        font-size: $base-font - 1px;
                    }
                }
            }
        }
        #category-level-2 > li {
            &.expandable {
                .cr-section a {
                    color: $black;
                    font-size: $base-font + 2px;
                    &.active {
                        font-size: $base-font + 2px;
                    }
                    &:hover {
                        color: $navy-blue;
                    }
                }
                #category-level-3 li {
                    a {
                        &.active {
                            font-size: $base-font + 2px;
                        }
                        &:hover {
                            color: $navy-blue;
                        }
                    }
                }
                &.not-bold {
                    > .cr-section > a:hover {
                        color: $navy-blue;
                        font-weight: bold;
                    }
                }
                &.bold-cat {
                    padding: 15px 0 0;
                    &:first-child {
                        padding-top: 0;
                    }
                    > .cr-section {
                        padding-bottom: 5px;
                        > a {
                            font-size: 14px;
                        }
                    }
                    #category-level-3 {
                        li {
                            padding: 15px 0;
                            &.active {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
.category-refinement {
    #category-level-1 {
        #category-level-2 > li.expandable {
            #category-level-3 > li {
                &:first-child {
                    padding: 15px 0;
                }
                padding: 30px 0 0;
                a {
                    &:hover {
                        font-weight: bold;
                    }
                }
                #category-level-4 {
                    > li {
                        &:first-child {
                            padding-top: 30px;
                        }
                    }
                    a.refinement-link {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
}
.bold-cat #category-level-3 {
    padding: 15px 0 !important;
}
