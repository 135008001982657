.top-menu-utility {
    .menu-utility {
        .mini-cart-total {
            .mini-cart-link {
                position: relative;
                span {
                    &.cart-bag {
                        background: url("../../../images/bag-icon.svg")
                            no-repeat;
                    }
                    &.cart-bag-qty {
                        background: url("../../../images/bag-icon-white.svg")
                            no-repeat;
                    }
                }
                .minicart-quantity {
                    background-color: $KS-light-blue;
                    top: 2px;
                    right: 87px;
                    padding: 0 8px;
                }
                &:hover,
                &.minicart_open {
                    span {
                        &.cart-bag,
                        &.cart-bag-qty {
                            background: url("../../../images/bag-icon-black.svg")
                                no-repeat;
                        }
                    }
                }
                &.mini-cart-empty {
                    &:hover {
                        span {
                            &.cart-bag,
                            &.cart-bag-qty {
                                background: url("../../../images/bag-icon.svg")
                                    no-repeat;
                            }
                        }
                    }
                }
            }
        }
        .mini-cart-content {
            .mini-cart-products {
                .mini-cart-product {
                    span,
                    a {
                        color: $navy-blue;
                        &.label {
                            @include Univers($UniversCondensed);
                        }
                        &.value {
                            @include Univers($UniversBoldCondensed);
                        }
                    }
                    span {
                        &.price-standard-exist {
                            color: $red;
                        }
                    }
                }
            }
            .attribute {
                padding: 0 0 10px 0;
                span {
                    &.label {
                        padding: 0;
                    }
                    &.value {
                        padding: 0;
                        display: inline;
                    }
                }
            }
            .product-availability-list {
                padding: 0 0 10px 0;
                span {
                    &.label {
                        padding: 0;
                    }
                    &.on-order {
                        text-transform: none;
                    }
                }
            }
        }
        .mini-cart-name {
            a {
                @include Univers($UniversBoldCondensed);
                font-size: $base-font + 2px;
                text-transform: uppercase;
            }
        }
        .mini-cart-totals {
            button,
            .button {
                padding: 15px 20px;
                background: $navy-blue;
                color: $white;
                width: 100%;
                border: none;
                @include Univers($UniversBoldCondensed);
                font-size: $base-font + 3px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            .mini-cart-subtotals {
                span {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 4px;
                    color: $navy-blue;
                }
            }
        }
        .mini-cart-slot {
            .minicartslot-content {
                .minicartslot {
                    .discount-sec {
                        @include Univers($UniversLightCondensed);
                        font-size: $base-font + 29px;
                        .discount-percent {
                            @include Univers($UniversBoldCondensed);
                            font-size: $base-font + 29px;
                        }
                    }
                    .product-sec {
                        @include Univers($UniversRomanOblique);
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .top-menu-utility {
        .menu-utility {
            .mini-cart-total {
                vertical-align: top;
            }
        }
    }
}
