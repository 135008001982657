#wrapper {
    &.pt_categorylanding {
        .refinements {
            @media screen and (min-width: 768px) {
                display: block;
                width: 20%;
            }
        }
    }
}
.pt_storefront {
    #main {
        max-width: 1260px;
    }
}
#secondary {
    // Remove Refinements for responsive design
    .pt_content-search-result &,
    .pt_order & {
        @media screen and (min-width: 768px) {
            display: block;
        }
    }
}
.clear-float {
    clear: both;
}
.zoomLens {
    background: url("../../../images/ks/ZoomGrill.svg") no-repeat;
    background-color: transparent !important;
    background-size: cover;
}
