.ui-tooltip.ui-widget-content {
    padding: 8px 12px 8px 8px;
    @include Univers($UniversRoman);
    color: $navy-blue;
}
.ui-tooltip-content {
    padding: 0.3em;
    .tooltip-custom {
        @include Univers($UniversRoman);
        color: $navy-blue;
    }
}
.ui-dialog {
    &.tips,
    &.product-hemming-tips,
    &.coupon-tooltip {
        padding: 0;
        float: none;
        .ui-dialog-titlebar {
            position: relative;
            display: block;
            margin: 0 auto 10px;
            padding: 0;
            float: none;
            width: 100%;
            height: auto;
            top: auto;
        }
        .ui-dialog-titlebar-close {
            position: relative;
            width: 50px;
            display: inline-block;
            float: none;
        }
        .ui-dialog-title {
            display: inline-block !important; // must be refactored in the common files
            width: calc(100% - 55px);
            float: none;
        }
    }
}
