//Brand-logo-utility
.top-menu-utility {
    .menu-utility {
        .menu-utility-user {
            .info {
                &.user-info {
                    span {
                        &.username {
                            @include WorkSansMedium($WorkSans);
                        }
                    }
                    .user-panel {
                        &.registered-user {
                            #profilemenu {
                                .account-links {
                                    a {
                                        @include WorkSansMedium($WorkSans);
                                    }
                                }
                            }
                            .not-firstname {
                                @include WorkSansMedium($WorkSans);
                            }
                            .signin-someone {
                                @include WorkSansMedium($WorkSans);
                                color: $navy-blue;
                            }
                            .account-logout {
                                a {
                                    @include WorkSansMedium($WorkSans);
                                    &:hover {
                                        color: $navy-blue;
                                    }
                                }
                            }
                        }
                        .login-box {
                            .form-row {
                                label {
                                    span {
                                        @include WorkSansMedium($WorkSans);
                                    }
                                }
                                .field-wrapper {
                                    @include WorkSansMedium($WorkSans);
                                    button {
                                        &.pwd-show {
                                            @include WorkSansSemiBold(
                                                $WorkSans
                                            );
                                        }
                                    }
                                }
                                &.form-row-button {
                                    .login-rememberme {
                                        label {
                                            .label-text {
                                                font-family: $Lora;
                                            }
                                        }
                                    }
                                }
                                button {
                                    @include WorkSansSemiBold($WorkSans);
                                    background-color: $navy-blue;
                                }
                            }
                            #password-reset {
                                @include WorkSansSemiBold($WorkSans);
                            }
                        }
                        .login-data {
                            span {
                                font-family: $Lora;
                            }
                        }
                        .login-oauth {
                            button {
                                color: $white;
                                @include WorkSansSemiBold($WorkSans);
                                background-color: $chambray;
                            }
                        }
                        .create-account-section {
                            span {
                                font-family: $Lora;
                            }
                            a {
                                span {
                                    @include WorkSansSemiBold($WorkSans);
                                }
                            }
                        }
                        .account-section-summary {
                            @include WorkSansMedium($WorkSans);
                            .signin-someone {
                                color: $navy-blue;
                            }
                        }
                    }
                }
                .customer-service-flyout {
                    .primary {
                        .email-us-icon,
                        .livechat-icon {
                            a {
                                font-family: $WorkSans;
                            }
                        }
                    }
                    .cs-flyout-phone {
                        span {
                            font-family: $WorkSans;
                        }
                    }
                    .cs-flyout-email {
                        span {
                            font-family: $WorkSans !important;
                        }
                    }
                    .secondary {
                        .quick-links {
                            .links {
                                font-family: $Lora;
                            }
                            a {
                                @include WorkSansMedium($WorkSans);
                            }
                        }
                    }
                }
            }
        }
    }
}
