.legacy-sitegen .pt_wish-list {
    .primary-content {
        .search-wishlist {
            h1 {
                @include Univers($UniversRoman);
                font-size: $base-font + 12px;
                @include Univers($UniversCondensed);
            }
            h2 {
                @include Univers($UniversRoman);
                font-size: $base-font + 1px;
                @include Univers($UniversCondensed);
            }
            &.login-box {
                .or-link {
                    @include Univers($UniversCondensed);
                    font-size: $base-font + 1px;
                    margin-bottom: 18px;
                }
            }
            .error-message {
                @include Univers($UniversCondensed);
                color: $radical-red;
                font-size: $base-font + 1px;
            }
            .form-row {
                &.form-row-button {
                    button {
                        color: $white;
                        background: $navy-blue;
                        @include Univers($UniversBoldCondensed);
                    }
                }
            }
            label {
                font-size: $base-font + 1px;
                span {
                    @include Univers($UniversCondensed);
                }
                &.input-focus {
                    span {
                        font-size: $base-font;
                    }
                }
            }
            input[type="text"],
            input[type="number"],
            input[type="password"],
            input[type="date"] textarea {
                padding: 20px 15px 14px 15px;
            }
        }
        .search-wishlist-bottom {
            .search-wishilist-box {
                h1 {
                    @include Univers($UniversRoman);
                    font-size: $base-font + 12px;
                    @include Univers($UniversCondensed);
                }
                .form-row {
                    @media screen and (min-width: 1024px) {
                        width: 21%;
                        margin-right: 12px;
                        &.email_wishlist {
                            width: 31%;
                        }
                    }
                    &.form-row-button {
                        button {
                            background: $navy-blue;
                            @include Univers($UniversBoldCondensed);
                        }
                    }
                }
            }
        }
        .results-found {
            .heading {
                @include Univers($UniversRoman);
                font-size: $base-font + 12px;
            }
            .section-header-list {
                .section-header {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 1px;
                }
            }
            .item-list {
                .section-item-list {
                    .col-1,
                    .col-2,
                    .col-3,
                    .col-4 {
                        @include Univers($UniversCondensed);
                        font-size: $base-font + 1px;
                    }
                    .col-4 {
                        @include Univers($UniversBoldCondensed);
                        font-size: $base-font;
                    }
                }
            }
        }
    }
}
//Wishlist login page
.create-login {
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="date"],
    textarea {
        border-radius: 0;
    }
    .login-oauth {
        button {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 3px;
            letter-spacing: 1.5px;
        }
    }
    .birthMonth {
        .input-select {
            @media screen and (max-width: 1024px) {
                border-radius: 0;
            }
        }
    }
    .custom-select {
        .selected-option {
            font-size: $base-font;
            border-radius: 0;
        }
    }
    button {
        background: $navy-blue;
        @include Univers($UniversBoldCondensed);
        font-size: $base-font + 3px;
        letter-spacing: 1.5px;
        &:hover {
            background: $KS-sky-blue;
            border-color: $KS-sky-blue;
        }
    }
    h1 {
        @include Univers($UniversCondensed);
        font-size: $base-font + 13px;
    }
    .create-inner-block {
        .login-create {
            .para {
                @include Univers($UniversCondensed);
                font-size: $base-font + 2px;
            }
            .step {
                h2 {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 2px;
                }
                p {
                    @include Univers($UniversCondensed);
                    font-size: $base-font + 2px;
                }
            }
        }
    }
    .login-create-account {
        h2 {
            font-size: $base-font + 1px;
            font-family: $Helvetica;
        }
        .para {
            @include Univers($UniversCondensed);
            font-size: $base-font + 2px;
        }
        .or-section {
            .or-link {
                font-size: $base-font;
                font-family: $HelveticaMedium;
            }
        }
        .legal {
            font-size: $base-font;
            font-family: $HelveticaMedium;
        }
    }
    .col-1 {
        .form-row {
            &.login-rememberme {
                margin: 18px 0 0 0;
                display: inline-table;
                float: none;
                @media screen and (max-width: 767px) {
                    margin: 0 0 10px 0;
                }
                span {
                    @media screen and (max-width: 767px) {
                        color: $black;
                    }
                }
                .field-wrapper {
                    top: auto;
                     position: relative;
                    text-align: left;
                    @media screen and (max-width: 767px) {
                        display: inline-block;
                    }
                }
                input[type="checkbox"] {
                    margin-right: 0;
                }
                label {
                    top: auto;
                    display: table-cell;
                    text-align: left;
                    padding: 0;
                    float: none;
                    vertical-align: middle;
                    left: 0;
                    line-height: normal;
                    @media screen and (max-width: 767px) {
                        display: inline-block;
                        vertical-align: top;
                        line-height: 18px;
                    }
                    .label-text {
                        font-size: $base-font;
                    }
                }
            }
        }
        .form-row-button {
            button {
                @media screen and (max-width: 767px) {
                    margin: 10px 0 0 0;
                }
            }
        }
    }
    .form-caption {
        font-size: $base-font;
        font-family: $Helvetica;
    }
    .inner-block {
        .login-box {
            background: $light-skygrey;
            h2,
            .description {
                @include Univers($UniversRoman);
                font-size: $base-font + 2px;
                @include Univers($UniversCondensed);
            }
            .filled-wineberry {
                padding: 15px 50px;
            }
            #password-reset {
                @include Univers($UniversRoman);
                font-size: $base-font;
                @include Univers($UniversCondensed);
                @media screen and (max-width: 767px) {
                    margin-top: 20px;
                }
            }
            .login-data {
                span {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 1px;
                    padding-bottom: 5px;
                    &.login-brands {
                        @include Univers($UniversCondensed);
                        span {
                            @include Univers($UniversCondensed);
                        }
                        sup {
                            font-size: $base-font + 5px;
                            top: -0.2em;
                        }
                    }
                }
            }
        }
    }
}
//whishlist email popup
.ui-dialog {
    &.wishlist-email {
        .ui-dialog-titlebar {
            .ui-dialog-title {
                @include Univers($UniversRoman);
                font-size: $base-font + 12px;
            }
        }
    }
}
.legacy-sitegen .sendtofriend {
    .sub-heading {
        @include Univers($UniversRoman);
        font-size: $base-font + 2px;
        line-height: 17px;
    }
    .form-row {
        &.subject {
            width: 53%;
            margin: 25px 0 0 0;
        }
        &.sendtome {
            margin: 16px 0 0 2px;
            label {
                font-size: $base-font;
                font-family: $HelveticaMedium;
                letter-spacing: 0.8px;
            }
        }
        &.message {
            input[type="text"] {
                padding: 0 15px 14px 15px;
            }
        }
        &.form-row-button {
            margin: 20px 0 0 0;
            width: 100%;
            button {
                padding: 17px 53px;
                @include Univers($UniversBoldCondensed);
                background: $navy-blue;
                font-size: $base-font + 3px;
            }
        }
    }
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="date"],
    textarea {
        padding: 20px 15px 14px 15px;
        border-radius: 0;
    }
}
//My Wishlist share
.legacy-sitegen .list-share {
    .list-title {
        padding: 25px;
        @include Univers($UniversRoman);
        font-size: $base-font + 12px;
    }
    .top-section {
        margin: 25px 0;
        padding: 0 25px;
        .share-option {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 3px;
            color: $navy-blue;
            background: transparent;
        }
    }
    .bottom-section {
        padding: 10px 25px;
        .share-link-content {
            .share-link-copy {
                padding: 10px 20px;
            }
            .copy-link {
                @include Univers($UniversBoldCondensed);
            }
        }
    }
}
//My Wishlist Items
.legacy-sitegen .item-list {
    .wishlist-row {
        label {
            span {
                &.label-text {
                    @include Univers($UniversBoldCondensed);
                }
            }
        }
        .column {
            &.col-1 {
                .item-details {
                    font-size: $base-font - 1px;
                    .name {
                        @include Univers($UniversBoldCondensed);
                        font-size: $base-font + 2px;
                        color: $navy-blue;
                        text-transform: uppercase;
                    }
                    .sku span {
                        color: $dim-gray;
                    }
                    span {
                        @include Univers($UniversBoldCondensed);
                        font-size: $base-font;
                        color: $navy-blue;
                        text-transform: uppercase;
                        &.label {
                            @include Univers($UniversCondensed);
                            text-transform: uppercase;
                        }
                        &.price-sale-standard,
                        &.notavailable {
                            color: $navy-blue;
                        }
                        &.optionitemprice {
                            .label,
                            .value {
                                @include Univers($UniversCondensed);
                            }
                        }
                    }
                    .product-special-messages {
                        @include Univers($UniversCondensed);
                        font-size: $base-font;
                        color: $special-product-messages-color;
                    }
                }
            }
            &.col-2 {
                .price {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 3px;
                    margin-top: 68px;
                    span {
                        .price-sale-standard {
                            color: $red;
                        }
                    }
                }
            }
            &.col-3 {
                .option-date-added {
                    font-size: $base-font;
                    .label {
                        @include Univers($UniversBoldCondensed);
                    }
                    .value {
                        @include Univers($UniversCondensed);
                        text-transform: none;
                    }
                }
                .option-add-to-cart {
                    button {
                        &.add-to-cart {
                            background: $navy-blue;
                            padding: 14.5px 40px;
                            @include Univers($UniversBoldCondensed);
                            letter-spacing: 1.5px;
                            font-size: $base-font + 6px;
                            max-height: 52px;
                            border-radius: 0;
                            border-top-right-radius: $KS-Btn-radius;
                            border-bottom-right-radius: $KS-Btn-radius;
                        }
                    }
                    .custom-select {
                        .selected-option {
                            padding: 14px;
                            line-height: normal;
                            height: auto;
                        }
                        select {
                            border-top-left-radius: $KS-Btn-radius;
                            border-bottom-left-radius: $KS-Btn-radius;
                        }
                        @media screen and (max-width: 1024px) {
                            select {
                                padding: 0 16px;
                            }
                        }
                    }
                }
                .edit-section {
                    margin: 20px 0 0 0;
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font;
                    clear: both;
                    .edit-wishlist-detail {
                        padding-right: 13px;
                    }
                    .option-update {
                        float: left;
                        padding-left: 13px;
                        button {
                            font-size: $base-font;
                            padding: 0;
                            color: $navy-blue;
                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                    a {
                        color: $navy-blue;
                    }
                }
                .option-toggle-public {
                    margin: 20px 0 0 0;
                }
            }
        }
    }
}
//Wishlist Empty
.legacy-sitegen .wishlist-empty {
    .heading {
        font-size: $base-font + 15px;
    }
    .qa-section {
        .qa-content {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 1px;
            .answer {
                font-size: $base-font + 3px;
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .legacy-sitegen .pt_wish-list {
        .primary-content {
            //My Wishlist bottom section
            .search-wishlist-bottom {
                .search-wishilist-box {
                    .form-row {
                        &.form-row-button {
                            button {
                                padding: 16px 67px;
                            }
                        }
                    }
                }
            }
        }
    }
    //Wishlist login page
    .create-login {
        .login-oauth {
            button {
                padding: 14px 40px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .legacy-sitegen .pt_wish-list {
        #primary {
            .search-wishlist {
                .form-row {
                    margin: 20px 0 0 0;
                }
                .search-wishilist-box {
                    padding: 20px;
                }
            }
            .results-found {
                .item-list {
                    .section-item-list {
                        .mobile-label {
                            font-size: $base-font;
                            font-family: $HelveticaBold;
                        }
                    }
                }
            }
        }
    }
    //Wishlist login page
    .create-login {
        .inner-block {
            .login-box {
                .login-data {
                    .login-email {
                        width: 73%;
                    }
                }
            }
        }
    }
    //whishlist email popup
    .ui-dialog {
        &.wishlist-email {
            .ui-dialog-titlebar {
                .ui-dialog-title {
                    font-size: $base-font + 8px;
                }
            }
        }
    }
    .legacy-sitegen .sendtofriend {
        .sub-heading {
            font-size: $base-font + 1px;
        }
        label {
            font-size: $base-font - 1px;
        }
    }
    //My Wishlist Items
    .legacy-sitegen .item-list {
        .wishlist-row {
            position: relative;
            .wishlist-columns {
                padding: 25px 0;
            }
            .column {
                width: 100%;
                &.col-1 {
                    max-width: 100%;
                    width: 100%;
                    .item-image {
                        width: 100px;
                    }
                    .item-details {
                        max-width: 100%;
                        width: 64%;
                        .edit-wishlist-detail-mobile {
                            margin: 0 0 10px 0;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            font-size: $base-font - 1px;
                            color: $black;
                            font-family: $HelveticaBold;
                        }
                    }
                }
                &.col-3 {
                    max-width: 100%;
                    width: 100%;
                    margin: 17px 0 0 0;
                    .option-date-added {
                        margin: 20px 0 0 0;
                    }
                    .item-dashboard {
                        width: 100%;
                    }
                    .option-add-to-cart {
                        width: 100%;
                        .inventory {
                            width: 20%;
                        }
                        button {
                            &.add-to-cart {
                                width: 80%;
                            }
                        }
                    }
                    .edit-section {
                        .option-update {
                            button {
                                text-indent: -9999px;
                                position: absolute;
                                right: 0;
                                top: 10px;
                                background-image: url("../../../images/close-icon.png");
                                height: 16px;
                                width: 16px;
                            }
                        }
                    }
                    .option-toggle-public {
                        width: 100%;
                        float: left;
                        margin: 20px 0;
                    }
                }
            }
        }
    }
    //My Wishlist
    .legacy-sitegen .list-share {
        .top-section {
            margin: 0 0 15px 0;
            padding: 0;
        }
        .bottom-section {
            padding: 0;
            .share-options {
                .share-icon-container {
                    a.share-twitter {
                        margin-right: 15px;
                    }
                }
            }
            .share-option {
                font-family: $HelveticaBold;
                font-size: $base-font;
                background: transparent;
            }
        }
    }
    //Wishlist Empty
    .legacy-sitegen .wishlist-empty {
        .qa-section {
            .qa-content {
                .question {
                    font-size: $base-font - 1px;
                }
                .answer {
                    font-size: $base-font - 2px;
                }
            }
        }
    }
}
