.error-page {
    div {
        @include Univers($UniversBoldCondensed);
        font-size: $base-font + 180px;
    }
    p {
        @include Univers($UniversCondensed);        
    }
}
@media screen and (max-width: 767px) {
	.error-page-message{
		.error-page {
   			div {
   				font-size: $base-font + 100px;
   			}
   		}
	}
}
