.legacy-sitegen.ui-dialog {
    &.bonus-product-quickview {
        .ui-dialog-content {
            @media screen and (max-width: 767px) {
                padding: 0 15px 40px 20px;
            }
        }
        .promotion {
            @media screen and (max-width: 767px) {
                font-size: $base-font - 2px;
            }
            b {
                font-size: $base-font;
                @media screen and (max-width: 767px) {
                    font-size: $base-font - 2px;
                }
            }
            .viewless {
                font-size: $base-font - 1px;
            }
            a {
                font-size: $base-font - 1px;
            }
        }
        .bonus-product-list {
            .product-col-2 {
                .morelink {
                    font-size: $base-font - 1px;
                }
            }
        }
        .product-name {
            font-size: $base-font + 12px;
            font-family: $Lora;
            font-style: normal;
        }
        .product-variations {
            .attribute {
                .value {
                    &.active {
                        background-color: $black;
                    }
                    .selecting {
                        background: url(../../../images/arrow-down.png)
                            no-repeat;
                        &.active {
                            background: url("../../../images/up-arrow.png")
                                no-repeat;
                        }
                        .swatch-image {
                            width: 30px;
                            min-width: 27px;
                            border-radius: 50%;
                        }
                    }
                    .swatches {
                        li {
                            &:hover {
                                background-color: $navy-blue;
                                color: $white;
                                a {
                                    border-color: $navy-blue !important;
                                    background-color: $navy-blue;
                                    color: $white;
                                }
                                span {
                                    background-color: $navy-blue;
                                    color: $white;
                                }
                            }
                            a {
                                margin: 0;
                                border-radius: 0;
                            }
                            &.selected {
                                color: $navy-blue;
                                a {
                                    span {
                                        color: $navy-blue;
                                    }
                                }
                                &:hover {
                                    a {
                                        span {
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                        &.size {
                            li {
                                width: 84%;
                                margin: 0 auto;
                                float: none;
                                border-bottom: 1px solid #ccc;
                                &.selected {
                                    a {
                                        color: $black;
                                        background-color: $white;
                                    }
                                }
                                &:hover {
                                    a {
                                        background-color: $black;
                                        color: $white;
                                    }
                                }
                            }
                        }
                        &.color {
                            li {
                                a {
                                    &:before {
                                        @media screen and (min-width: 1024px) {
                                            display: none;
                                        }
                                        @media screen and (max-width: 767px) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .size-chart-link {
                    font-size: $base-font - 1px;
                }
            }
        }
        .bonus-product-list-footer {
            padding: 20px 0 0 0;
            width: 100%;
            margin: 0 auto;
            border: none;
            .add-to-cart-bonus {
                background-color: $navy-blue;
                padding: 16px 50px;
                @media screen and (max-width: 767px) {
                    padding: 16px 35px;
                }
            }
            .button-add {
                width: 33%;
                margin: 0 auto;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .legacy-sitegen.ui-dialog {
        &.bonus-product-quickview {
            .bonus-product-list {
                .product-name {
                    font-size: $base-font + 20px;
                    margin: 0 0 10px 0;
                }
            }
            .product-variations {
                .attribute {
                    .value {
                        .swatches {
                            &.active {
                                width: 99%;
                            }
                            &.color {
                                li {
                                    a.swatchanchor {
                                        padding: 0 0 0 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .bonus-product-list-footer {
                width: 40%;
                text-align: center;
                .add-to-cart-bonus {
                    float: none;
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .legacy-sitegen.ui-dialog {
        &.bonus-product-quickview {
            .bonus-product-items {
                padding: 0 0 0 5px;
            }
            .bonus-product-list {
                .product-name {
                    font-size: $base-font + 4px;
                }
            }
            .bonus-product-list-footer {
                width: 100%;
            }
        }
    }
}
.bonusheading {
    font-size: $base-font + 2px;
    @media screen and (max-width: 767px) {
        font-size: $base-font - 2px;
    }
}
.promotion {
    font-size: $base-font;
}
.bonus-product-items {
    padding: 0 0 0 20px;
    box-sizing: border-box;
    border: 2px solid $dim-gray;
}
.bonus-product-list {
    .bonus-product-item {
        .product-col-2 {
            width: 69%;
        }
        .price {
            font-size: $base-font;
        }
    }
    .bonus-product-list-footer {
        .add-to-cart-bonus {
            background: $wine-berry;
        }
    }
}
.pt_cart {
    #cart-table {
        &.item-list {
            .cart-promo {
                background-color: $light-skygrey;
                font-size: $base-font - 1px;
                .bonus-item-details {
                    @include WorkSansSemiBold($WorkSans);
                    .tooltip {
                        color: $red;
                    }
                }
                .bonus-item-actions {
                    color: $red;
                    .select-bonus {
                        color: $red;
                    }
                }
            }
        }
    }
}
.bonus-product-list {
    .bonus-product-item {
        @extend %clearfix;
        border-bottom: 1px solid $gainsboro;
        .product-col-1,
        .product-col-2 {
            @media screen and (max-width: 768px) {
                float: left;
                clear: none;
            }
        }
    }
    .product-add-to-cart {
        padding-bottom: 0;
        .inventory {
            width: auto;
        }
    }
    .product-name {
        float: none;
        margin: 1em 0;
    }
    .quantity-error {
        color: $red;
        text-transform: inherit;
    }
    .bonus-product-list-footer {
        @extend %clearfix;
        padding-top: 1em;
        padding-bottom: 1em;
        .add-to-cart-bonus {
            float: right;
        }
    }
    .selected-bonus-item {
        margin: 1em 0 1em 1em;
        position: relative;
        .item-name {
            font-weight: bold;
        }
        .remove-link {
            cursor: pointer;
            color: $sangria;
            left: -1em;
            position: absolute;
            &:hover {
                color: $charcoal;
            }
        }
    }
}
.bonus-discount-container {
    .promo-details {
        display: none;
        &.visible {
            display: block;
        }
    }
}

.legacy-sitegen.ui-dialog.bonus-product-quickview.ui-draggable .product-variations .attribute .value .swatches li:hover {
    background-color: $navy-blue;
    color: $white;
    a {
        border-color: $navy-blue !important;
        background-color: $navy-blue;
        color: $white;
    }
    span {
        background-color: $navy-blue;
        color: $white;
    }
}