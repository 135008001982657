@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.account-subpage-heading {
	&, .top-content & {
		@include Univers($UniversCondensed);
		font-size: $base-font + 15px;
		color: $dim-gray;
	}
}
.account-subpage-subheading {
	&, .top-content & {
		@include Univers($UniversRoman);
		font-size: $base-font + 2px;
		color: $dim-gray;
	}
}
.gift-heading {
	@include Univers($UniversBoldCondensed);
	font-size: $base-font + 25px;
	text-align: center;
    letter-spacing: 3px;
    width: 57%;
    display: block;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
    	font-size: $base-font + 7px;
    }
}
.gift-paragraph {
	@include Univers($UniversCondensed);
	font-size: $base-font + 3px;
	letter-spacing: 1.1px;
    text-align: center;
	@media screen and (max-width: 767px) {
    	font-size: $base-font + 1px;
    }
}
.gift-card-section {
	.gift-card-result-section {
		-moz-box-shadow: -16px 16px 0px $navy-blue;
		-webkit-box-shadow: -16px 16px 0px $navy-blue;
		box-shadow: -16px 16px 0px $navy-blue;
		display: inline-block;
		width: 100%;
	}
	.note-section {
		-moz-box-shadow: -16px 16px 0px $navy-blue;
		-webkit-box-shadow: -16px 16px 0px $navy-blue;
		box-shadow: -16px 16px 0px $navy-blue;
		.note {
			@include Univers($UniversRoman);
			font-size: $base-font + 2px;
			color: $dim-gray;
		}
		.another-card {
			color: $navy-blue;
			@include Univers($UniversBoldCondensed);
			font-size: $base-font + 3px;
		}
	}
	.gift-card-message, .gift-card-lookup-form-section {
	    -moz-box-shadow: -16px 16px 0px $navy-blue;
		-webkit-box-shadow: -16px 16px 0px $navy-blue;
		box-shadow: -16px 16px 0px $navy-blue;
	}
	input[type="text"] {
	    border-radius: 0;
	}
	.make-label-absolute {
		label {
			span {
				@include Univers($UniversBoldCondensed);
				font-size: $base-font + 1px;
			}
		}
	}
	.balance-result-section {
		box-shadow: -16px 16px 0 $navy-blue;
		padding: 0 21px 24px 20px;
		.balance-result-block {
			background: $navy-blue;
			@include Univers($UniversBoldCondensed);
			font-size: $base-font + 2px;
	    }
		.svs-card-amount {
			font-size: $base-font + 20px;
			font-weight: 700;
		}
		.svs-masked-number {
			font-size: $base-font + 2px;
		}
		.available-balance-text {
		    margin: 0 0 15px 0;
		}
	}
	.form-action {
		button {
			background: $navy-blue;
			color: $white;
			border-color: $navy-blue;
			@include Univers($UniversBoldCondensed);
			font-size: $base-font + 3px;
		}
	}
	@media screen and (max-width: 767px) {
		.gift-card-message, .gift-card-lookup-form-section, .gift-card-result-section {
		    -moz-box-shadow: -10px 10px 0px $navy-blue;
	    	-webkit-box-shadow: -10px 10px 0px $navy-blue;
	    	box-shadow: -10px 10px 0px $navy-blue;
		}
		.gift-heading {
		    width: 91%;
		    font-size: $base-font + 12px;
		    margin: 0 auto;
		}
		.balance-result-section {
			-moz-box-shadow: -10px 10px 0px $navy-blue;
	    	-webkit-box-shadow: -10px 10px 0px $navy-blue;
	    	box-shadow: -10px 10px 0px $navy-blue;
			.svs-card-amount {
				font-size: $base-font + 12px;
			}
			.balance-result-block {
				font-size: $base-font - 2px;
			}
			.svs-masked-number {}
		}
		.note-section {
			-moz-box-shadow: -10px 10px 0px $navy-blue;
	    	-webkit-box-shadow: -10px 10px 0px $navy-blue;
	    	box-shadow: -10px 10px 0px $navy-blue;
	        .note {
	        	font-size: $base-font;
        	    text-align: center;
	        }
		}
		.gift-paragraph {
			font-size: $base-font;
		}
	}
}
.plcc-pre-approval-points {
	@include Univers($UniversRoman);
	font-size: 20px;
	color: #000000;
	background: #75de9f;
	text-align: center;	
	display: inline-block;
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	
	@media screen and (max-width: 767px) {
	    font-size: 10px;
	}
}
.plcc-main {
	&.plcc-pre-approval-main {
		
		.plcc-main-heading {
			@include Univers($UniversRoman);
			font-size: $base-font + 6px;
			color: $black;
		    margin: 0 0 15px 0;
		}
		.plcc-profile-summary {
		    margin: 0 0 15px 0;
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email, .plcc-edit-profile {
				@include Univers($UniversCondensed);
				font-size: $base-font + 1px;
			}
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email {
				color: $dim-gray;
			    line-height: 25px;			
			}
			.plcc-edit-profile {
				color: $black;
			    line-height: 19px;
		        text-decoration: underline;
			}
		}
		.phone-number-msg {
			clear: both;
		}
		.question-mark-icon {
		    margin-right: 15px;
		}
		.plcc-form-section {
			width: 92.1%;
			padding: 38px 70px 38px 28px;
		    background: $white-smoke-light;
		    box-sizing: border-box;
		    -moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
		    margin-bottom: 20px;
		    display: inline-block;
			
			.plcc-masked-number {
				width: 32%;
				background: $white;
			    display: inline-block;
			    padding: 18px 15px 14px 15px;
			    box-sizing: border-box;
			    -moz-box-sizing: border-box;
				-webkit-box-sizing: border-box;
				@include Univers($UniversCondensed);
				font-size: $base-font + 3px;
				color: $black;
			}
			.plcc-form-row-wrapper {
				display: inline-block;
				width: 100%;
			}
			.form-row {
				&.ssn {
				    width: 68%;
    				float: right;
				}
			}  
		}
		.plcc-primary {
			label {
				@include Univers($UniversCondensed);
				font-size: $base-font + 1px;
				color: $dim-gray;
			}
			.plcc-ssn-label, .extra-label {
				color: $black;
			}
			.phone-number-msg, .child-support-section , .married-section, .important-info {
				clear: both;
			}
			.extra-label, .plcc-personal-address-section {
				font-size: $base-font;
				text-transform: uppercase;
			}
			.plcc-personal-address-section {
				@include Univers($UniversCondensed);
				font-size: $base-font + 1px;
				color: $black;
			    margin: 0 0 15px 0;
			}
		}
	}
	.plcc-secondary {
		.see-benefits {
			@include Univers($UniversBoldCondensed);
		    font-size: $base-font + 3px;
		    color: $navy-blue;
		}
	}
	.option-text {
	    @include Univers($UniversCondensed);
		font-size: $base-font + 1px;
    }
	.plcc-left-nav-heading {
		@include Univers($UniversBoldCondensed);
		font-size: $base-font + 7px;
	}
	.plcc-left-nav-info {
		@include Univers($UniversRoman);
		font-size: $base-font + 2px;
		color: $dim-gray;
		line-height: 22px;
	}
	.plcc-primary {
		.phone-number-msg, .child-support-section , .married-section, .important-info {
			@include Univers($UniversRoman);
			font-size: $base-font;
			color: $dim-gray;
			line-height: 18px;
			
			.bold {
				font-weight: 700;
			}
		}
		.phone-number-label {
			@include Univers($UniversCondensed);
			font-size: $base-font + 1px;
			color: $black;
		}
		.extra-label {
			@include Univers($UniversCondensed);
			font-size: $base-font + 2px;
			color: $dim-gray;
		}
		.plcc-ssn-label {
			@include Univers($UniversCondensed);
			font-size: $base-font + 1px;
			color: $slightblack;
		}
		.note-section {
			@include Univers($UniversBoldCondensed);
		    font-size: $base-font + 3px;
		    color: $black;
		    border: 2px solid $black;
		    padding: 20px;
		}
		.review-form-error-msg {
			@include Univers($UniversBoldCondensed);
		    font-size: $base-font + 1px;
		    color: $plcc-error;
		    margin-bottom: 10px;
            margin-top: 10px;
		}
		.form-row {
			&.form-indent {
		    	label {
		    		@include Univers($UniversCondensed);
		    		font-size: $base-font + 2px;
		    		color: $black;
	    		    line-height: 20px;
		    	}
		    }
		}
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea,input[type="tel"]  {
				border-radius: 0;
				@include Univers($UniversCondensed);
	    		font-size: $base-font + 3px;
	    		color: $slightblack;	
			}
		}
		.form-action {
			.form-row-button {
				&.cancel-button {
					width: 130px;
					a {
						@include Univers($UniversBoldCondensed);
						letter-spacing: 1px;
						font-size: $base-font + 6px;
						outline-offset: -3px; 
					}
				}		
			}
		}
	}
	@media screen and (min-width: 1024px) and (max-width: 1399px) {
		padding: 0 10px;
		box-sizing: border-box;
	    -moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		
		&.plcc-pre-approval-main {
			.plcc-form-section {
			    width: 97.1%;
    			padding: 38px 3% 38px 2%;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.plcc-primary {
		}
		.plcc-secondary {
			
		} 
	}
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		&.plcc-pre-approval-main {
			width: 708px;
			margin: 22px auto 0;
			display: block;
			.plcc-main-heading {
				display: none;
			}
			.plcc-secondary {
				max-width: 100%;
				.see-benefits {
					margin-bottom : 20px;
				}
			}
			.custom-select {
			    font-size: 0;
			}
			.plcc-primary {
				padding: 0;
				
				.phone-number-msg,.child-support-section,.married-section,.important-info {
					font-size: $base-font + 1px;
				}
				.plcc-form-section {
				    width: 100%;
				    padding: 38px 28px 7px 28px;
				}
			}
		}
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea  {
	    		color: $black;	
			}
		}
		.question-mark-icon {
		    margin-right: 15px;
		}
		.plcc-primary {
			.review-form-error-msg {
				font-size: $base-font;
			}
		}	
	} 
	@media screen and (max-width: 767px) {
		&.plcc-pre-approval-main {
			padding: 0 10px;
			.question-mark-icon {
				margin-right: 8px;
			}
			.custom-select {
			    font-size: 0;
			}
			.plcc-profile-summary {
				.plcc-profile-name,
				.plcc-profile-address,
				.plcc-city,
				.plcc-profile-email {
					line-height: 20px;
				}
				.plcc-edit-profile {
					line-height: 24px;
				}
			}
			
			.plcc-primary {
				.phone-number-msg,
				.child-support-section,
				.married-section,
				.important-info {
					padding: 0 17px;
				}
				.form-action {
					.form-row-button {
						&.save-button {
							button {
								font-size: $base-font;
							}
						}
					}
				}
				.plcc-form-row {
					.plcc-form-row-1 {
						margin-bottom: 20px;
					}
				}
				.form-row {
					&.month, &.day {
						margin-right: 5%;
					}
					&.month {
						width: 28%;
					}
					&.day {
					    width: 30.5%;
					}
					
					&.form-indent {
						padding: 0;
						label {
							line-height: 16.5px;
						}
					}
				}
				.plcc-ssn-label, .extra-label {
					color: $slightblack;
				}
				
			}
			
			.plcc-form-section {
			    padding: 25px 10px;
		        width: 100%;
		        .plcc-masked-number {
		        	font-size: $base-font;
		        }
			}
			
			.plcc-secondary {
			    padding: 0 22.5px;
			}
			.plcc-main-heading {
				padding: 0 22.5px;
				font-size: $base-font + 2px;
			    margin: 0 0 10px 0;
			}
		}
		.plcc-primary {
			.plcc-form-section, .plcc-form-row {
			}
			.phone-number-msg,.child-support-section, .married-section,.important-info {
				line-height: 18.5px;
			}
			.form-row {
				&.form-indent {
					label {
					    float: left;
    					width: 88%;
    					font-size: $base-font - 1px;		
					}
				}
			}
			.extra-label, .plcc-ssn-label {
				font-size: $base-font;
			}		
		}
		.plcc-left-nav-heading{
			font-size: $base-font;
		    line-height: 16px;
    		margin: 0;
		}
		.plcc-left-nav-info {
			font-size: $base-font;
			line-height: 18.5px;
		}
    	.plcc-secondary {
    		.see-benefits {
				font-size: $base-font - 2px;
			}
    	}
    }
    
    .plcc-primary {
	    .phone-number-msg, .contact-disclaimer {
			display: block;
		}
		.plcc-web-instance-page {
    		.phone-number-msg {
				display: block;	
			}
    	}
    	.form-row{ 
    		.selected-option{
    			border-radius: 0;
    		}
    	}
	}    
}
.default-indicator {
	@include Univers($UniversCondensed);
	font-size: $base-font + 1px;
}
.paragraph {
	@include Univers($UniversRoman);
	font-size: $base-font + 2px;
	
}
.address-list {
	li {
		.address-list-sec {
		    margin-bottom: 18px;
		}
	}
}
.address-list-sec {
	@include Univers($UniversCondensed);
	font-size: $base-font + 3px;
	
	@media screen and (max-width: 767px) {
		width: 100%;
	}	
	.mini-address-title {
		@include Univers($UniversBoldCondensed);
	}
	a, .address-delete, .address-edit, .cancel {
		color: $navy-blue;
	}
	.delete-content {
	    .delete-msg {
	    	font-family: $Helvetica;
			font-size: $base-font + 4px;
	    }
	    .delete-controls {
	    	.cancel, .address-delete {
		    	font-family: $HelveticaBold;
				font-size: $base-font + 4px;
	    	}
	    }
	    
	}
}
.address-list-sec {
	.address-info {
		a {
			margin: 0 29px 0 0;
		}
	}
}
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.make-label-absolute {
		.form-row {
			.field-wrapper {								
				input[type="tel"] {
					border-radius: 0;
					-webkit-appearance: none;
				}
			}
			&.month {
				margin-left: 0;
			}
		}
	}
	.address-form-block {
		.make-label-absolute {
			.form-row {
				label {
					font-weight: 500;
					&[for*="country"] {
						margin: -1px auto 0;
					}
				}
				&.phone {
					span {
						white-space: nowrap;
					}
				}
				&.form-row-button {
					.apply-button{
						@include Univers($UniversBoldCondensed);
						font-size: $base-font + 3px;
						padding: 15px 56px;
					}
					.cancel-address {
				        background: none;
						color: $navy-blue;
						width:auto;
					}
				}
			}
		}
	}
	.form-validation-error-msg {
		&.error {
			@include Univers($UniversCondensed);
			font-size: $base-font + 1px;
		}
	}
	.address-create {
		@include Univers($UniversBoldCondensed);
		font-size: $base-font + 3px;
		&.no-click {
			color: $dim-gray;
			cursor: not-allowed;
		}
	}
	.make-label-absolute {
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea, .selected-option, input[type="tel"]  {
				color: $slightblack;
				@include Univers($UniversCondensed);
				border-radius: 0; 
			}
		}
	}
	.profile-info {
		margin-bottom: 1.3%;
		h1.account-subpage-heading {
			display: inline-block;
			margin: 30px 0 0 6px;
		}
		h2 {
			@include Univers($UniversRoman);
			font-size: $base-font + 2px;
			margin: 18px 0 16px 6px;
    		line-height: 24px;
		}
		legend {
			font-size: $base-font + 13px;
			@include Univers($UniversCondensed);
			padding: 26px 0 12px 2px;
			margin: 0 0 33px 0;
		    display: inline-block;
		    width: 100%;
		}
		.inner-content {
			padding: 0 9.3% 5.4% 4.4%;
		}
		.form-row {
			
			input[type="text"], input[type="password"] {
				padding: 21px 15px 13px 20px;
			}
		}
		
		button {
		    color: $white;
			letter-spacing: 1px;
			padding:15px 0;
		}
		.make-label-absolute {
			label {
				span {
					@include Univers($UniversCondensed);
					font-size: $base-font + 1px;		
				}
			}
		}
		.field-wrapper {
			button {
				&.pwd-show {
					font-size: $base-font - 1px;		
				}
			}
		}
	}
	.setnewpassword {
		h1 {
     		@include Univers($UniversCondensed);
     		font-size: $base-font + 13px;
     	}
     	button {
 			background: $navy-blue;
		    border-color: $navy-blue;
		    @include Univers($UniversBoldCondensed);
		    font-size: $base-font + 3px;
		}
		.resetpassword {
			a {
				@include Univers($UniversBoldCondensed);
			}
		}
	}
	//Check order status page 
	.check-status-order {
		input[type="text"] {
			border-radius: 0;	
		}
		.col-1 {
			p {
				@include Univers($UniversRoman);
			}
		}
	}
}
// Account payment page.
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.paymentslist {
		.payment-list {
			.cardinfo {
				.carddetails {
					.plcc-card-message {
						@include Univers($UniversRoman);
						
						b {
	    					@include Univers($UniversBoldCondensed);
	    				}
	    				
						.rewards {
							color: $navy-blue;
						}	
					}
				}
			}
		}
	}
	
	&.paymentinstruments {
		#secondary {
			nav {
				li {
					&.payment-methods {
						a {
							color: $navy-blue;
						}
					}
				}
			}
		} 
	}
	
	.paymentslist {
		.top-content {
			.default-msg {
				@include Univers($UniversCondensed);
				line-height: 29px;
			}
		}
		&.make-label-absolute {
			.makeDefault {
				label.input-focus {
					span {
						@include Univers($UniversCondensed);
					}
				}
			}
		}

		@media screen and (min-width: 768px) and (max-width: 1023px) {
			&.make-label-absolute .form-row {
				&.month,&.year {
					label {
						text-align: left;
						transform: translate(0,7px);
						width: 89%;
						max-width: 80px;
					}
				}
			}
		}
		 
		
		.payment-form {
			.form-row {
    			label {
    				span {
    					@include Univers($UniversCondensed);
    				}
    			}
    		}
			.right-cont {
				.billing-payment-description {
					.heading {
	    				@include Univers($UniversCondensed);
					}
					.brand-cards {
						span {
	    					@include Univers($UniversCondensed);
						}
					}
				}
			}
			
			.card-actions {
				.save-cancel {
					.cancel-button {
						font-size: $base-font;
						color: $navy-blue;
						
						&:hover {
							background: transparent;
							border-color: transparent;
						}
						
						&:focus {
							outline: transparent;
    						box-shadow: none;
						}
					}
				}
			}
		}
		.payment-list {
			.showall {
				@include Univers($UniversBoldCondensed);
				font-size: $base-font;
			}
			.cardinfo {
				.carddetails {
		    		@include Univers($UniversCondensed);
		    		font-size: $base-font + 2px;
		    		.li.cardtype {
	    				@include Univers($UniversBoldCondensed);
		    		}
		    		.li.delete-card {
	 					@include Univers($UniversBoldCondensed);
		    		}
		    		.li.make-default {
	    				@include Univers($UniversBoldCondensed);
	    				color: $navy-blue;
	    				font-size: $base-font;
	    			}
	    			.li.delete-card {
				    	.button-text {
				    		@include Univers($UniversBoldCondensed);
				    	}
				    }
				}
			}
		}
	}
}
//Email preferences page for WW .
.legacy-sitegen .email-preferences {
	.edit-click {
		@include Univers($UniversBoldCondensed);
		font-size: $base-font + 2px;
    	letter-spacing: 1.5px;
	}
	
	.email-thankyou {
		margin-bottom: 8px;
		
		h1 {
			&.offer {
				@include Univers($UniversBoldCondensed);
				color: $navy-blue;
			}			
		}
		.code {
		    @include Univers($UniversBoldCondensed);
		    
		    .value {
		    	@include Univers($UniversBoldCondensed);
		    	color: $navy-blue;
		    }
		}
		.expirytime {
			@include Univers($UniversCondensed);
			font-size: $base-font + 1px;
		}
	}
	.user-info {
		span {
			margin: 10px 0;
			
			&.email-communication {
			    margin: 5px 0;
			}
		}
	}
	
	.email-preference-row {
		.col-2 {
			.email-description {
				@include Univers($UniversCondensed);
				font-size: $base-font + 2px;
				line-height: 19px;
			}
			.preference-options {
				.options-checkbox {
					@include Univers($UniversCondensed);
					font-size: $base-font + 2px;
					margin: 32px 34px 0 0;
				}
			}
			.left-text {
			    margin-right: 10%;
			}
		}
	}
	.sisterbrands {
		.email-preference-row {
			padding: 12px 12px 26px;
			.col-2 {
				.email-description {
					@include Univers($UniversCondensed);
					font-size: $base-font + 1px;
					letter-spacing: 0;
				}
			}
		}
	}
	.morelink {
		color: $navy-blue;
	}
}
.custom-slider-checkbox {
	.slider-label {
		@include Univers($UniversBoldCondensed);
	}
	input:checked + .slider {
		background-color: $navy-blue;
	}
}
// Delete card confirmation dialog
.ui-dialog {
	&.delete-payment-card {
		width: 760px !important;
	}
	.delete-creditcard-overlay {
		max-width: 615px;
		
    	.cancle-delete-creditcard {
			min-width: 175px;
			
			@media screen and (max-width: 767px) {
				min-width: 46.5%;
			}
		}
	}
}
.emailsubscription-guest {
	.subcription-content {
		@include Univers($UniversBoldCondensed);
	}
	
	.subscribe-emailid {
		@media screen and (min-width: 768px) {
			width: 40%;
		}
		
		&.email-unsubscribe-guest {
			width: 100%;
			
			.form-row {
				&.form-row-button {
					button, button.emailsubscribeguest {
					    padding: 15.7px 30px;
					    max-height: 52px;
					}
				}
			}
		}
		
		.form-row {
			&.email {
				input[type=text] {
					padding: 15.5px 0 15.5px 17px;
					border: 2px solid $navy-blue;
					border-radius: 50px;
					
					@media screen and (min-width: 768px) {
						border-top-right-radius: 0;
    					border-bottom-right-radius: 0;
						border-right: 0;
					}
				}
			}
			
			&.form-row-button {				
				button, button.emailsubscribeguest {
					border-radius: 50px;
				    padding: 18.5px 30px;
				    border: 2px solid $navy-blue;
				    max-height: initial;
				    
				    &:hover {
			    		border-color: $KS-sky-blue;
				    }
				    
				    @media screen and (min-width: 768px) {
						border-top-left-radius: 0;
				    	border-bottom-left-radius: 0;
					}
				}
			}
		}
	}
}
.subscribe-emailid {
	.form-row-button {
		button {
		    max-height: 52px;
		}
	}
	.email-box {
		@include Univers($UniversCondensed);
		height: 48px;
		line-height: 48px;
	}
}
.unsubscribe-confirmation-email {
	.email-unauthenticatedunsubscribe-subhead {
		span {
			@include Univers($UniversCondensed);
		}
	}
}
.email-frequency-modal {
	@include Univers($UniversCondensed);
	
	button {
		min-width: 200px;
	}
}
.setnewpassword {
	button.btn.btn-primary:not(.pwd-show) {
		height: 46px;
		top: -2px !important;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		left: -5px !important;
   }
   @media screen and (max-width: 767px) {
	button.btn.btn-primary:not(.pwd-show) {
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		left: 0px;
		}
	}
}
.forgot-password {
	.make-label-absolute {
		.field-wrapper {
			input[type="text"] {
				-webkit-appearance: none;
			}
		}
	}
	.wrapper-forgot-password {
		h1 {
			@include Univers($UniversCondensed);
		}
		p {
			@include Univers($UniversCondensed);
			font-size: $base-font + 3px;
			line-height: 28px;
			
			@media screen and (max-width: 767px) {
				br {
					display: none;
				}
			}
		}
		.passwordResetConfirmMsg{
			@include WorkSansMedium($WorkSans);
			@media screen and (max-width: 767px) {
				font-size:$base-font;
				line-height:22px;
			}
		}	
	}
	button {
		background: $navy-blue;
	    border-color: $navy-blue;
	    @include Univers($UniversBoldCondensed);
	    font-size: $base-font + 3px;
	}
	.close-button {
		@include Univers($UniversBoldCondensed);
		font-size: $base-font + 3px;
	}
	
	.make-label-absolute {
		.field-wrapper {
			input[type="text"] {
				@include Univers($UniversCondensed);
				font-size: $base-font + 3px;
				color: $slightblack;
			}
		}
		label {
			span {
				color: $dusty-gray;	
			}
		}
	}
}
//Catalog request
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.catalog-request{ 
		max-width: 930px;
		.address-form-block {
        	.make-label-absolute {
        		.form-row {
        			&.state {
            			label {
            				&.input-focus {
            					-webkit-transform: translate(0, 4px);
    							transform: translate(0, 4px);
    							-moz-transform: translate(0, 4px);
            				}
            			}
            		}
        		}
        	}
        }
		.catalog-request-header{
			margin: 31px 0 19px 0;
			font-size: $base-font + 15px;
		}
		.catalog-request-callout{
			font-size: 14px;
			margin-bottom: 17px;
		}
		.address-form-block{
			border: 0;
			padding: 39px 52px 44px 65px;
			width: 100%;
			.address-section{
				div{
					&.catalog-prefernces{
						padding-left: 10px;
						div{
							&.form-row{
								label{ 
									span{
										@include Univers($UniversCondensed);
									}
									&.input-focus{
										-webkit-transform: translate(5px, 11px);
										-ms-transform: translate(5px, 11px);
			    						transform: translate(5px, 11px);
									}
								}
							}
						}
					}
				}
			}
			.make-label-absolute{ 
				.form-row{
					padding: 0px 4.5% 0 0;
					&.form-row-button{ 
						.apply-button{
							width: 69%;
							padding: 15px 10px;
						}
					}
					label{
						span{
							@include Univers($UniversCondensed);
						}
					}
					input{
						@include Univers($UniversCondensed);
						padding: 22px 15px 10px;
					}
				}
			}
		}
		.catalog-request-step1, .catalog-request-step2{ 
			font-size: 14px;
			letter-spacing: 0.7px;
			span{
				@include Univers($UniversBoldCondensed);
				letter-spacing: 1px;
			}
		}
		.catalog-request-step2{ 
			line-height: 21px;
			width: 91%;
		}
		.make-label-absolute{
			.field-wrapper{ 
				input{
					@include Univers($UniversCondensed);
					padding: 18px 15px 14px 15px;
					border-radius: 0;
				}
				.selected-option{
					border-radius: 0;
					@include Univers($UniversCondensed);
				}
				select{
					@include Univers($UniversCondensed);
				}
			}
		}
	}
	.catalog-registered{
		#secondary{ 
			.secondary-navigation{
				.navigation-links-row{
					li{
						&.catalog-preferences{
							a{
								color: $navy-blue;
							}
						}
					}
				}	
				@media screen and (min-width: 768px) {	
					margin-bottom: 37px;
				}
			}
		}
		
	}
}
// Address
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.internation-shipment-method {
		@include Univers($UniversCondensed);
		font-size: $base-font + 3px;
		background: $light-black-two;
		color: $white;
		text-align: center;
		line-height: 18px;
    	padding: 6px 0 8px;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
		
		@media screen and (max-width: 767px) {
		    text-align: left;
		    padding: 6px 9px 8px;
		    
		    .shipping-method {
		    	margin-right: 15px;
		    }
		}
	}
	
	&.addresses{
		
		#secondary{ 
			.secondary-navigation{
				@media screen and (min-width: 768px) {	
					margin-bottom: 37px;
				}
			}
		}
		.address-conatiner{
			max-width: 958px;
			.address-links{
				font-size: 12px;
				font-weight: 700;
				.address-edit, .address-delete{
					font-size: 12px;
					font-weight: 700;
				}
			}
		}
		.addressinfo{
			margin-top: 30px;
		}
		.default-indicator{
			margin: 8px 0 0 22px;
		    padding: 0 0 0 17px;
		    letter-spacing: 0.6px;
		}
		.address-create{
			margin-top: 6px;
			color: #002953;
		}
		.address-main-heading{
			margin-bottom: 22px;
		}
		.address-list{ 
			li{
				width: 43.4%;
			}
			.address-form-block{
				padding: 29px 61px 26px 62px;
			}
		}
		.address-form-block{ 
			.make-label-absolute{ 
				.form-row{
					margin: 0 0 13px 0;
					&.form-row-button {
						margin-bottom: 0;
					}
					input[type="text"], .field-wrapper .custom-select .selected-option {
						padding: 21px 15px 11px 20px;
					}
					.field-wrapper .custom-select .selected-option {
						line-height: normal;
					}
					.selected{
						font-size: 14px;
					}
				}
				label{
					&.input-focus{ 
						span{
							font-size: 11px;
						}
					}
				}
				.form-row {
					&.makeDefault{
						label {
							span{
								font-size: 12pt;
							    font-weight: 500;
							}
							&.input-focus{ 
								span{
									font-size: 12pt;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		.profile-info {
			button {
				font-size: $base-font + 1px;
			}
		}
		.catalog-request{ 
			.catalog-request-step1, .catalog-request-step2{
				@include Univers($UniversRoman);
			}
			.catalog-prefernces{
				span{
					@include Univers($UniversRoman);
				}
			}
		}
		&.profile {
			.profile-info {
				.inner-content {
					padding: 0 3.4% 5% 3.4%;
				}
				.custom-select {
					.form-row {
						width: 100%;
					}
				}
				.form-row-button {
					padding: 0;
				}
			}
		}
		&.addresses{ 
			.address-list{ 
				.address-form-block{
					padding: 26px 10px;
				}
			}
		}
	}
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account { 
		.catalog-request{
			max-width: 730px;
			.address-form-block{
				padding: 37px 30px 45px 50px;
				.make-label-absolute{ 
					.form-row{
						padding: 0px 5.2% 0 0;
					}
				}
			}
		}
		&.addresses{ 
			.address-conatiner{
				margin: 0 20px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account{
		&.profile {
			.profile-info {
				.form-row {
					width: 100%;
				}
				legend {
					padding: 20px 0 20px 0;
				}
			}
		} 
		&.addresses {
			.address-list{ 
				li{
					width: 100%;
				}
			}
			#secondary{ 
				.secondary-navigation{
					margin-bottom: 20px;
				}
			}
			.address-main-heading{
				margin-bottom: 15px;
			}
			
		}
		.catalog-request{ 
			.catalog-request-header{
				margin: 10px 0 10px 0;
			}
			.catalog-request-callout{
				font-size: 13px;
				line-height: 24px;
				margin-bottom: 9px
			}
			.catalog-request-step1, .catalog-request-step2{
				font-size: 13px;
				line-height: 20px;
			}
			.address-form-block {
				padding: 13px 11px 25px;
				div{
					&.catalog-prefernces{
						padding: 21px 0 13px 0;
						div{
							&.form-row{
								margin: 0 0 22px 0;
							}
						}
					}
				}
				.make-label-absolute{
					label{ 
						span{
							font-family: $HelveticaMedium;
						}
					}
					.field-wrapper{
						input{
							font-family: $HelveticaMedium;
							padding: 24px 15px 10px;
						}
						select{
							font-family: $HelveticaMedium;
						}
					}
					.form-row{
						padding: 0;
						&.form-row-button{
							button{
								&.apply-button{
									padding: 14px 10px;
									font-size: 15px;
									width: 100%;
								}
							}
						}
					}
					.catalog-prefernces{ 
						span{
							@include Univers($UniversRoman);
						}
					}
				}
			}
		}
	}
	// Delete card confirmation dialog
	.ui-dialog {
		&.delete-payment-card {
			width: 638px !important;
		}
		.delete-creditcard-overlay {
			max-width: 500px;
		}
	}
}
@media screen and (max-width: 767px) {
	.ui-dialog {
		.delete-creditcard-overlay {
			max-width: 100%;
		}
	}
	.legacy-sitegen .email-preferences {
		.user-info {
			span {
				font-size: $base-font + 1px; 
			}
		}
		.email-preference-row {
			.col-2 {
		 		font-size: 12px;
		 		
		 		.preference-options {
		 			.options-checkbox {
		 				margin: 20px 5px 0 0;
		 				
		 				.input-checkbox {
		 					margin: 0 5px 0 0;
		 				}
		 			}
		 		}
		 		.left-text {
			    	margin-right: 0;
				}
		 	}
		 	.email-preference-brand{
		 		width:142px;
		 		margin: 0 0 5px 0;
		 	}
		}
		.sisterbrands {
			.email-preference-row {
				padding: 10px 0;
			}
		}
		
	}
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account { 
		.address-list{ 
			.address-form-block{ 
				.make-label-absolute{ 
					.form-row{
						&.form-row-button{
							.cancel-address{
								font-size: 13px;
							}
							.apply-button{
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
}

.legacy-sitegen .pt_account .address-form-block .make-label-absolute .form-row.makeDefault label span{
	top:0px;
}

 