//Catagory Banner
.content-banner-breadcrumb {
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        margin: 0 auto 10px;
    }
    &.absolute {
        .category-banner-text {
            position: absolute;
            top: 42%;
            left: 20%;
            width: auto;
        }
    }
}
.slot-grid-header {
    position: relative;
    display: table;
    &.no-category-banner {
        height: 140px;
        background-color: $white-smoke;
        display: table;
        width: 100%;
        @media screen and (max-width: 767px) {
            height: auto;
        }
        .standard-contentslot {
            height: auto;
        }
        .category-banner-text {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            position: static;
            top: 0;
            left: 0;
            @media screen and (max-width: 767px) {
                display: block;
                text-align: left;
            }
            .category {
                @include Univers($UniversBoldCondensed);
                font-size: $base-font + 33px;
                padding: 0;
                color: $black;
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 6px;
                    margin: 10px 0 10px 14px;
                    letter-spacing: 3px;
                }
            }
            p {
                color: $black;
                @include Univers($Univers);
                font-size: $base-font + 10px;
                margin: 0;
                padding: 0;
            }
        }
    }
    .standard-contentslot {
        position: relative;
        z-index: 1;
        height: 25px;
    }
    img {
        height: auto;
        max-width: 100%;
        width: 100%;
    }
    .category-banner-text {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        .category {
            font-size: $base-font + 48px;
            letter-spacing: 15px;
            @include WorkSansExtraBold($WorkSans);
            text-transform: uppercase;
            color: $white;
            margin: 0;
        }
        p {
            font-size: $base-font + 6px;
            font-family: $Lora;
            color: $white;
            padding: 0 0 0 20px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .slot-grid-header {
        &.no-category-banner {
            height: 120px;
            .category-banner-text {
                p {
                    font-size: $base-font + 8px;
                    &.category {
                        font-size: $base-font + 23px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .slot-grid-header {
        .category-banner-text {
            .category {
                font-size: $base-font + 38px;
            }
            p {
                padding: 0;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .slot-grid-header {
        &.no-category-banner {
            .category-banner-text {
                p {
                    font-size: $base-font + 6px;
                    &.category {
                        font-size: $base-font + 18px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .slot-grid-header {
        &.no-category-banner {
            height: auto;
            .category-banner-text {
                margin: 0;
            }
        }
        .category-banner-text {
            position: relative;
            left: 0;
            text-align: center;
            top: 0;
            transform: inherit;
            -ms-transform: inherit;
            -webkit-transform: inherit;
            margin: 0 0 10px 0;
            width: 100%;
            display: none;
            .category {
                font-size: $base-font + 13px;
                color: $black;
                letter-spacing: 6px;
                margin: 0;
            }
            p {
                padding: 0;
                color: $dim-gray;
                font-size: $base-font + 1px;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .slot-grid-header {
        &.no-category-banner {
            .category-banner-text {
                p {
                    font-size: $base-font + 6px;
                    &.category {
                        font-size: $base-font + 8px;
                    }
                }
            }
        }
    }
}
.slot-grid-header {
    &.no-category-banner {
        .category-banner-text {
            p {
                &.category {
                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        font-size: $base-font + 33px;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 6px;
                    }
                }
            }
        }
    }
}

// SC-9149
.category-seo-text {
	.read-more, .read-less{
		color: $navy-blue;
	}
}
// End: SC-9149