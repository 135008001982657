.catalog-quick-order {
    .pdpForm {
        .form-row {
            label {
                span.error {
                    font-size: $base-font - 2px;
                }
            }
        }
    }
    .pdp-main {
        #shippinginfoBtn {
            letter-spacing: normal;
        }
        .heading {
            @include WorkSansBold($WorkSans);
            font-size: $base-font;
        }
        .pdp-main .product-col-2 .tab-content {
            font-family: $Lora;
            font-size: $base-font;
        }
        .product-price {
            span {
                font-size: $base-font + 6px;
                color: $navy-blue;
            }
            .price-standard {
                padding: 0 35px 0 0;
                @media screen and (max-width: 767px) {
                    padding: 0 19px 0 0;
                }
            }
            .price-standard-exist {
            	span{
                	color: $red;
                }
            }
        }
        .savingmessage {
            color: $reddish-monza;
            margin: 0 0 13px 0;
            @media screen and (max-width: 1023px) {
                margin: 0 0 18px 0;
            }
        }
        .promotion {
            color: $reddish-monza;
            margin: 0 0 17px 0;
            .promotion-callout {
                font-size: $base-font + 2px;
            }
            @media screen and (max-width: 767px) {
                margin: 0 0 23px 0;
            }
        }
        .product-variations {
            .attribute {
                .attribute_label {
                    margin: 14px 0 2px 0;
                    @media screen and (max-width: 1023px) {
                        margin: 16px 0 7px 0;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 2px;
                        margin: 14px 0 3px 0;
                    }
                }
            }
        }
        .product-col-2 {
            .tab-content {
                p,
                ul {
                    font-family: $Helvetica;
                    font-size: $base-font + 4px;
                    @media screen and (max-width: 767px) {
                        font-size: $base-font + 2px;
                    }
                }
            }
            .tab-heading {
                font-family: $Helvetica;
            }
            @media screen and (max-width: 767px) {
                .shippingdrawer {
                    p {
                        font-size: $base-font + 2px;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .heading,
            .read-more,
            .clickhere {
                font-size: $base-font + 2px;
            }
            .productinfo {
                .moreinfo {
                    font-size: $base-font + 2px;
                }
            }
        }
    }
    .catalog-quick-order__main {
        .product-name {
            .name-text {
                padding: 0 0 20px 0;
                @media screen and (max-width: 767px) {
                    padding: 0 0 7px 0;
                    font-size: $base-font + 12px;
                }
            }
        }
        .pdp-main {
            .product-price {
                margin: 0 0 17px 0;
            }
            .product-variations {
                .attribute {
                    border-top: 1px solid $gainsboro;
                    padding: 0 0 10px 0;
                    @media screen and (max-width: 1023px) {
                        padding: 0 0 10px 0;
                    }
                    @media screen and (max-width: 767px) {
                        padding: 0 0 10px 0;
                    }
                    &.sizeFamily {
                        padding: 0 0 17px 0;
                        border-bottom: 1px solid $gainsboro;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .expired-price {
                    margin: 0 0 13px 0;
                }
            }
        }
        .cqo-btn-search {
            padding: 15px 36px;
            font-size: $base-font + 6px;
        }
    }
}
