.search-result-options {
    .pagination {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: max-content;
            margin: 0 auto;
            &.pageMaxCount {
                display: inline-block;

                li {
                    margin-right: 65px;
                }
            }
        }

        li {
            border-radius: 0;
            @include Univers($UniversCondensed);
            border: none;
            background: transparent;
            color: $navy-blue;
            width: auto;
            line-height: normal;
            position: relative;
            font-size: $base-font + 6px;
            a {
                padding: 0 10px;
            }
            &.current-page {
                color: $navy-blue;
                background: transparent;
                &:after {
                    content: "";
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    background: $white;
                    border-radius: 100%;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
                    margin-left: 50%;
                    left: -12.5px;
                    transform: translateY(-1px);
                    z-index: -1;
                }
            }
            &.first-last a {
                background: url("../../../images/ks/carat-up-blue.svg")
                    no-repeat 45% 0;
                display: inline-block;
                background-size: 20px;
                span {
                    color: transparent;
                    visibility: hidden;
                }
                &.page-previous {
                    transform: rotate(-90deg);
                }
                &.page-next {
                    transform: rotate(90deg);
                    background-position: 56% 0;
                }
            }
            &.dot-page {
                padding: 12px 0 0;
            }
        }
    }
}

.search-result-items {
    .grid-tile {
        margin: 0 auto 32px;
        border: 1px solid transparent;
        .product-tile {
            max-width: 245px;
            &:hover {
                border: 1px solid $mercury;
            }
        }
    }
}
.search-result-items.plp-category-page {
    @media screen and (max-width: 767px) {
        .grid-tile {
            .product-tile {
                min-width: 110px;
                &:hover {
                    border: 1px solid $very-light-gray;
                }
            }
        }
    }
}
.view-more {
    .viewmore-grid {
        font-size: $base-font + 2px;
        @include Univers($UniversBoldCondensed);
        border-radius: $KS-Btn-radius;
    }
}
