@import "nav";
@import "menu_utility";
@import "country_selector";
//Header-Promo-Banner
.header-promo-banner {
    .header-promotion {
        @include WorkSansMedium($WorkSans);
    }
}
.menu-toggle {
    span {
        background: $mobile-menu-bar-color;
    }
}
//Top-banner
.header-offers {
    .offer-flyout-main {
        span {
            &.offers {
                @include WorkSansMedium($WorkSans);
            }
        }
    }
    .offers-flyout-banner {
        .viewall-offers {
            a {
                @include WorkSansSemiBold($WorkSans);
            }
        }
    }
}
.header-secondary {
    .header-search {
        .quick-order {
            .catalogOrderIcon {
                .cqo_text {
                    color: $navy-blue;
                    @include WorkSansMedium($WorkSans);
                }
            }
        }
        input[type="text"] {
            @media screen and (min-width: 1024px) {
                @include WorkSansMedium($WorkSans);
            }
        }
        button {
            &:hover {
                color: $citrus;
            }
        }
    }
}
//Header-promo-bottom
.header-promo-bottom {
    .promo-banner {
        font-family: $Lora;
        a {
            color: $navy-blue;
            border-bottom: 1px solid $navy-blue;
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .slider-menu {
        #navigation {
            background: $wild-sand;
            .menu-category {
                background: $white;
            }
        }
    }
    .header-secondary {
        .header-search {
            input[type="text"] {
                @include Univers($UniversBoldCondensedOblique);
                color: $navy-blue;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .top-menu-utility {
        .menu-utility {
            .menu-utility-user {
                #mini-cart {
                    .mini-cart-total {
                        span {
                            @include WorkSansMedium($WorkSans);
                            &.minicart-quantity {
                                background-color: $navy-blue;
                            }
                        }
                    }
                }
            }
        }
    }
    .top-banner {
        .header-mian-banner {
            .header-secondary {
                .header-search {
                    &.active {
                        #search-suggestions {
                            .search-suggestion-wrapper {
                                .suggested-phrase {
                                    @include WorkSansMedium($WorkSans);
                                }
                                .search-phrase {
                                    font-family: $Helvetica;
                                    a {
                                        span {
                                            font-family: $HelveticaBold;
                                        }
                                    }
                                }
                                .phrase-suggestions {
                                    .hitgroup {
                                        .hit {
                                            @include WorkSansBold($WorkSans);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .slider-menu {
        #navigation {
            .scroll-wrapper {
                .registered-user {
                    .my-account {
                        &.heading {
                            font-family: $Lora;
                            background: $wild-sand;
                        }
                    }
                    .account-logout {
                        a {
                            @include WorkSansSemiBold($WorkSans);
                        }
                    }
                }
            }
        }
    }
}
//Checkout Header Sizing
.pt_checkout {
    #header {
        .primary-logo {
            a {
                width: 150px;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_checkout {
        #header {
            .header-mian-banner {
                .help-click {
                    top: 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .pt_checkout {
        #header {
            .primary-logo {
                width: 150px;
            }
        }
    }
}
