/* WW-site breadcrumbs css start */
.breadcrumb,
.pt_customer-service .breadcrumb {
    @include Univers($UniversCondensed);
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    .breadcrumb-element {
        color: $KS-dark-grey;
    }
    @media screen and (min-width: 1024px) {
        background-color: $white;
        padding: 5px 7px;
    }
    @media screen and (max-width: 1023px) {
        border-top: 1px solid $KS-dark-grey;
        border-bottom: 1px solid $KS-dark-grey;
        
    } /* breadcrumb tablet media query end */
    @media screen and (max-width: 767px) {
        border-top: 1px solid transparent;
        border-bottom: 1px solid $KS-dark-grey;
        margin: 0 auto;
        /* breadcrumb mobile media query start */
        .back-arrow {
            background: url("../../../images/ks/carat-up-blue.svg") no-repeat 0
                0;
            transform: rotate(-90deg);
        }
        .breadcrumb-element {
            &.current-element {
                margin: 6px 4px;
            }
        }
    } /* breadcrumb mobile media query end */
}
.pt_customer-service .breadcrumb {
    padding: 10px 0 10px 10px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid $KS-dark-grey;
    margin: 0 auto 10px;
    width: calc(100% + 20px);
    left: -10px;
    @media screen and (max-width: 1023px) {
        padding: 10px 0 10px 30px;
        border-top: 1px solid $KS-dark-grey;
        width: calc(100% + 40px);
        left: -20px;
    }
    @media screen and (max-width: 767px) {
        border-top: 1px solid transparent;
        padding: 5px 0 5px 10px;
        width: calc(100% + 20px);
        left: -10px;
    }
}
/* WW-site breadcrumbs css end */
