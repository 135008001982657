.plcc-landing-content {
    .plcc-top-banner {
        .jl,
        .rm {
            display: none;
        }
    }
    .plcc-tabs {
        @include Univers($UniversBoldCondensed);
        font-size: $base-font + 9px;
        .tab {
            @include Univers($UniversCondensed);
            letter-spacing: 5px;
            padding-bottom: 1px;
            &.active {
                @include Univers($UniversBoldCondensed);
                color: $navy-blue;
                letter-spacing: 5px;
            }
            a {
                &::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 0;
                    left: -6px;
                    background: transparent;
                    height: 4px;
                    border-left: solid 4px transparent;
                    border-right: solid 4px transparent;
                    z-index: 2;
                    border-bottom: none;
                    -webkit-transition: width 0.25s ease-in-out;
                    transition: width 0.25s ease-in-out;
                    top: calc(100% - 1px);
                }
                &:hover {
                    &::after {
                        width: 100%;
                        background: $navy-blue;
                        border-left: solid 4px #000;
                        border-right: solid 4px #000;
                    }
                }
            }
        }
    }
    .benefits {
        .tiles-wrapper {
            .tile-row {
                display: flex;
            }
            .tile {
                h1 {
                    @include Univers($UniversCondensed);
                    font-size: $base-font + 13px;
                    color: $navy-blue;
                    text-transform: uppercase;
                }
                &.earn-rewards {
                    h1 {
                        margin-top: 18px;
                    }
                }
                &.off {
                    img {
                        margin-top: 42px;
                    }
                }
                &.free-ship {
                    img {
                        margin-top: 51px;
                    }
                    h1 {
                        margin-top: 12px;
                    }
                }
                &.annual-free {
                    img {
                        margin-top: 37px;
                    }
                    h1 {
                        margin-top: 21px;
                    }
                }
                &.perks {
                    img {
                        margin-top: 37px;
                    }
                    h1 {
                        margin-top: 14px;
                    }
                }
            }
        }
        .brands-list {
            margin: 55px 0 28px;
            h1 {
                @include Univers($UniversBoldCondensed);
                font-size: $base-font + 13px;
                color: $navy-blue;
                text-transform: uppercase;
            }
        }
    }
    .rewards {
        margin: 158px 0 52px;
        .circle {
            background: $navy-blue;
            .text-content {
                margin: 29% auto 0;
                max-width: 300px;
                h1 {
                    color: $white;
                    font-size: $base-font + 14px;
                    &.bold {
                        @include Univers($UniversBoldCondensed);
                        font-size: $base-font + 18px;
                        text-transform: uppercase;
                    }
                    &:nth-of-type(2) {
                        width: 180px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }

        table th {
            @include Univers($UniversCondensed);
            color: $navy-blue;
            text-transform: uppercase;
        }
        table td {
            color: $dim-gray;
            @include Univers($UniversCondensed);
        }
        .reward-look {
            color: $dim-gray;
            u {
                color: $black;
            }
        }
    }
    .perks-email-wrapper {
        margin: 45px auto 47px;
        .perks-signup {
            .plcc-landing-perks {
                h1 {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 22.6px;
                    letter-spacing: 10.5px;
                    line-height: 42px;
                    margin-top: 0;
                }
            }
            .plccbenefits-n-email {
                .plcc-platinumcard-benefits {
                    .plcc-benefits-head {
                        @include Univers($UniversCondensed);
                    }
                }
                
                .email-signup {
                    .input-text.email{ 
                        border-top-left-radius: 50rem;
                        border-bottom-left-radius: 50rem;
                        transform:scale(1.01, 1.08);
                    }
                }
            }
        }
        .perks-offer {
            margin-top: 0;
            .per-off-content {
                padding: 16px;
                margin-top: 10px;
                span {
                    font-size: $base-font + 6px;
                }
                h1 {
                    @include Univers($UniversBoldCondensed);
                    font-size: $base-font + 29px;
                    color: $navy-blue;
                    line-height: 40px;
                }
            }
        }
    }
    .plcc-bottom {
        .bottom-right {
            h1 {
                @include Univers($UniversBoldCondensed);
                font-size: $base-font + 13px;
                color: $navy-blue;
                text-transform: uppercase;
                line-height: 33px;
                margin-bottom: 18px;
            }
        }
        .bottom-left {
            .qa-section {
                .qa-content {
                    padding: 22px;
                    a.question {
                        font-size: $base-font + 2px;
                    }
                    .answer {
                        font-size: $base-font + 1px;
                    }
                }
            }
        }
        .plcc-text-content {
            margin: 45px 0 0;
        }
        .disclaimer {
            @include Univers($UniversRoman);
            font-size: $base-font;
        }
    }
    .pre-approved-cta {
        h1 {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 13px;
        }
        .approved {
            @include Univers($UniversBoldCondensed);
            color: $mineshaft;
        }
        .sign-in {
            @include Univers($UniversBoldCondensed);
            text-decoration: underline;
            color: $navy-blue;
            margin-top: 12px;
        }
        button {
            margin: 13px 0 32px;
        }
    }
}
@media screen and (max-width: 1240px) {
    .plcc-landing-content {
        .plcc-tabs {
            &.four-links {
                .tab {
                    margin: 0 4.2%;
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .plcc-landing-content {
        .plcc-tabs {
            &.four-links {
                .tab {
                    margin: 0 3.2%;
                }
            }
        }
        .plcc-top-banner {
            overflow: hidden;
        }
        .benefits {
            .tiles-wrapper {
                .row.row-2 {
                    margin-top: 48px;
                }
            }
            .brands-list {
                margin: 55px 0 35px;
            }
        }
        .rewards {
            margin: 40px 0 52px;
            .circle {
                .inner-circle {
                    margin: 3.6% auto 0 3.6%;
                    display: inline-block;
                }
            }
            .reward-look {
                margin: 30px 0 10px;
            }
        }
        .perks-email-wrapper {
            margin: 27px auto 35px;
            .perks-offer {
                padding-bottom: 0;
                border-bottom: 0;
            }
            .plccbenefits-n-email {
                .footer-email-signup {
                     .email-signup {
                        .input-text.email { 
                            height: 78px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_specials {
        #main {
            padding: 0 10px;
        }
    }
    .plcc-landing-content {
        .plcc-tabs {
            &.four-links {
                .tab {
                    margin: 0 1.2%;
                }
            }
        }
        h1 {
            font-size: $base-font;
            line-height: normal;
        }
        .plcc-tabs {
            display: inline-block;
            font-size: $base-font - 2px;
            padding: 10px 0;
            .tab {
                letter-spacing: 2px;
                width: auto;
                display: inherit;
                &.active {
                    border-width: 2px;
                }
            }
        }
        .benefits {
            .tiles-wrapper {
                .tile {
                    width: 50%;
                    margin: 0 0;
                    padding: 6px;
                    min-height: 247px;
                    h1 {
                        margin: 13px 0 11px 0;
                        font-size: $base-font;
                        line-height: normal;
                    }
                    span {
                        font-size: $base-font - 1px;
                        line-height: normal;
                    }
                }
                .tile:nth-child(even) {
                    float: right;
                }
                .row {
                    .row-2 {
                        margin-top: 0;
                    }
                }
            }
            .pre-approved-cta {
                button {
                    max-width: 62%;
                    margin: 20px 0 16px;
                }
            }
            .brands-list {
                display: none;
            }
        }
        .rewards {
            margin: 55px 0 27px;
            padding: 0 0;
            .circle {
                width: 84vw;
                height: 84vw;
                width: 247.5px;
                height: 247.5px;
                .text-content {
                    max-width: 180px;
                    margin: 20% auto 0;
                    h1 {
                        font-size: $base-font;
                        margin-bottom: 3%;
                        line-height: 16px;
                        &.bold {
                            font-size: $base-font;
                        }
                    }
                }
            }
            .table-wrapper {
                margin: -32.5% auto 0;
                margin: -62px auto 0;
                max-width: 301px;
                .table-content {
                    padding: 5px;
                }
            }
            table th {
                font-size: $base-font - 2px;
                padding: 5px 10px;
            }
            table td {
                font-size: $base-font;
                padding: 5px 10px;
            }
            .reward-look {
                width: 100%;
                font-size: $base-font - 1px;
                line-height: 16px;
            }
        }
        .perks-email-wrapper {
            margin: 5px auto 18px;
            padding: 0 0;
            .perks-signup {
                .plcc-landing-perks {
                    float: none;
                    h1 {
                        font-size: $base-font + 16.5px;
                        margin-top: 2px;
                    }
                }
                .plccbenefits-n-email {
                    text-align: center;
                    float: none;
                    margin: auto;
                    width: 72.6%;
                    .plcc-platinumcard-benefits {
                        .plcc-benefits-head {
                            font-size: $base-font;
                            margin: 4px 0 7px;
                        }
                        .plcc-benefit-msg {
                            font-size: $base-font - 1px;
                            line-height: 16px;
                        }
                    }
                    .footer-container {
                        .footer-email-signup {
                            .email-signup {
                                margin: 9px 0;
                                .form-row {
                                    width: 60%;
                                }
                                .form-row-button {
                                    width: 40%;
                                    button {
                                        float: left;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .perks-offer {
                padding-bottom: 25px;
                margin-top: 0;
                .per-off-content {
                    padding: 4px 10px 10px;
                    margin-top: 0;
                    h1 {
                        font-size: $base-font + 13px;
                        letter-spacing: 6px;
                        margin: 0 0;
                        line-height: 30px;
                    }
                    span {
                        font-size: $base-font;
                    }
                }
            }
        }
        .plcc-bottom {
            padding: 0 0;
            .bottom-content {
                padding: 20px 0 0;
            }
            .bottom-right {
                margin: 50px 0 18px;
            }
            .plcc-text-content {
                font-size: $base-font - 3px;
                line-height: 13px;
            }
            h1 {
                font-size: $base-font + 8px;
            }
        }
        .pre-approved-cta {
            .approved {
                font-size: $base-font;
            }
            .sign-in {
                font-size: $base-font + 2px;
                margin-top: 8px;
            }
            button {
                max-width: 62%;
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .pt_specials {
        #main {
            padding: 0 10px;
        }
    }
    .plcc-landing-content {
        .perks-email-wrapper {
            padding: 0 0;
            .perks-signup {
                .plccbenefits-n-email {
                    width: 100%;
                }
            }
        }
    }
}
.plcc-landing-content {
    .plcc-top-content {
        .ww.athenticated-cardholders,
        .ww.authenticated-prescreened-non-pre-approved {
            .text-content {
                top: 17%;
                right: 10%;
                @media screen and (max-width: 1024px) {
                    top: 3%;
                }
                @media screen and (max-width: 767px) {
                    top: 0;
                    right: 0;
                }
                .msg {
                    font-size: 21px;
                }
            }
        }
    }
}
