.pt_content {
	.full-width {
		@media screen and (min-width: 1024px) {
			.primary-content {
				margin-top: -1px;	
			}
		}	
	}
}
.gift-landing {
    
    .more-gift-cards {
    	margin: 0 0 50px 0 !important;
    }
    
    .main-slot, .gift-slot-1, .more-gift-cards {
	    
	    .terms {
	    	font-size: $base-font + 2px;
	    	font-family: $Lora;
	    	margin: 0;
	    	width: 100%;
			float: left;
			position: absolute;
			bottom: 30px;
			text-align: center;
			
			a {
				margin: 0 5px 0 0;
				border-bottom: 1px solid $black;
			}
	    }
	    
	    .button-gift {
	   		width: auto;
   	 		display: inline-block;
	    }
	    
	    .content {	    
		    .valign_height {
			    position: relative;
			    
			    .e-gift, .gift-card {					
					h2 {
						font-size: $base-font + 43px;
						@include WorkSansBold ($WorkSans);
					}
					
					button {
						font-size: $base-font + 2px;
					    @include WorkSansSemiBold ($WorkSans);
					    color: $white;
					    border-radius: 3px;
					    background: $navy-blue;
					    border-color: $navy-blue;
					}
			    }
			    
			    .description {
    				font-size: $base-font + 28px;
			    	@include WorkSansBold ($WorkSans);
			    	margin: 0 0 30px 0;
			    	text-transform: uppercase;
    				letter-spacing: 10px;
    				color: $white;
    			}
    			
    			.js-gift-card-balance {
					margin: 20px;
					font-size: $base-font + 2px;
				    letter-spacing: 2px;
				    padding: 15px 20px;
				    @include WorkSansSemiBold ($WorkSans);
				    color: $white;
				    border-radius: 3px;
				    background: $navy-blue;
				    border-color: $navy-blue;
				}
		    }
	    }
    }
    
    .white-background {
    	
    	.more-gifts {
    		font-size: $base-font + 28px;
	    	@include WorkSansBold ($WorkSans);
	    	margin: 10px 0;
	    	text-transform: uppercase;
			letter-spacing: 10px;
    	}
    	
    	.shop-gift {
    		font-size: $base-font + 6px;
	    	font-family: $Lora;
	    	margin: 0 0 30px 0;
	    	text-transform: initial;
	    	letter-spacing: 1px;
    	}
    	
    	.cards {   
    		button {
			    padding: 15px 30px;
			    background: transparent;
			    color: $navy-blue;
			    border-color: $navy-blue;
			    @include WorkSansSemiBold ($WorkSans);
			    border-radius: 3px;
    		}
    	}
    }
}
.gift-landing{
	.white-background {
		.cards {			
			button {
				@media screen and (max-width: 1023px) {
					font-size: $base-font;
					border-width: 2px;
					padding: 16px 35px;
				}
				@media screen and (max-width: 767px) {
					font-size: $base-font;
					border-width: 2px;
					padding: 16px 30px;
				}
				@media screen and (max-width: 480px) {
					padding: 10px 20px;
				}								
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.gift-landing {
	    .main-slot, .gift-slot-1, .more-gift-cards {
		    .content {
			    left: 50%;
			    width: 100%;
			    
			    .valign_height {
			    	max-width: 470px;
	    			float: none;
				    
				    .e-gift, .gift-card {					
						h2 {
							font-size: $base-font + 33px;
							margin: 0 0 60px 0;
						}
						
						button {
							margin: 0 20px 0 0;
						}
				    }
			    }
		    }
	    }
	    
	    .white-background {
	   		width: 100%;
			padding: 40px 5px;
			
			.shop-gift {
				margin: 0;
			}
			
			.cards {
				margin-top: 35px;
				width: 50%;
				
				&:nth-child(4) {
					margin: 35px 0 0 0;
				}
				
				img {
					width: 100%;
				}
			}
	    }
	}
}
@media screen and (max-width: 480px) {
	.gift-landing {
	    .main-slot, .gift-slot-1, .more-gift-cards {
		    .terms {
		    	bottom: 40px;
		    }
		    
		    .button-gift {
		   		width: 100%;
		    }
		    
		    .content {
		    	left: 50%;
		    	
			    .valign_height {
				    .e-gift, .gift-card {					
						h2 {
							font-size: $base-font + 13px;
							margin: 0 0 20px 0;
							letter-spacing: 5px;
						}
						
						button {
						    margin: 20px 0 0 0;
	    					display: inline-block;
	    					
	    					&.gift {
	    						padding: 14px 30px;
	    					}
						}
				    }
				    
				    .description {
    					font-size: $base-font + 13px;
    					margin: 0;
    					letter-spacing: 5px;
	    			}
			    }
		    }
	    }
	    
	    .gift-slot-1, .main-slot {
    		.content {
    			.valign_height {
    				.description {
    					max-width: 270px;
						margin: 0 auto;
    				}
    				
    				.e-gift {
    					h2 {
    						max-width: 270px;
							margin: 0 auto;
    					}
    				}				
    			}			
    		}	
    	}
	    
	    .white-background {
	    	.more-gifts {
				font-size: $base-font + 13px;
				letter-spacing: 5px;
	    	}
	    	
	    	.shop-gift {
	    		font-size: $base-font;
	    	}
	    }
	}
}
.ui-dialog {
	&.gc-checkbalance {
	    
	    
	    
	    .gc-balance {
	    	@include WorkSansSemiBold($WorkSans);
	    	
	    	.form-row {
	    		
	    		&.form-row-pincode {
	    			.form-row {
					    &.form-row-button {
					    	
					    	button {
					    		background: $navy-blue;
					    		border-color: $navy-blue;
					    		font-size: $base-font + 2px;
					    		letter-spacing: 2px;
					    		border-radius: 3px;
					    		padding-top: 14.5px;
    							padding-bottom: 14.5px;
					    	}
					    }
	    			}
	    			
	    		}
	    	}
	    	
	    	.gc-show-message {
	    		@include WorkSansMedium($WorkSans);
	    		
	    		.heading {
	    			@include WorkSansBold($WorkSans);
	    		}
	    	}
	    }
	}
}
