.only-for-bh-section {
    .bh-choose-item-below {
        font-family: $UniversBold;
        font-size: 16px;
        letter-spacing: 1px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            font-size: 18px;
            line-height: 1.11;
            color: $black;
        }
    }
}
.div-trending-section {
    .recommendations-heading {
        text-align: center;
        margin-bottom: 45px;
        width: 100%;
        display: inline-block;
        text-transform: capitalize;
        @include Univers($UniversCondensed);
        font-size: $base-font + 12px;
    }
    #carousel-recommendations {
        width: 100%;
        display: inline-block;
        text-align: center;
        .product-tile {
            width: calc(20% - 4px);
            width: -moz-calc(20% - 4px);
            width: -webkit-calc(20% - 4px);
            width: -o-calc(20% - 4px);
            float: none;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            .product-swatches {
                display: block;
            }
            .product-name {
                margin: 20px 0 11px 0 !important;
                font-size: $base-font + 1px;
                line-height: 20px;
            }
            .product-image {
                img {
                    width: 120px;
                    height: 173px;
                }
            }
            .p-image-rollover {
                display: none;
            }
        }
        button {
            &.slick-arrow {
                width: 17px;
                height: 23px;
                border: none;
                text-indent: -9999px;
                position: absolute;
                bottom: 0;
                margin: 0 auto;
                z-index: 9;
                -webkit-transition: initial;
                transition: initial;
                -ms-transition: initial;
                padding: 0;
                top: 50%;
            }
        }
    }
}
// PDP Recommandation styles
.pt_product-details {
    #main {
        .pdp-main {
            .last-visited-section {
                .last-visited-heading {
                    border: none;
                    text-align: center;
                    margin-bottom: 23px;
                    padding-bottom: 0;
                }
                .product-tile {
                    text-align: center;
                    width: 100%;
                    .product-image {
                        margin-bottom: 10px;
                    }
                    .product-name {
                        line-height: normal;
                        font-size: $base-font + 1px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    img.p-image {
                        display: inline-block;
                    }
                }
                .search-result-items {
                    max-width: 954px;
                    margin: 0 auto;
                    padding-right: 0;
                    .grid-tile {
                        padding-right: 45px;
                        border: none;
                    }
                }
                button {
                    &.slick-arrow {
                        margin: 0 auto;
                    }
                }
                @media screen and (min-width: 1024px) and (max-width: 1300px) {
                    .search-result-items {
                        .grid-tile {
                            padding: 0 53px;
                        }
                    }
                }
                @media screen and (min-width: 768px) and (max-width: 1030px) {
                    button {
                        &.slick-arrow {
                            top: 38%;
                        }
                    }
                }
                @media screen and (max-width: 1023px) and (min-width: 768px) {
                    .search-result-items {
                        max-width: 93%;
                        .grid-tile {
                            padding-right: 25px;
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    .last-visited-heading {
                        text-transform: capitalize;
                    }
                    .search-result-items {
                        .grid-tile {
                            padding-right: 15px;
                            padding-left: 15px;
                            max-width: inherit;
                            float: none;
                        }
                    }
                }
            }
        }
        .standardproductset {
            .promotion {
                width: 100%;
                display: inline-block;
            }
            .product-price {
                margin-bottom: 10px;
            }
            .product-col-2 {
                .top-add-all {
                    &.product-add-to-cart {
                        border-bottom: none;
                    }
                }
                .product-set-item {
                    border-top: 1px solid $light-gray;
                }
                .pdpForm {
                    .product-add-to-cart {
                        border-top: 1px solid $light-gray;
                    }
                }
                .product-variations {
                    .attribute {
                        &:last-child {
                            border-bottom: 1px solid $light-gray;
                        }
                    }
                }
                .product-hemmable {
                    border-top: none;
                }
                .product-monograming {
                    display: inline-block;
                    width: 100%;
                    border-bottom: 1px solid $light-gray;
                }
            }
            .product-set {
                #thumbnails {
                    .thumb {
                        @media screen and (max-width: 1023px) {
                            width: 21%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 25%;
                        }
                    }
                }
            }
            @media screen and (max-width: 1023px) {
                .product-thumbnails {
                    button {
                        &.slick-arrow {
                            &.slick-next {
                                display: none !important;
                            }
                            &.slick-prev {
                                display: none !important;
                            }
                        }
                    }
                    &.slick-arrow-bar {
                        &:before {
                            display: none !important;
                        }
                    }
                }
                .product-add-to-cart {
                    .wishlist {
                        float: left;
                        width: auto;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            margin: 0;
                        }
                    }
                }
            }
        }
        // Handling special product set page
        .specialproductset {
            .prod-detail-page {
                .info {
                    .product-bundle-main {
                        width: 100%;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        .product-col-2 {
                            &.product-set {
                                padding: 0;
                                box-sizing: border-box;
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                @media screen and (max-width: 1023px) {
                                    padding: 0;
                                }
                            }
                        }
                    }
                    .recommendation {
                        width: 16.1%;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                    }
                }
            }
            .product-col-1 {
                &.product-bundle {
                    padding: 0;
                }
            }
            .product-set {
                border-bottom: 0;
            }
            .product-bundle {
                .product-col-2 {
                    .product-actions {
                        border: 0;
                    }
                }
            }
            .product-teasers {
                margin-top: 5px;
            }
            .product-name {
                margin: 16px 0 5px;
            }
            .product-set {
                .product-price {
                    margin-bottom: 12px;
                }
            }
            .product-set-list {
                border-top: 1px solid $light-gray;
                margin-top: 24px;
                .product-set-item {
                    border-bottom: 6px solid $light-gray;
                }
                .product-set-item:last-child {
                    border-bottom: 0;
                }
                .product-variations {
                    ul {
                        .attribute {
                            border-bottom: 1px solid $light-gray;
                            padding: 0 0 17px 0;
                            &.size {
                                .attribute_label {
                                    margin: 24px 0 12px;
                                }
                                .swatches li a {
                                    margin: 3px 10px 7px 0px;
                                }
                            }
                        }
                        .attribute:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .promotion {
                    margin: 10px 0;
                    clear: both;
                }
            }
            .product-set-details .product-variations .attribute:nth-child(2n) {
                border: 0;
            }
            .product-add-to-cart {
                border-top: 1px solid $light-gray;
                .availability-web {
                    padding: 15px 0;
                }
                .shippingdetails {
                    margin: 29px 0 0;
                }
            }
            .share-icon-container {
                margin: 0 0;
            }
            .tabs {
                margin: 37px 0 0 0;
            }
        }
    }
}
//PDP page layouts settings
.pt_product-details {
    .b_product_badge {
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (min-width: 1025px) {
        #main {
            max-width: 100%;
            // Adding this class to div will adjust the content to center
            .adjust-inner-content {
                max-width: 1260px;
                margin: 0 auto;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #main {
            padding: 0 20px;
            margin: 0;
            .adjust-inner-content {
                padding: 0;
                margin: 0;
                width: 100%;
                box-sizing: border-box;
            }
            .primary-content {
                padding: 13px 0 0;
            }
            .product-col-1 {
                margin-right: 14px;
            }
            .product-name {
                font-size: 24px;
                line-height: 33px;
            }
            .product-add-to-cart {
                button {
                    &.all-set-button {
                        width: 100%;
                    }
                }
                button {
                    float: left;
                }
                .inventory {
                    width: 24.8%;
                }
                .wishlist {
                    float: none;
                    width: 100%;
                    padding: 20px 0 0 0;
                    a {
                        float: none;
                        display: inline-block;
                    }
                }
                .shippingdetails {
                    margin: 17px 0 0;
                }
            }
            // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none;
                        padding: 0;
                        margin: 0 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        .product-col-2 {
                            padding: 0 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #main {
            .e-giftcard-detail {
                .product-add-to-cart {
                    button {
                        width: 100%;
                    }
                }
            }
            .pdp-main {
                .product-review {
                    display: block;
                }
            }
            .adjust-inner-content {
                padding: 0;
            }
            .product-col-1 {
                &.product-set {
                    width: 100%;
                    padding-right: 0;
                    margin-right: 0;
                    .product-primary-image {
                        width: 48.7%;
                        float: left;
                        .product-primary-image {
                            width: 100%;
                        }
                    }
                    .product-col-1-productdetail {
                        float: right;
                        width: 46.4%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            float: none;
                            padding: 0 10px;
                        }
                    }
                }
            }
            .product-col-2 {
                &.product-set {
                    width: 100%;
                    margin-right: 0;
                    .product-add-to-cart {
                        button {
                            width: 218px;
                            &.all-set-button {
                                float: right;
                            }
                        }
                        .inventory {
                            width: 13.33%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        .header-promo-bottom {
            .promo-banner {
                margin: 15px auto 10px;
            }
        }
        #main {
            .product-col-2 {
                padding: 0;
                .product-name {
                    margin: 3px 0 22px -2px;
                }
                .product-price {
                    .price-standard {
                        padding-right: 20px;
                        margin-left: -2px;
                    }
                }
                .product-variations {
                    .attribute {
                        .attribute_label {
                            margin: 11px 0 11px;
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    width: 23.097%;
                    .custom-select {
                        select {
                            @include Univers($UniversRoman);
                            border-radius: $KS-Btn-radius 0 0 $KS-Btn-radius;
                            padding: 0 21px;
                            -webkit-transition: none;
                            transition: none;
                            border: 2px solid #002953;
                            height: 50px;
                        }
                    }
                }
                button {
                    font-size: 14px;
                }
            }
            .share-icon-container {
                .share-icon-label {
                    letter-spacing: 2px;
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0;
                        &.share-facebook {
                            padding: 0 26px;
                        }
                        &.share-twitter {
                            padding: 0 4px;
                        }
                        &.share-mail {
                            padding: 0 18px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        #main {
            margin: 0 0;
            // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none;
                        width: 100%;
                        padding: 0 0;
                        margin: 0 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        width: 100%;
                    }
                }
                .product-set-list {
                    .promotion {
                        padding: 0 0;
                    }
                    .product-set-details {
                        padding: 0 0;
                    }
                }
            }
            .standardproductset {
                .adjust-inner-content {
                    padding: 0;
                }
                .product-thumbnails {
                    &.hide-mobile {
                        display: block;
                        .thumb {
                            width: 100%;
                            position: relative;
                            a.thumbnail-link {
                                left: 0;
                            }
                        }
                        .slick-dots {
                            margin: 15px 20px 15px 0;
                            float: right;
                            li {
                                float: left;
                                margin-left: 10px;
                                button {
                                    text-indent: -99999px;
                                    border: 1px solid #cccccc;
                                    background: #cccccc;
                                    width: 10px;
                                    border-radius: 50%;
                                    padding: 0;
                                    height: 10px;
                                }
                                &.slick-active {
                                    button {
                                        border: 1px solid #000000;
                                        background: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-1 {
                    &.product-set {
                        .product-primary-image {
                            width: 100%;
                        }
                    }
                }
                .product-col-2 {
                    &.product-set {
                        padding: 0 10px;
                        box-sizing: border-box;
                        .product-add-to-cart {
                            .inventory {
                                width: 80px;
                            }
                            button {
                                width: 220px;
                                &.all-set-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .adjust-inner-content {
                padding: 0 10px;
            }
            .product-col-wrapper {
                margin-top: 0;
            }
            .product-col-1 {
                width: 100%;
                margin-right: 0;
            }
            .product-col-2 {
                width: 100%;
                margin-left: 0;
                padding: 0;
            }
            .product-name {
                margin: 0 0 8px 0;
            }
        }
        .pdp-main {
            .product-set-details {
                .heading {
                    .title {
                        font-family: $WorkSans;
                        font-weight: 900;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        #main {
            .adjust-inner-content {
                padding: 0 10px;
            }
            .tabs {
                margin: 30px 0 0 0;
                .tab-label {
                    padding: 0 15px;
                }
            }
            .promotion {
                letter-spacing: 0.5px;
            }
            .product-price {
                margin-bottom: 10px;
            }
        }
    }
    .product-set-long-description {
        .read-more-content {
            .read-more {
                color: $navy-blue;
            }
        }
    }
}
#main {
    .standardproductset {
        .product-col-2 {
            .top-add-all {
                &.product-add-to-cart {
                    .shop-the-look {
                        @media screen and (max-width: 1023px) {
                            float: left;
                        }
                    }
                    .product-set-main-button-parent {
                        &.hide-tablet-landscape {
                            @media screen and (max-width: 1023px) {
                                display: none;
                            }
                            @media screen and (max-width: 1023px) {
                                display: block;
                            }
                        }
                        &.show-tablet-landscape {
                            display: none;
                            @media screen and (max-width: 1023px) {
                                display: block;
                                float: right;
                                width: 50%;
                                text-align: right;
                            }
                            @media screen and (max-width: 1023px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            .product-set-addall {
                .product-add-to-cart {
                    .all-set-button {
                        @media screen and (max-width: 1023px) {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}
.ui-dialog {
    &.terms-and-conditions {
        min-height: 260px !important;
        .ui-dialog-content {
            font-size: $base-font + 2px;
            font-family: $Helvetica;
            .terms-scrollbar-height {
                padding: 0 20px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
        }
    }
}
.js {
    .zoomContainer {
        &.js-zoom-container {
            z-index: 1;
        }
    }
    .zoomWindow {
        z-index: 99999 !important;
    }
    &.pdp-page {
        .zoomWindow {
            z-index: 1 !important;
        }
    }
}
.enlarge-video-sec {
    display: inline-block;
    width: 100%;
    padding: 25px 0 0 0;
    &.mobile-enlarge-video-sec {
        width: auto;
        padding: 0;
        position: relative;
        top: -7px;
    }
    .enlarge-video {
        display: inline-block;
        font-size: $base-font + 2px;
        @include Univers($UniversCondensed);
        text-transform: uppercase;
        padding-left: 20px;
        line-height: 15px;
        &.enlarge {
            background: url("../../../images/ks/Enlarge_Icon_Grey_666666.svg")
                no-repeat;
            background-size: 21% 100%;
        }
        &.video-link {
            background: url("../../../images/ww/video-play-icon.png") no-repeat;
            float: right;
            visibility: hidden;
        }
        &.visible {
            visibility: visible;
        }
    }
}
.padding-eight {
    margin: 0 0 8px 0;
}
.quantity-ats-message {
    font-family: $HelveticaMedium;
    color: $radical-red;
    font-size: $base-font;
}
.max-cart-qty,
.max-cart-qty-set {
    font-family: $Lora;
    font-size: $base-font;
    margin: 0 0 10px 0;
    color: $red;
}
.max-cart-qty-set {
    margin-top: 10px;
}
.color-not-selected {
    font-family: $HelveticaMedium;
    color: $radical-red;
    font-size: $base-font;
    &.hide-error {
        display: none;
    }
    &.show-error {
        display: block;
    }
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.full-width {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
@media screen and (max-width: 1023px) {
    .md-full-left {
        float: left;
    }
    .md-full-right {
        float: right;
    }
    .md-full-width {
        width: 100%;
    }
    .md-half-width {
        width: 46%;
    }
}
.enlarge-dialog {
    &.ui-dialog {
        max-width: 100%;
        .ui-icon-closethick {
            background-image: url(../../../images/dialog-close-icon.png);
            height: 23px;
            width: 23px;
            z-index: 9999;
        }
        .ui-dialog-titlebar-close {
            right: 25px;
            top: 22px;
            z-index: 99999;
        }
    }
    @media screen and (max-width: 1023px) {
        width: 100% !important;
        max-width: 100% !important;
    }
    .ui-widget-header {
        height: 0;
    }
    img.productthumbnail-zoom-image {
        display: none;
    }
    .image-zoom-out {
        position: absolute;
        top: 100px;
        right: 100px;
        font-size: 42px;
        display: none;
    }
    .image-zoom-in {
        position: absolute;
        top: 110px;
        right: 110px;
        font-size: 42px;
        display: none;
    }
    .enlarge-hero-image {
        width: 100%;
        overflow: hidden;
        @media screen and (min-width: 1024px) {
            width: 57.2%;
            display: inline-block;
            max-height: 700px;
            .image-zoom-out {
                display: inline-block;
            }
            .pinch-zoom-container {
                a {
                    left: 0;
                }
            }
            .enlarge-hero-container {
                width: 100%;
                margin: 0 auto;
                max-height: 700px;
            }
            &.zoom-active {
                .thumb.slick-slide {
                    img.productthumbnail {
                        opacity: 0;
                    }
                }
            }
            .thumb.slick-slide {
                height: 100%;
                text-align: center;
                img {
                    max-height: 100%;
                    display: inline-block;
                }
                img.productthumbnail-zoom-image {
                    display: none;
                    position: absolute;
                    top: -183px;
                    left: 0;
                }
            }
        }
    }
    .enlarge-right-sec {
        padding: 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: $white;
        .pdpForm {
            float: left;
            width: 100%;
        }
        .product-options .color li a,
        .product-variations .color li a {
            width: 42px;
            min-width: 42px;
            height: 42px;
        }
        @media screen and (min-width: 1024px) {
            width: 35.56%;
            padding: 0;
            margin-right: 50px;
            margin-top: 65px;
            margin-bottom: 20px;
        }
        .slick-track {
            width: 100% !important;
            transform: initial !important;
            -ms-transform: translate(0cm) rotate(0deg) !important; /* Internet Explorer */
            -ms-transform-origin: top right !important;
        }
        .apply-scroll-bar {
            display: inline-block;
            width: 100%;
            max-height: 615px;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                max-height: 200px;
            }
            .product-variation-content {
                .product-variations {
                    .swatches {
                        &.color {
                            float: left;
                            width: 100%;
                            @media screen and (min-width: 768px) and (max-width: 1023px) {
                                max-width: 310px;
                            }
                        }
                    }
                }
            }
        }
        .slimScrollRail,
        .slimScrollBar {
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)" !important; // IE8
            filter: alpha(opacity=1) !important; // IE 5-7
            opacity: 1 !important;
        }
        .md-full-right {
            &.md-half-width {
                @media screen and (min-width: 768px) and (max-width: 1023px) {
                    width: 50%;
                }
            }
        }
    }
    .pdp-main {
        .product-name {
            margin: 0;
            @media screen and (max-width: 1023px) {
                font-size: $base-font + 6px;
            }
        }
        .enlarge-thumb-image {
            height: auto;
            width: 100%;
            padding: 10px 0 0 0;
            @media screen and (min-width: 1024px) {
                padding: 10px 0 0 0;
            }
        }
        .product-variations {
            .attribute {
                border: none !important;
            }
        }
    }
    .enlarge-thumb-image {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 69px !important;
            @media screen and (min-width: 1024px) {
                width: 73px !important;
            }
            a {
                img {
                    margin-bottom: 10px;
                }
            }
        }
        // thumbnail image
        img {
            cursor: pointer;
            max-width: 100%;
            height: auto;
        }
        h2 {
            margin: 1rem 0 0.3rem;
        }
    }
    button {
        &.slick-arrow {
            background: url("../../../images/enlarge-slider-lt-rt-icon.png")
                no-repeat;
            width: 15px;
            height: 25px;
            padding: 0;
            border: none;
            text-indent: -9999px;
            position: absolute;
            -webkit-transition: initial;
            -ms-transition: initial;
            transition: initial;
            z-index: 9999;
            top: 0;
            bottom: 0;
            margin: auto;
            &.slick-prev {
                background-position: 0 -68px;
            }
            &.slick-next {
                background-position: 0 0;
            }
        }
    }
}
#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}
.pdp-main {
    @extend %clearfix;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-review {
        display: none;
    }
    .product-col-1 {
        float: left;
        width: 31.7%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        &.product-bundle {
            padding: 0 12% 0 0;
            .product-primary-image {
                text-align: left;
            }
        }
    }
    .product-bundle {
        float: left;
        padding: 0;
        .product-col-2 {
            &.product-set {
                width: 100%;
                padding: 0;
            }
            .product-thumbnails {
                display: none;
            }
            .product-name {
                margin: 0 0 37px 0;
            }
            .promotion {
                margin: 0 0 31px 0;
            }
            .product-shortdescription {
                display: none;
            }
            .product-longdescription {
                display: none;
            }
            &.product-detail {
                .product-set-item {
                    padding: 20px 0 0 0;
                    border-width: 6px;
                    &:last-child {
                        border-width: 1px;
                    }
                    .product-add-to-cart {
                        display: none;
                    }
                }
                .product-set-image {
                    width: 14.5%;
                    .view-details {
                        display: none;
                    }
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    width: 100%;
                    padding: 0;
                    .product-name {
                        display: none;
                    }
                    .product-price {
                        display: none;
                    }
                    .attribute {
                        display: none;
                    }
                    .promotion {
                        display: none;
                    }
                    .product-variations {
                        .attribute {
                            display: block;
                            margin: 0;
                            padding: 0 0 19px 0;
                            border-top: none;
                            &:nth-child(2n),
                            &:nth-child(3n) {
                                border-top: 1px solid $very-light-gray;
                                padding: 0 0 10px 0;
                            }
                        }
                        .size_label {
                            text-transform: uppercase;
                        }
                    }
                }
            }
            .product-add-to-cart {
                margin: 0 0 29px 0;
                width: 100%;
                padding: 0;
                display: inline-block;
                float: left;
                border-bottom: none;
                .availability-web {
                    border-top: none;
                    .availability-novariation {
                        font-size: $base-font - 1px;
                        @include WorkSansMedium($WorkSans);
                        font-style: normal;
                        color: $black;
                    }
                }
                button {
                    background-color: $navy-blue;
                    color: $white;
                    border-color: $navy-blue;
                    margin: 0;
                    float: left;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    height: 50px;
                }
                .product-set-main-button-parent {
                    margin: 0;
                }
            }
            .product-actions {
                padding: 0;
                border-bottom: 1px solid $very-light-gray;
                .wishlist {
                    padding: 60px 0 0 0;
                }
                .gift-registry {
                    display: none;
                }
            }
        }
        .share-icon-container {
            display: inline-block;
            margin: 22px 0 0 0;
        }
    }
    .product-teasers {
        display: inline-block;
        width: 100%;
        margin-bottom: 14px;
        .rating-text {
            font-size: $base-font - 1px;
            text-transform: uppercase;
            line-height: 21px;
            letter-spacing: 1px;
            margin: -3px 0 0 3px;
            cursor: pointer;
            vertical-align: top;
            @media screen and (max-width: 767px) {
                letter-spacing: 0.5px;
            }
        }
        .TTratingBox {
            display: inline-block;
            width: 95px;
            margin-left: 3px;
        }
    }
    .turntofit {
        font-size: $base-font - 1px;
        @include Univers($UniversCondensed);
        text-transform: uppercase;
        display: inline-block;
        width: 100%;
        margin: 10px 0 12px;
        color: $navy-blue;
        .turntofitcounts {
            @include Univers($UniversBoldCondensed);
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .product-col-2 {
        float: left;
        width: 54%;
        padding: 0 9.5%;
        box-sizing: border-box;
        #zoom-image-container {
            display: inline-block;
            width: 100%;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
        }
        .product-review {
            display: block;
        }
        &.giftcard-detail {
            .inventory {
                display: block;
                margin-top: 10px;
            }
        }
        &.e-giftcard-detail {
            .inventory {
                display: none;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-name {
                line-height: 29px;
            }
            .custom-select {
                select {
                    &.error ~ .selected-option {
                        color: $red;
                    }
                }
            }
            .please-select {
                &.error {
                    color: $red;
                }
            }
            .product-special-messages {
                padding: 10px 0;
            }
            .tab-content {
                padding: 0;
                p {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0 0 15px 15px;
                }
            }
            .terms-conditions {
                margin: 0 0 29px 0;
            }
            .product-variations {
                &.gift-card {
                    padding: 0;
                }
                .attribute {
                    &:first-child {
                        padding: 0 0 29px 0;
                        margin: 26px 0 0 0;
                        border-bottom: 1px solid $alto;
                        border-top: 0;
                    }
                    &:last-child {
                        margin: 25px 0 0 0;
                        padding: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding-top: 4px;
                border: 0;
            }
            .personal-message {
                margin: 16px 0 0 0;
                .label-inline {
                    .field-wrapper {
                        input[type="checkbox"] {
                            margin: 0 3px 0 2px;
                            width: 16px;
                            height: 16px;
                            border: 1px solid $white;
                            outline: 1px solid $black;
                        }
                    }
                }
            }
            .fields-address {
                .form-row {
                    margin: 14px 0 6px 0;
                    width: 100%;
                    input[type="text"],
                    textarea {
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        &.error {
                            &::-webkit-input-placeholder {
                                color: $red;
                            }
                            &::-moz-placeholder {
                                color: $red;
                            }
                            &:-ms-input-placeholder {
                                color: $red;
                            }
                            &:-moz-placeholder {
                                color: $red;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                button {
                    margin-top: 15px;
                }
            }
            .product-actions {
                clear: both;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-variations {
                .swatches {
                    li {
                        a {
                            width: 60px;
                            height: 85px;
                            border-radius: 0;
                            -webkit-border-radius: 0;
                            -mox-border-radius: 0;
                            img {
                                border-radius: 0;
                                -webkit-border-radius: 0;
                                -mox-border-radius: 0;
                            }
                        }
                        &.selected {
                            a {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }
            .gift-card {
                padding: 10px 0 0 0;
            }
            .product-add-to-cart {
                button {
                    margin-top: 10px;
                }
                .wishlist {
                    padding: 25px 0 0 0;
                }
            }
            .personal-message {
                margin: 20px 0 0 0;
            }
            .e-gift-email {
                .form-row {
                    .option-text {
                        display: none;
                    }
                    &.error {
                        label {
                            span {
                                color: $red;
                            }
                        }
                    }
                }
                .field-wrapper {
                    span {
                        &.error {
                            float: left;
                            width: 100%;
                            padding: 0;
                            display: block !important;
                            color: $red;
                        }
                    }
                }
                input[type="text"] {
                    width: 290px;
                    height: 50px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                    &.error {
                        &::-webkit-input-placeholder {
                            color: $red;
                        }
                        &::-moz-placeholder {
                            color: $red;
                        }
                        &:-ms-input-placeholder {
                            color: $red;
                        }
                        &:-moz-placeholder {
                            color: $red;
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .product-add-to-cart {
                .fields-address {
                    .form-row {
                        .option-text {
                            display: none;
                        }
                        &.error {
                            label {
                                span {
                                    color: $red;
                                }
                            }
                        }
                    }
                    .form-row {
                        input[type="text"] {
                            @media screen and (max-width: 767px) {
                                width: 100%;
                            }
                        }
                    }
                    .field-wrapper {
                        span {
                            &.error {
                                float: left;
                                width: 100%;
                                padding: 10px 0;
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .gift-card {
                padding: 20px 0 0 0;
                border-top: 1px solid $KS-dark-grey;
                .variant-dropdown {
                    border: none;
                    position: relative;
                    width: 166px;
                    display: inline-block;
                    @media screen and (max-width: 1023px) {
                        width: 100%;
                    }
                    select {
                        width: 180px;
                        outline: none;
                        -moz-appearance: none;
                        appearance: none;
                        -webkit-appearance: none;
                        padding: 0 10px;
                        font-size: $base-font - 1px;
                        font-family: $Lora;
                        letter-spacing: 1px;
                        height: 50px;
                    }
                    .custom-select {
                        .selected-option {
                            text-transform: capitalize;
                            border-radius: 0;
                        }
                        &.current_item {
                            .selected-option {
                                border-radius: 0;
                            }
                        }
                        select {
                            border-radius: 0;
                        }
                    }
                }
            }
        }
        &.e-giftcard-detail {
            .product-variations {
                .attribute:first-child {
                    border: 0;
                }
            }
            .product-add-to-cart {
                button {
                    border-radius: 50px;
                }
            }
        }
        .product-special-messages {
            padding: 5px 0;
            font-size: $base-font + 2px;
            color: $special-product-messages-color;
            font-family: $HelveticaBold;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 2px;
            }
        }
        .product-feature-messages {
            padding: 5px 0;
            font-family: $Helvetica;
            color: $night-rider;
            font-size: $base-font + 2px;
            text-transform: uppercase;
        }
        .tab-content {
            font-size: $base-font;
            font-family: $Helvetica;
            padding: 20px 0;
            ul {
                list-style-type: disc;
                padding: 0 0 30px 15px;
                li {
                    list-style-type: disc;
                    line-height: 18px;
                }
            }
        }
        .terms-conditions {
            width: 100%;
            display: inline-block;
            margin: 0 0 25px 0;
            font-size: $base-font;
            font-family: $Helvetica;
            a {
                width: auto;
                text-decoration: underline;
                font-family: $Lora;
            }
            #dialog {
                display: none;
            }
        }
        .product-add-to-cart {
            > label {
                display: none;
            }
            .shop-the-look {
                display: none;
            }
            .product-price {
                display: none;
            }
            .all-set-button {
                float: right;
                padding: 0 36px;
                margin: 40px 0 20px;
            }
            .product-set-main-button-parent {
                margin: 40px 0 20px;
            }
        }
        .top-add-all {
            &.product-add-to-cart {
                width: 100%;
                border-top: 1px solid $light-gray;
                border-bottom: 1px solid $light-gray;
                padding: 12px 0;
                margin-top: 30px;
                .all-set-button,
                .product-set-main-button-parent {
                    margin: 0;
                }
                .shop-the-look {
                    line-height: 50px;
                    font-size: 24px;
                    font-family: $Lora;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
        .product-set-main-button-error,
        .variation-selection-error-msg,
        .product-set-product-button-error {
            color: $red;
            font-size: 12px;
            @include WorkSansSemiBold($WorkSans);
        }
        .product-set-main-button-parent {
            display: inline-block;
            vertical-align: middle;
            line-height: 50px;
            padding-left: 20px;
            .product-set-main-button-error {
                padding: 0;
            }
        }
        .price-standard {
            border-right: 1px solid $gainsboro;
            padding-right: 0.5rem;
        }
    }
    .product-name {
        color: $navy-blue;
        @include Univers($UniversBoldCondensed);
        font-size: 30px;
        line-height: 39px;
        margin: 11px 0 26px 0;
        width: 100%;
        text-transform: uppercase;
    }
    .product-number {
        color: $very-light-gray;
        font-size: 0.65rem;
        margin-bottom: 0.5rem;
    }
    .expired-price {
        font-family: $WorkSans;
    }
    .product-price {
        font-size: $base-font + 9px;
        margin-bottom: 21px;
        @include Univers($UniversBoldCondensed);
        @media screen and (max-width: 767px) {
            font-size: $base-font + 8px;
        }
        .price-sales {
            color: $navy-blue;
        }
        .price-standard {
            border-right: none;
            padding-right: 29px;
            color: $red;
            text-decoration: line-through;
            > span {
                color: $navy-blue;
            }
        }
        .price-standard-exist {
            color: $red;
        }
        .price-tiered {
            color: $red;
            text-transform: uppercase;
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: 15px;
    }
    .product-main-attributes {
        .attribute {
            padding: 0.2em 0;
        }
        .label {
            padding: 0;
        }
    }
    .product-primary-image {
        position: relative;
        &.not-a-device {
            max-width: 100%;
            text-align: center;
            display: inline-block;
            .main-image {
                display: block;
                overflow: visible;
                position: relative;
            }
        }
    }
    .product-info {
        clear: both;
        padding-top: 1em;
        ul {
            font-size: 0.8rem;
            list-style: square;
            padding: 0 5em;
            @media screen and (min-width: 768px) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
        .product-details-container {
            color: $navy-blue;
            @media screen and (min-width: 768px) {
                display: flex;
                flex-direction: row-reverse;
                .product-details-description {
                    flex: 1;
                }
                .product-details-video {
                    min-width: 320px;
                    padding-left: 1em;
                }
            }
        }
    }
    .productmessage {
        span {
            color: $KS-light-blue;
            @include Univers($UniversRoman);
            font-size: $base-font;
            margin-bottom: 10px;
            display: inline-block;
            width: 74%;
        }
    }
    .product-content-quickorder {
        .product-add-to-cart {
            overflow: inherit;
        }
    }
    // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        display: block;
        margin: 0;
        overflow: visible;
        border-top: 1px solid $light-gray;
        padding-top: 15px;
        .wishlist {
            width: auto;
            display: inline-block;
            @include Univers($UniversCondensed);
            font-size: $base-font + 4px;
            text-transform: none;
            color: $navy-blue;
            padding: 15px 0 0 0;
            @media screen and (min-width: 768px) {
                margin-left: 24px;
            }
            a {
                .wishlist-logo {
                    display: inline-block;
                    line-height: normal;
                    float: left;
                    background: url("../../../images/wishlist.svg") no-repeat;
                    width: auto;
                    height: 18px;
                    padding-left: 30px;
                    &.added-to-wishlist {
                        background: url("../../../images/wishlist-solid.svg")
                            no-repeat;
                    }
                }
            }
        }
        .inventory {
            width: 80px;
            float: left;
            white-space: nowrap;
            height: 50px;
            position: relative;
            label {
                float: none;
                text-align: right;
            }
            input {
                font-size: 1.5em;
                height: 50px;
                width: 50px;
                text-align: center;
                @media screen and (min-width: 768px) {
                    height: 37px;
                    width: 37px;
                }
            }
        }
        .shippingdetails {
            float: left;
            width: 100%;
            margin: 20px 0 0;
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            box-sizing: border-box;
            .heading {
                font-size: $base-font;
                margin: 0;
                text-align: left;
                @include Univers($UniversBold);
                color: $navy-blue;
                background: url(../../../images/pdp-arrow-closed.png) no-repeat
                    right;
                &.expanded {
                    background: url(../../../images/pdp-arrow-open.png)
                        no-repeat right;
                }
            }
            .shippingdrawer {
                .productinfodate {
                    p {
                        margin: 5px 0;
                        color: $navy-blue;
                    }
                }
                .productinfo {
                    .moreinfo {
                        color: $navy-blue;
                    }
                }
            }
        }
        button.add-to-cart {
            border: none;
            height: 50px;
            width: 72.5%;
            padding: 0;
            color: $white;
            background: $navy-blue;
            text-transform: uppercase;
            border-radius: 0;
            border-top-right-radius: $KS-Btn-radius;
            border-bottom-right-radius: $KS-Btn-radius;
            box-sizing: content-box;
            display: inline-block;
            &:hover {
                background: $KS-sky-blue;
            }
            &.all-set-button {
                width: auto;
                border-radius: 3px;
            }
        }
        .dw-apple-pay-button {
            margin-left: 0.5rem;
            @media screen and (min-width: 768px) {
                width: 172px; // make sure apple pay button is as big as Add To Cart
            }
        }
        .availability-web {
            label {
                display: none;
            }
            .availability-msg {
                p {
                    @include WorkSansMedium($WorkSans);
                    color: $black;
                    font-size: 14px;
                }
            }
        }
    }
    .savingmessage {
        color: $red;
        font-size: $base-font + 4;
        @include Univers($UniversBoldCondensed);
        line-height: 22px;
        text-transform: uppercase;
        width: 100%;
        letter-spacing: 1px;
        @media screen and (max-width: 767px) {
            font-size: $base-font + 3px;
        }
    }
    .promotion {
        letter-spacing: 1.5px;
        @include Univers($UniversBoldCondensed);
        font-size: $base-font + 4px;
        .promotion-title {
            display: none;
        }
        .promotion-callout {
            color: $red;
            font-size: $base-font + 4px;
            @include Univers($UniversCondensed);
            line-height: 20px;
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 3px;
            }
            i {
                display: none;
            }
            .promo-tooltip {
                font-size: $base-font + 1px;
                margin-left: 0.2rem;
                text-transform: uppercase;
                color: $dusty-gray;
                text-decoration: underline;
                @include Univers($UniversRoman);
                letter-spacing: 0;
                @media screen and (max-width: 767px) {
                    font-size: $base-font;
                }
            }
        }
    }
    .product-actions {
        @extend %clearfix;
        padding: 22px 0;
        .socialsharing {
            display: block;
            width: 100%;
        }
        .share_label {
            font-family: $Helvetica;
            color: $black;
            font-size: $base-font;
            vertical-align: middle;
        }
        a {
            border: 0;
            color: $black;
            font-family: $HelveticaBold;
            padding: 0;
            height: 52px;
            line-height: 50px;
            &.share-icon {
                display: inline-block;
                line-height: 0;
                height: auto;
                vertical-align: middle;
                padding: 0 13px;
                font-size: $base-font + 9;
                &.share-twitter {
                    padding: 0 16px;
                }
                &.share-mail {
                    padding: 0 5px;
                }
            }
        }
    }
    .product-shortdescription {
        color: $black;
        font-size: $base-font;
        @include WorkSansBold($WorkSans);
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .product-longdescription {
        color: $black;
        font-size: $base-font + 2;
        font-family: $Lora;
        margin-bottom: 20px;
        line-height: 25px;
        text-transform: none;
    }
    .heading {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        font-size: $base-font + 1px;
    }
    .share-icon-container {
        .share-icon {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            img {
                width: 26px;
            }
            &.share-facebook {
                img {
                    width: 18px;
                }
            }
            &.share-mail {
                img {
                    width: 22px;
                }
            }
        }
        .share-icon-label {
            font-size: $base-font + 4px;
            @include Univers($UniversLightCondensed);
            color: $navy-blue;
            display: inline-block;
        }
    }
    .product-thumbnails,
    .product-thumbnails ul,
    .enlarge-thumb-image {
        @media screen and (min-width: 768px) {
            width: 95%;
            padding-top: 16px;
            overflow: hidden;
            height: 120px;
            &.remove-ht-ovr-hidden {
                overflow: visible;
                height: auto;
            }
            &.slick-arrow-bar {
                &::before {
                    content: "";
                    background: url("../../../images/ww/alt-lt-rt-icons.png")
                        no-repeat;
                    background-position: -5px -44px;
                    width: 19px;
                    height: 1px;
                    display: inline-block;
                    position: absolute;
                    top: 64px;
                    right: -28px;
                }
            }
            button {
                &.slick-arrow {
                    background: url("../../../images/ww/alt-lt-rt-icons.png")
                        no-repeat;
                    width: 6px;
                    height: 11px;
                    padding: 0;
                    border: none;
                    text-indent: -9999px;
                    position: absolute;
                    top: 0;
                    right: -19px;
                    -webkit-transition: initial;
                    -ms-transition: initial;
                    transition: initial;
                    &.slick-prev {
                        background-position: 0 -19px;
                        top: 81px;
                        transform: translateX(15px);
                        left: 100%;
                        &.slick-disabled {
                            background-position: -21px -19px;
                        }
                    }
                    &.slick-next {
                        background-position: 0 0;
                        top: 38px;
                        transform: translateX(15px);
                        left: 100%;
                        &.slick-disabled {
                            background-position: -22px 0;
                        }
                    }
                }
            }
        }
    }
    .main-product-set-details,
    .product-set {
        #thumbnails {
            padding-bottom: 30px;
            .thumb {
                padding-top: 0;
                width: 25%;
                padding-right: 15px;
                box-sizing: border-box;
                line-height: 0;
                float: left;
                @media screen and (max-width: 767px) {
                    padding-right: 0;
                }
                a {
                    @media screen and (max-width: 767px) {
                        width: 63.5px;
                        float: left;
                        height: 93.5px;
                        left: 0;
                    }
                }
            }
        }
        .product-price {
            font-size: $base-font + 9px;
            .price-standard,
            .price-standard-exist {
                font-size: $base-font + 6px;
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 2px;
                }
            }
        }
    }
    .product-variations {
        width: 100%;
        display: inline-block;
        margin-top: 3px;
        //To display 3 size attributes in mobile.
        @media screen and (max-width: 767px) {
            ul {
                li {
                    ul.size {
                        float: left;
                    }
                }
            }
        }
        .attribute {
            border-top: 0;
            padding: 5px 0;
            border-bottom: 1px solid $light-gray;
            padding-bottom: 10px;
            &.sizeFamily {
                border-bottom: 2px solid $navy-blue;
                padding-bottom: 0;
                margin-bottom: 0;
                vertical-align: bottom;
                li a {
                    margin-bottom: 0;
                    border-radius: 0;
                    font-size: $base-font + 6px;
                    text-transform: uppercase;
                    @include Univers($UniversCondensed);
                }
            }
            .attribute_label {
                margin: 11px 0 2px;
                .label,
                .selected-value,
                .size-chart-link {
                    display: inline-block;
                    color: $navy-blue;
                    @include Univers($UniversCondensed);
                    text-transform: uppercase;
                    font-size: $base-font;
                    margin: 0;
                    margin-right: 3px;
                }
                .selected-value,
                .final-sale-color-msg {
                    @include Univers($UniversBoldCondensed);
                    text-transform: uppercase;
                    font-size: $base-font;
                    letter-spacing: 0.64px;
                    @media screen and (min-width: 320px) and (max-width: 767px) {
                        letter-spacing: 0.55px;
                    }
                }
                .size-chart-link {
                    font-size: 11px;
                    float: right;
                    span.size_icon,
                    span.size_label {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span.size_icon {
                        width: 23px;
                        padding-top: 5px;
                        img {
                            width: 100%;
                        }
                    }
                    span.size_label {
                        padding-left: 3px;
                        text-transform: uppercase;
                    }
                }
            }
            .value {
                margin-top: 11px;
                &.variants-container {
                    vertical-align: bottom;
                    margin-bottom: -1px;
                    .swatches li {
                        vertical-align: bottom;
                    }
                }
            }
            &:first-child {
                border-top: 1px solid $light-gray;
                margin-top: 10px;
            }
            &.size_border {
                margin-bottom: 4px;
                .attribute_label {
                    margin: 11px 0;
                }
                .swatches {
                    li {
                        a {
                            margin: 5px 10px 0 0;
                        }
                    }
                }
            }
        }
    }
    // Product Set Display
    .product-set {
        .product-set-list {
            border-top: 1px solid transparent;
            .product-set-details {
                padding-left: 4%;
                margin: 0;
                width: 78%;
                float: right;
                box-sizing: border-box;
                .product-price {
                    margin-bottom: 3px;
                    display: inline-block;
                    float: left;
                }
                .product-hemmable {
                    border-bottom: none;
                }
            }
            .product-image-container {
                float: left;
                box-sizing: border-box;
                background: transparent;
                .thumb {
                    border-bottom: 5px solid transparent;
                    float: left;
                    margin-right: 10px;
                    padding-top: 20px;
                    width: 25%;
                    &.selected {
                        a {
                            img {
                                border-bottom: 5px solid $wine-berry;
                                box-sizing: border-box;
                                cursor: pointer;
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
            .product-set-item {
                clear: both;
                overflow: inherit;
                display: inline-block;
                padding-top: 35px;
                padding-bottom: 40px;
                width: 100%;
                .product-variations {
                    width: 100%;
                    float: left;
                }
            }
            .product-set-image {
                width: 21.6%;
                display: inline-block;
                img {
                    width: 100%;
                }
            }
            .product-number {
                display: none;
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border: none;
            padding: 0 0 0 4%;
            width: 78.4%;
            float: right;
            box-sizing: border-box;
            label {
                float: left;
                font-size: 0.75rem;
                padding-top: 0.3em;
                padding-right: 0.5em;
            }
            .availability-web {
                padding: 15px 0;
                font-size: 11px;
                @include WorkSansMedium($WorkSans);
                label {
                    float: none;
                }
                .color_gray {
                    font-weight: normal;
                    color: $dim-gray;
                }
            }
            .inventory {
                width: 75px;
                float: left;
                white-space: nowrap;
                height: 50px;
                position: relative;
            }
            .sub-product-item {
                background: $navy-blue;
                color: $white;
                cursor: pointer;
                display: inline-block;
                font-size: $base-font + 2px;
                padding: 0 52px;
                text-align: center;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                text-transform: uppercase;
                outline: 0;
                letter-spacing: 1px;
                width: auto;
                height: 50px;
                float: left;
                border-top-left-radius: 0;
                -webkit-border-top-left-radius: 0;
                -moz-border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                -webkit-border-bottom-left-radius: 0;
                -moz-border-bottom-left-radius: 0;
                box-sizing: border-box;
            }
            > a {
                font-family: $HelveticaBold;
                color: $black;
                font-size: 11px;
                line-height: 30px;
            }
        }
        .view-details {
            @include WorkSansBold($WorkSans);
            font-size: $base-font - 1px;
            color: $black;
            text-align: center;
            text-transform: uppercase;
            padding: 8px 0;
        }
        .product-id {
            @include WorkSansMedium($WorkSans);
            color: $silver;
            font-size: $base-font;
            text-align: center;
        }
    }
    // Product Recommendations
    .recommendation {
        width: 14.29%;
        float: right;
        .recommendations {
            clear: left;
            padding: 0 0;
            border: 1px solid $light-gray;
        }
        .recommendations-heading {
            font-size: $base-font + 4px;
            text-align: center;
            padding: 17px 0 13px;
            margin: 0 0;
        }
        button {
            &.slick-arrow {
                width: 17px;
                height: 23px;
                padding: 0;
                border: none;
                text-indent: -9999px;
                position: relative;
                top: 31%;
                bottom: 0;
                margin: 0 auto;
                z-index: 9;
                -webkit-transition: initial;
                transition: initial;
                -ms-transition: initial;
                &.slick-prev {
                    background: url("../../../images/recently-viewed-arrows_left.svg")
                        no-repeat;
                    &.slick-disabled {
                        background: url("../../../images/recently-viewed-arrows-left-unactivated.svg")
                            no-repeat;
                    }
                }
                &.slick-next {
                    background: url("../../../images/recently-viewed-arrows_right.svg")
                        no-repeat;
                    &.slick-disabled {
                        background: url("../../../images/recently-viewed-arrows-right-unactivated.svg")
                            no-repeat;
                    }
                }
            }
        }
        ul {
            list-style-type: none;
            position: relative;
            width: 100%;
        }
        .grid-tile {
            float: none;
            width: 100%;
            border: 0;
        }
        .recommendation-tooltip {
            background: none repeat scroll 0 0 $white;
            border: 1px solid;
            left: 50%;
            position: absolute;
            text-align: left;
            top: 50%;
            width: 300px;
            z-index: 100;
        }
        .product-tile {
            padding: 0 0;
            margin: 0 0;
            color: $black;
            width: 100%;
            border: 0;
            .product-image {
                max-width: 80px;
                margin: auto;
                img {
                    width: auto;
                    height: auto;
                }
            }
            .product-name {
                font-size: $base-font;
                font-family: $WorkSans;
                letter-spacing: 0;
                margin: 3px 0 6px;
                width: 100%;
                line-height: normal;
            }
            .product-price {
                font-size: $base-font;
                margin: 5px 0;
                color: $night-rider;
                @include Univers($UniversBoldCondensed);
                .price-standard {
                    padding-right: 6px;
                }
            }
        }
        &.for-desktop {
            .product-tile {
                padding-bottom: 9px;
            }
        }
        &.for-device {
            display: none;
        }
        @media screen and (min-width: 1025px) {
            .slick-slider {
                top: 0 !important;
            }
            .recommendations {
                clear: both;
                display: inline-block;
            }
            .product-tile {
                .product-name {
                    overflow: hidden;
                    height: auto;
                    max-height: 30px;
                    padding: 0 5px;
                    box-sizing: border-box;
                }
            }
        }
        @media screen and (max-width: 1023px) {
            width: 100%;
            float: none;
            &.for-desktop {
                display: none;
            }
            &.for-device {
                display: block;
            }
            .recommendations {
                padding-bottom: 27px;
                margin-top: 35px;
            }
            .recommendation-tiles {
                max-width: 796px;
                margin: auto;
            }
            .recommendations-heading {
                padding: 23px 0 22px;
                font-size: $base-font + 18px;
            }
            .grid-tile {
                width: 149px;
                float: left;
                padding: 0 25px;
            }
            .product-tile {
                .product-image {
                    max-width: 116px;
                }
                .product-name {
                    font-size: $base-font + 3px !important;
                    line-height: normal !important;
                    word-break: normal !important;
                    margin: 7px 0 0;
                }
            }
        }
        @media screen and (max-width: 1023px) {
            .recommendations {
                padding-bottom: 17px;
                margin-top: 22px;
            }
            .grid-tile {
                padding: 0 21px;
            }
        }
        @media screen and (max-width: 1023px) {
            .recommendations {
                margin: 0 10px;
            }
        }
        @media screen and (max-width: 767px) {
            .product-tile {
                .product-image {
                    max-width: initial;
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    //ends recommendation
    .tabs {
        width: 100%;
        display: inline-block;
        margin: 20px 0 0 0;
        .tab-label {
            font-size: $base-font + 4px;
            text-transform: uppercase;
            @include Univers($UniversBoldCondensed);
            text-align: center;
            margin: 0;
            border: 0;
            padding: 10px 0;
            letter-spacing: 1px;
            &.current {
                color: $navy-blue;
                margin: 0;
                border: 0;
                background-color: $wild-sand;
                &:hover {
                    border-bottom: none;
                }
            }
        }
        .tab-content {
            background-color: #f5f5f5;
            display: none;
            padding: 41px 0;
            &.current {
                display: block;
            }
            .tab-content-details {
                font-size: $base-font + 2px;
                @include Univers($UniversBold);
                line-height: 22px;
                margin: 0 auto;
                text-align: left;
                display: inline-block;
                max-width: 966px;
                width: 100%;
                .tab-left {
                    width: 57%;
                    display: inline-block;
                    float: left;
                }
                .tab-right {
                    width: 39%;
                    padding: 0 0 0 37px;
                    display: inline-block;
                    box-sizing: border-box;
                }
                p {
                    @include Univers($UniversRoman);
                    font-weight: normal;
                    font-size: 14px;
                    strong {
                        @include Univers($UniversBold);
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 12px;
                    }
                }
                ul {
                    padding: 0;
                    li {
                        @include Univers($UniversRoman);
                        font-weight: normal;
                        margin: 0 0 5px 0;
                        font-size: 14px;
                        text-indent: 5px;
                        &:before {
                            content: "-";
                            text-indent: -5px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 12px;
                        }
                        &:last-child:not(.tt-o-page-list__number) {
                            margin: 0 0 19px 0;
                        }
                    }
                }
                .tab-detail-header {
                    text-transform: capitalize;
                }
                .tab-fit,
                .tab-care {
                    padding: 0 0 15px 0;
                }
            }
        }
    }
    .product-primary-image {
        .product-video-container {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            display: none;
            width: 100%;
            height: 100%;
        }
    }
}
.main-image {
    .zoom_container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 120%;
        z-index: 999;
        display: none;
        #zoom-window {
            overflow: visible !important;
        }
    }
    &:hover {
        .zoom_container {
            display: block;
        }
    }
}
.primary-image {
    max-width: 100%;
    width: auto;
}
.product-thumbnails {
    @media screen and (max-width: 767px) {
        margin-bottom: 21px;
    }
    ul {
        @extend %clearfix;
    }
    @media screen and (min-width: 768px) {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 73px;
            float: left;
            position: relative;
            a {
                margin-bottom: 10px;
                display: inline-block;
                width: 100%;
            }
        }
    }
    // thumbnail image
    img {
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }
    h2 {
        margin: 1rem 0 0.3rem;
    }
}
.rating {
    color: $black;
    font-size: 16px;
    .product-info & {
        font-size: 2em;
    }
    i {
        padding-right: 3px;
    }
}
.product-variations,
.product-options {
    .attribute {
        overflow: visible;
        padding: 0;
        position: relative;
        border-top: 1px solid $KS-dark-grey;
        h3,
        .label {
            font-size: $base-font;
            margin: 16px 0;
            text-transform: uppercase;
            display: inline-block;
            font-family: $HelveticaBold;
        }
        .value {
            width: 100%;
            display: inline-block;
        }
        ul {
            width: 100%;
            &.swatches.color {
                flex-wrap: wrap;
                justify-content: start;
                align-items: flex-start;
                display: flex;
                li {
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .selected-value {
            border: 0 none;
            color: $navy-blue;
            text-transform: none;
            display: inline-block;
            font-family: $Helvetica;
        }
        .size-chart-link {
            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        display: inline-block;
        margin: 0;
        padding: 0;
        &.selected {
            a {
                border-color: $navy-blue;
                background: $navy-blue;
                color: $white;
            }
        }
        @media screen and (min-width: 1026px) {
            &:hover {
                a {
                    border-color: $navy-blue;
                    background: $navy-blue;
                    color: $white;
                }
            }
        }
        &.unselectable {
            a {
                border-color: $KS-dark-grey;
                opacity: 0.8;
                position: relative;
                color: $navy-blue;
                &::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    background: $alto;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%) rotate(-18deg);
                    -moz-transform: translate(-50%, -50%) rotate(-18deg);
                    -ms-transform: translate(-50%, -50%) rotate(-18deg);
                    -o-transform: translate(-50%, -50%) rotate(-18deg);
                    transform: translate(-50%, -50%) rotate(-18deg);
                    width: 92px;
                }
                &::before {
                    display: none;
                }
                img {
                    height: 100%;
                    transform: translateY(0);
                }
            }
            &:hover {
                a {
                    border-color: $gainsboro;
                    background-color: $dim-gray;
                    opacity: 0.3;
                }
            }
        }
        &.unselectable {
            &.selected {
                a {
                    background-color: $dark-gray;
                    opacity: 0.3;
                }
                &:hover {
                    a {
                        border-color: $gainsboro;
                        background-color: $dim-gray;
                        opacity: 0.3;
                    }
                }
            }
        }
        a {
            @include Univers($UniversRoman);
            font-size: $base-font + 2px;
            box-sizing: border-box;
            color: $navy-blue;
            border: 1px solid $navy-blue;
            display: block;
            line-height: 32px;
            height: 33px;
            border-radius: 0;
            min-width: 90px;
            margin: 5px 10px 5px 0;
            padding: 1px;
            text-align: center;
            white-space: nowrap;
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
                height: 33px;
                min-width: 90px;
            }
        }
    }
    .color li {
        a {
            width: 45px;
            min-width: 35px;
            max-height: 20px;
            border: 0;
            border-radius: 0;
            padding: 0;
            margin: 5px 10px;
            position: relative;
            z-index: 0;
            overflow: hidden;
            &:before {
                display: block;
                background: transparent;
                content: "";
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                position: absolute;
                left: 3px;
                top: 3px;
                z-index: 1;
                border: 1px solid $KS-dark-grey;
                outline: 4px solid $white;
            }
            &.color.color-swatch{
				background-clip: content-box !important;
				border: 0;
				padding: 3px;
			}
            img {
                width: 100%;
                height: auto;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid transparent;
                display: table;
                margin: auto;
                transform: translateY(-25%);
                &[src$="NO-SWATCH-TAB.svg"] {
                    height: 100%;
                    transform: translateY(-9%);
                }
            }
        }
        &.selected,
        &:hover {
            a {
                border: 1px solid $black !important;
            }
        }
        &.unselectable {
            a {
                border-color: $KS-dark-grey;
                opacity: 0.8;
                position: relative;
                color: $navy-blue;
                max-height: 22px;
                padding: 3px;
                margin: 3px 10px;
            }
        }
    }
    select {
        background-color: $white;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        height: 42px;
        width: 100%;
        margin-left: 0;
        @media screen and (min-width: 768px) {
            height: 32px;
        }
    }
}
// Tabs using just CSS, no javascript required
.tabs {
    position: relative;
    text-align: center;
}
.tab {
    @media screen and (min-width: 768px) {
        display: inline-block;
        float: none;
    }
    .tab-switch {
        display: none;
        &:checked {
            + .tab-label {
                @media screen and (min-width: 768px) {
                    background: $white;
                    border-bottom-color: $navy-blue;
                    color: $black;
                    font-weight: 900;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1;
                }
            }
            + label + .tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }
    .tab-label {
        border: 0;
        border-radius: 0;
        border-bottom: 3px solid $white;
        color: $nobel;
        display: block;
        font-weight: 400;
        line-height: 2.5em;
        margin-bottom: 0;
        position: relative;
        text-transform: none;
        top: 0;
        transition: all 0.25s;
        min-width: 100%;
        background: transparent;
        outline-offset: -4px;
        @media screen and (min-width: 768px) {
            cursor: pointer;
            padding: 0 1.25rem;
            &:hover {
                border-bottom-color: $nobel;
            }
        }
    }
    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;
        @media screen and (min-width: 768px) {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 2.75em;
            transition: all 0.35s;
            width: 100%;
        }
    }
}
.pt_product-details {
    @media screen and (max-width: 1023px) {
        @media screen and (min-width: 960px) {
            .product-col-2 {
                .product-add-to-cart {
                    .all-set-button {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #product-nav-container {
            div {
                float: right;
            }
        }
        .pdp-main {
            margin: 0;
            .product-set {
                &.product-col-1 {
                    width: 50%;
                    padding-right: 10px;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0;
                    &.main-product-set-details {
                        width: 50%;
                        padding-left: 10px;
                    }
                    .product-review {
                        margin-bottom: 12px;
                    }
                    .product-shortdescription {
                        margin-bottom: 15px;
                    }
                    .product-set-list {
                        .product-set-image {
                            width: 20.6%;
                        }
                        .product-set-details {
                            width: 78.4%;
                        }
                    }
                }
            }
            .product-bundle {
                padding: 0;
                .product-col-2 {
                    &.product-detail,
                    &.product-detail-set {
                        width: 100%;
                        padding: 0;
                        &:nth-child(2n) {
                            border-bottom: 1px solid $very-light-gray;
                        }
                        .product-name {
                            font-size: $base-font + 12px;
                            margin: 0 0 27px 0;
                        }
                        .product-thumbnails {
                            display: none;
                        }
                        .product-set-image {
                            width: 52.6%;
                        }
                        .product-set-details {
                            width: 100%;
                        }
                        .product-set-item {
                            padding: 20px 0 10px 0;
                        }
                        .product-add-to-cart {
                            width: 100%;
                            margin: 0;
                        }
                        .product-actions {
                            padding: 0;
                            width: 100%;
                            text-align: left;
                            .wishlist {
                                width: 100%;
                                padding: 20px 0;
                            }
                        }
                    }
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    .product-add-to-cart {
                        padding: 0 5px 20px;
                        @media screen and (max-width: 767px) {
                            padding: 0 0 20px;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 20px 0 0 0;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .tabs {
                margin: 61px 0 0 0;
                .tab-content {
                    padding: 41px 18px 41px 18px;
                }
                .tab-content-details {
                    width: 100%;
                }
            }
        }
        &#wrapper {
            width: 100%;
            padding-top: 0;
        }
        .primary-content {
            width: 100%;
            box-sizing: border-box;
            padding: 43px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .promotion {
            float: none;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            .promotion-callout {
                line-height: normal;
            }
        }
        #main {
            padding: 0;
        }
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .pdp-main {
            .product-review {
                display: block;
            }
            .inventory {
                .quantityinput {
                    border-top-left-radius: $KS-Btn-radius;
                    border-bottom-left-radius: $KS-Btn-radius;
                    height: 50px;
                }
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                    &.product-bundle {
                        .product-primary-image {
                            display: none;
                        }
                    }
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    &.product-bundle {
                        .product-set-image {
                            width: 48.4%;
                        }
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-set-list {
                            .product-set-item {
                                .product-variations {
                                    display: none;
                                }
                            }
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        #add-all-to-cart {
                            display: none;
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-image,
                        .product-set-details {
                            width: 100%;
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .inventory {
                        width: 70px;
                    }
                    .fields-address {
                        .form-row {
                            width: 100%;
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0 10px;
                    }
                }
            }
            .product-name {
                font-size: 32px;
                line-height: normal;
                box-sizing: border-box;
            }
            .product-shortdescription {
                font-size: 12px;
                line-height: normal;
                font-size: 3.75vw;
            }
            .product-longdescription {
                font-size: 16px;
                line-height: normal;
                font-size: 3.9vw;
            }
            .share-icon-container .share-icon-label {
                font-size: 12px;
                line-height: normal;
            }
            .top-add-all.product-add-to-cart .shop-the-look {
                font-size: 24px;
                line-height: normal;
                font-size: 6.5vw;
                padding: 10px 0;
            }
            .product-variations {
                .attribute {
                    .attribute_label {
                        .label,
                        .selected-value,
                        .size-chart-link {
                            line-height: normal;
                            font-size: 12px;
                        }
                    }
                }
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    .product-add-to-cart {
                        .all-set-button {
                            width: 100%;
                            margin: 15px 0;
                        }
                    }
                    .product-set-main-button-parent,
                    .product-set-main-button-error,
                    .product-set-main-button-error,
                    .variation-selection-error-msg,
                    .product-set-product-button-error {
                        width: 100%;
                        padding-left: 0;
                        margin: 0;
                        font-size: 12px;
                        font-size: 3.8vw;
                    }
                    span.product-variations-toggle {
                        font-size: $base-font + 2;
                        font-family: $HelveticaBold;
                        line-height: normal;
                        font-size: 3.8vw;
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-price {
                            line-height: normal;
                            font-size: 14px;
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        .all-set-button,
                        .product-set-main-button-parent {
                            display: none !important;
                        }
                    }
                    .availability-web {
                        font-size: 16px;
                        line-height: normal;
                        font-size: 3.8vw;
                        .availability-msg {
                            p {
                                font-size: 16px;
                                line-height: normal;
                                font-size: 3.8vw;
                            }
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-item {
                            padding-top: 15px;
                            padding-bottom: 25px;
                            .product-set-image,
                            .product-set-details {
                                width: 100%;
                                padding: 12px 0;
                                .product-price,
                                .price-standard,
                                .price-standard-exist {
                                    font-size: 14px;
                                    line-height: normal;
                                    font-size: 4.4vw;
                                }
                            }
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .inventory {
                        width: 70px;
                    }
                    .fields-address {
                        .form-row {
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0 10px;
                    }
                }
            }
            .product-bundle {
                width: 100%;
                .product-review {
                    display: none;
                }
                .product-name {
                    display: none;
                }
                .promotion {
                    margin: 0;
                }
                &.product-col-1 {
                    width: 100%;
                    &.product-set {
                        width: 100%;
                        .product-thumbnails {
                            ul {
                                li {
                                    text-align: center;
                                    .thumbnail-link {
                                        .productthumbnail {
                                            border-bottom: none;
                                        }
                                    }
                                }
                                &.slick-dots {
                                    float: right;
                                    li {
                                        text-align: right;
                                        float: left;
                                        margin: 0 20px 0 0;
                                        button {
                                            font-size: 0;
                                            line-height: 0;
                                            display: block;
                                            width: 10px;
                                            height: 11px;
                                            padding: 5px;
                                            cursor: pointer;
                                            color: $very-light-gray;
                                            border: 0;
                                            outline: none;
                                            background: $very-light-gray;
                                            border-radius: 50%;
                                        }
                                        &.slick-active {
                                            button {
                                                background: $black;
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-2 {
                    &.product-detail {
                        padding: 0;
                        .product-price {
                            padding: 30px 0 0 0;
                        }
                        .product-actions {
                            text-align: center;
                        }
                        .product-set-item {
                            padding: 15px 0 0 0;
                            .product-set-image {
                                width: 48.4%;
                            }
                            .product-set-details {
                                padding: 12px 0 0 0;
                            }
                            .product-variations-toggle {
                                display: none;
                            }
                            .product-variations {
                                display: block;
                                .attribute {
                                    padding: 0 0 35px 0;
                                    &:nth-child(2n) {
                                        padding: 0 0 40px 0;
                                    }
                                }
                            }
                        }
                        .product-actions {
                            .wishlist {
                                a {
                                    height: auto;
                                }
                            }
                        }
                        .product-add-to-cart {
                            margin: 0;
                            button {
                                width: 73%;
                                margin: 0;
                                border-top-left-radius: 0;
                                -webkit-border-top-left-radius: 0;
                                -moz-border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                -webkit-border-bottom-left-radius: 0;
                                -moz-border-bottom-left-radius: 0;
                            }
                        }
                    }
                }
                .product-add-to-cart {
                    button {
                        width: 85%;
                    }
                }
                .product-actions {
                    a {
                        font-size: $base-font + 12px;
                    }
                }
            }
            .tabs {
                .tab {
                    width: 50%;
                    text-align: left;
                    float: left;
                }
                .tab-content {
                    &.current {
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .tab-content-details {
                        .tab-left,
                        .tab-right {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
        }
        .pt_product-details {
            .pdp-main {
                .product-set {
                    &.product-col-1 {
                        &.product-bundle {
                            .product-primary-image {
                                .thumb {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .product-set {
            .product-add-to-cart {
                .sub-product-item {
                    width: 220px;
                    width: -webkit-calc(100% - 75px);
                    width: -moz-calc(100% - 75px);
                    width: calc(100% - 75px);
                }
            }
        }
        .pdp-main {
            .product-bundle {
                .bundle {
                    .product-add-to-cart {
                        button {
                            width: 73%;
                        }
                    }
                }
            }
            .tabs {
                .tab-label {
                    font-size: $base-font;
                }
                .tab-content {
                    .tab-content-details {
                        font-size: $base-font;
                    }
                }
            }
        }
    }
}
#QuickViewDialog {
    .pdpForm {
        fieldset {
            width: 100%;
        }
    }
    .pdp-main {
        margin: 0;
        .product-name {
            font-size: $base-font + 9px;
            line-height: 30px;
            font-style: normal;
            margin: 0;
            text-transform: uppercase;
            @include Univers($UniversBoldCondensed);
            letter-spacing: 1px;
        }
        .product-thumbnails,
        .enlarge-thumb-image {
            @media screen and (min-width: 768px) {
                &.slick-arrow-bar {
                    &::before {
                        top: 53px;
                    }
                }
                button {
                    &.slick-arrow {
                        &.slick-prev {
                            top: 65px;
                        }
                        &.slick-next {
                            top: 33px;
                        }
                    }
                }
            }
        }
        .product-price {
            font-size: $base-font + 9px;
            @include Univers($UniversBoldCondensed);
            float: left;
            width: 100%;
            margin: 7px 0 10px;
        }
        .product-actions {
            display: none;
        }
        .product-set {
            &.product-col-1 {
                padding: 46px 30px 0;
                width: 163px;
                left: 100%;
                right: auto;
                margin-left: -163px;
                box-sizing: border-box;
                background: $concrete;
                max-height: 100%;
                overflow: overlay;
                position: absolute;
                top: 0;
                height: 100%;
                vertical-align: top;
                .heading {
                    font-size: $base-font;
                    @include Univers($UniversRoman);
                }
                .product-thumbnails {
                    .thumb {
                        width: 100%;
                        a {
                            border: 0;
                            &:hover {
                                border: 0;
                            }
                            img {
                                border-bottom: 4px solid transparent;
                            }
                        }
                        &.selected {
                            a {
                                img {
                                    border-color: $navy-blue;
                                }
                            }
                        }
                    }
                }
            }
            .view-details {
                width: 79%;
            }
            &.product-col-2 {
                width: 83%;
                float: none;
                box-sizing: border-box;
                padding: 0 20px;
            }
            .product-set-list {
                .product-image-container,
                .product-set-image {
                    width: 40%;
                    .view-details,
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    padding: 0 20px 0;
                    width: 100%;
                    display: block;
                    float: none;
                    .qv-productset-scrollbar {
                        padding: 13px 0 0;
                        display: inline-block;
                    }
                    .product-name {
                        font-size: $base-font + 12px;
                        line-height: 28px;
                    }
                    .product-price {
                        @include Univers($UniversBoldCondensed);
                    }
                    .product-variations {
                        .attribute {
                            .attribute_label {
                                .label {
                                    @include Univers($UniversCondensed);
                                }
                                .selected-value {
                                    @include Univers($UniversCondensed);
                                }
                                .size-chart-link {
                                    @include Univers($UniversCondensed);
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .swatches {
                            li {
                                a {
                                    @include WorkSansMedium($WorkSans);
                                    border-radius: 0;
                                }
                            }
                            &.color {
                                li {
                                    a {
                                        border: none;
                                    }
                                    &.selected {
                                        a {
                                            border: 1px solid $black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-set-item {
                    display: none;
                    border-bottom: none;
                    padding-bottom: 0;
                    max-height: 550px;
                    &.selected {
                        display: inline-block;
                        padding-top: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding: 0;
                width: 100%;
                border: none;
                .availability-web {
                    display: block;
                }
                .wishlist {
                    width: 100%;
                    padding: 20px 0 0 0;
                    text-align: center;
                    display: inline-block;
                    font-family: $Lora;
                    font-size: $base-font - 1px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    a {
                        .wishlist-logo {
                            display: inline-block;
                            line-height: 18px;
                            @include WorkSansBold($WorkSans);
                            background: url("../../../images/wishlist.svg")
                                no-repeat;
                            width: auto;
                            height: 18px;
                            padding-left: 30px;
                            &.added-to-wishlist {
                                background: url("../../../images/wishlist-solid.svg")
                                    no-repeat;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1023px) {
            .product-add-to-cart {
                button#add-to-cart {
                    width: auto;
                }
            }
        }
    }
    .product-col-1 {
        .product-primary-image {
            height: 393px;
        }
        .b_product_badge {
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;
        }
    }
    .product-col-2 {
        .product-name {
            .item-name {
                font-size: $base-font + 12px;
            }
        }
        .e-giftcard-detail,
        .giftcard-detail {
            .product-add-to-cart {
                .add-to-cart {
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
                .wishlist {
                    margin-bottom: 20px;
                }
            }
        }
        .attribute {
            .attribute_label {
                .label {
                    @include Univers($UniversCondensed);
                }
            }
        }
        .promotion {
            font-size: $base-font + 2px;
            width: 100%;
            .promotion-title {
                font-weight: normal;
            }
            .promotion-callout {
                font-size: $base-font + 4px;
                @include Univers($UniversCondensed);
                .promo-tooltip {
                    font-size: $base-font + 1px;
                    @include Univers($UniversRoman);
                }
            }
        }
        .inventory {
            select {
                @include WorkSansBold($WorkSans);
                font-size: $base-font + 3px;
            }
        }
    }
    .pdp-main {
        &.specialproductset {
            .product-actions {
                .socialsharing {
                    display: none;
                }
            }
            .promotion {
                display: inline-block;
                margin: 10px 0 0 0;
            }
            .product-primary-image {
                height: 100%;
            }
            .product-price {
                padding: 0 0 15px 0;
                margin: 0;
            }
            .savingmessage {
                padding: 0 0 15px 0;
            }
            .product-bundle-main {
                width: auto;
                float: none;
                .product-col-2 {
                    width: 100%;
                    padding: 0;
                    border: 0;
                    .product-name {
                        word-break: break-word;
                        margin: 0 0 14px 0;
                    }
                    .product-add-to-cart {
                        button {
                            width: 73%;
                        }
                    }
                    .product-set-list {
                        padding: 0;
                        box-sizing: border-box;
                        float: left;
                        .product-set-item {
                            display: block;
                            max-height: none;
                            border-bottom: 6px solid $light-gray;
                            box-sizing: border-box;
                            padding-right: 10px;
                            &.selected {
                                border-top: 1px solid $light-gray;
                            }
                        }
                        .product-set-details {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
                form {
                    width: 100%;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 10px 0 20px 0;
                        margin: 0;
                        border-top: 1px solid $light-gray;
                    }
                }
                .product-actions {
                    display: block;
                    border: none;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0 0 30px 0;
                        margin: 0;
                        .wishlist {
                            padding: 0;
                        }
                    }
                }
                .view-details {
                    width: 93%;
                    padding: 0 0 20px 0;
                }
            }
        }
    }
}
.product-variations {
    .attribute:last-child {
        border-bottom: none;
    }
}
.ui-dialog {
    &.product-set-quickview {
        &.quick-view {
            .ui-dialog-content {
                padding: 0;
            }
        }
        #QuickViewDialog {
            .pdp-main {
                .product-set {
                    .product-add-to-cart {
                        padding-top: 20px;
                    }
                    &.product-col-1 {
                        height: 108%;
                        .product-thumbnails {
                            .thumb {
                                padding: 0 0;
                                margin: 0 0;
                                text-align: center;
                            }
                        }
                    }
                    #thumbnails {
                        width: 100%;
                    }
                }
            }
        }
        .qv-productset-scrollbar {
            display: inline-block;
            width: 96% !important;
        }
    }
    &.quick-view {
        overflow: visible;
        .ui-dialog-content {
            position: relative;
            overflow: visible;
            .pdp-main {
                .product-col-1 {
                    .enlarge-video-sec {
                        .enlarge-video {
                            &.video-link {
                                float: left;
                            }
                        }
                    }
                    .promotionalbanner {
                        display: none;
                    }
                }
                .top-wrap,
                .info {
                    padding: 0;
                }
                .product-variations {
                    .attribute {
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        &.variant-dropdown {
                            border-top: none;
                        }
                        &.sizeFamily {
                            border-bottom: 2px solid $navy-blue;
                            padding-bottom: 0;
                            margin-bottom: 0;
                            vertical-align: bottom;
                            li a {
                                margin-bottom: 0;
                                border-radius: 0;
                            }
                        }
                    }
                }
                .product-monogramming,
                .product-hemmable {
                    border-bottom: none;
                    .hemming-tips-link:before {
                        left: -14px;
                    }
                }
                .product-col-2 {
                    padding: 0 5% 0 0;
                    input[type="checkbox"] {
                        margin: 0 0 0 5px;
                    }
                    .product-special-messages {
                        padding: 20px 0 0 0;
                    }
                    .tab-content {
                        ul {
                            padding: 0 0 0 15px;
                        }
                    }
                    &.giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 74%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    &.e-giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 100%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .product-add-to-cart {
                        padding: 20px 0 30px;
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        .inventory {
                            width: 80px;
                        }
                        button {
                            padding: 0 27px;
                            width: 280px;
                            box-sizing: content-box;
                            border: none;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 18px 0 0 0;
                            margin: 0 auto;
                            a {
                                float: none;
                                display: inline-block;
                            }
                        }
                        .fields-address {
                            .form-row {
                                &:nth-child(3) {
                                    width: 100%;
                                }
                                .input-textarea {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .product-variation-content {
                        max-height: 400px;
                        overflow: auto;
                        width: 100%;
                        float: left;
                        padding-right: 13px;
                    }
                }
            }
        }
    }
}
.pt_product-details {
    #main {
        .pdp-main {
            .product-col-2 {
                &.e-giftcard-detail {
                    .product-add-to-cart {
                        .add-to-cart {
                            @media screen and (max-width: 480px) {
                                width: 100%;
                            }
                        }
                    }
                }
                &.giftcard-detail {
                    .product-add-to-cart {
                        .add-to-cart {
                            @media screen and (max-width: 480px) {
                                width: 75%;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    .custom-select {
                        .selected-option {
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                            padding: 13px 21px;
                        }
                    }
                }
            }
            .product-monogramming {
                select,
                input,
                textarea {
                    font-family: $WorkSans;
                    font-size: 12px;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .pt_product-details {
        #main {
            .product-thumbnails {
                .slick-dots {
                    margin: 15px 20px 15px 0;
                    float: right;
                    li {
                        margin-left: 10px;
                        float: left;
                        button {
                            text-indent: -99999px;
                            border: 1px solid $very-light-gray;
                            background: $very-light-gray;
                            width: 10px;
                            border-radius: 50%;
                            padding: 0;
                            height: 10px;
                        }
                        &.slick-active {
                            button {
                                border: 1px solid $black;
                                background: $black;
                            }
                        }
                    }
                }
            }
        }
        .pdp-main {
            &.specialproductset {
                .product-set {
                    &.product-col-2 {
                        &.main-product-set-details {
                            .product-set-list {
                                .product-set-item {
                                    .product-variations {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #product-content {
                padding: 0 10px;
            }
            .product-actions {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .enlarge-dialog {
        &.ui-dialog {
            top: 0 !important;
            position: fixed !important;
            .pinch-zoom-container {
                a {
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    .productthumbnail {
                        max-height: 100%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
#QuickViewDialog .view-details {
    @include Univers($UniversCondensed);
    letter-spacing: 1px;
    font-size: $base-font + 8px;
    color: $navy-blue;
    a {
        color: $navy-blue;
    }
}
.TTratingBox {
    height: auto;
}
.TT3ShowMore span.TT3ShowMoreText {
    letter-spacing: 0.5px;
    margin-left: 15px;
}
.ui-dialog.quick-view .ui-widget-header {
    height: auto;
    display: flex;
    float: none;
    flex-direction: row-reverse;
    .ui-dialog-titlebar-close {
        position: relative;
        float: none;
    }
}
//WCAG focus outline issues on quick modal dialog only for KS brand
.ui-dialog-content {
    .prod-detail-page {
        .focus-outline {
            outline-offset: 0;
        }
    }
    .pdp-main .product-variations .attribute.size_border .swatches li a {
        margin-left: 3px;
    }
}
.pdp-main
    .product-info
    .tabs
    .tab-content
    .tab-content-details
    #TTaskArea
    #TT4questionTextWrap {
    overflow: hidden;
}
#QuickViewDialog .pdp-main .product-add-to-cart button#add-to-cart {
    width: calc(100% - 135px);
    &.atb-disabled {
        padding-left: 27px;
        padding-right: 27px;
    }
}

@media screen and (max-width: 1024px) {
    #QuickViewDialog .pdp-main .product-add-to-cart button#add-to-cart {
        font-size: $base-font + 1px;
        width: calc(100% - 180px);
    }
}

