.custom-select ,.prod-detail-page .custom-select  {
    .selected-option {
        @include Univers($UniversRoman);
		border-radius: $KS-Btn-radius 0 0 $KS-Btn-radius;
        padding: 13px 21px;
        transition:none;
        border:1px solid $navy-blue;
        line-height: normal;
        height: auto;
        min-height: 50px;
    }
    .selection-list {
		
        li {
            @include Univers($UniversRoman);
            padding: 9px 0 9px 21px;
            color: $navy-blue;
            &:hover{
                color: $white;
                background: $navy-blue;
            }
        }
    }
    &.current_item {
        .selected-option {
            
		border-radius: $KS-Btn-half-radius 0 0 0;
        }
        .selection-list {
            border-bottom-right-radius:0;
            border-bottom-left-radius: 0;
            border: solid 1px $navy-blue;
            border-top:none;
            @media screen and (max-width: 767px) {
                border-top:solid 1px $navy-blue;
            }
        }
    }
    select {
     @media screen and (max-width: 1024px) {
         @include Univers($UniversRoman);
         border-radius: $KS-Btn-radius 0 0 $KS-Btn-radius;
         padding: 0 21px;
         -webkit-transition: none;
         transition: none;
         border: 2px solid #002953;
         height: 50px;
     }
 }
    &.sort-by{
        .selected-option{
            border:none;
            height: auto;
            min-height: auto;
            padding:12px 30px;
            font-size: $base-font; 
            @media screen and (max-width: 1023px) {
                padding: 13px 21px;
            }
        }
    }
}
.refinements .custom-select {
    &.current_item {
        .selection-list {
            border-bottom-right-radius:0;
            border-bottom-left-radius: 0;
        }
    }
}
.mm-prod-details {
    .custom-select {
        .selected-option {
            line-height: 38px;
            min-height: 38px;
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
            @include Univers($UniversBoldCondensed);
        }
    }
}
.pt_checkout {
    .form-row {
        .field-wrapper {
            .selection-list {
                li {
                    font-family: $Helvetica;
                    font-size: $base-font + 1px;
                }
            }
        }
    }
}
.product-list-page{
    .custom-select {
        &.current_item {
            .selected-option {
                border-radius: 0;
            }
            .selection-list {
                border-bottom-right-radius:0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
.pt_checkout {
    .custom-select {
        .selected-option {
            font-family: $Helvetica;
            font-weight: normal;
            font-size: $base-font + 1px;
            text-transform: none;
            border-radius: 0;
        }
        .selection-list {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            li {
                border-bottom: 0;
                font-family: $Helvetica;
            }
        }
    }
}
.pt_account {
    .custom-select {
        .selected-option {
            @include Univers($UniversCondensed);
            text-transform: none;
            color: $dusty-gray;
        }
        .selection-list {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            li {
                border-bottom: 0;
                width: 100%;
                padding-right :0px;
            }
        }
    }
}
.plcc-form-section .make-label-absolute .form-row .field-wrapper .custom-select .selected-option {
    height: 50px;
    border-radius: 0;
}