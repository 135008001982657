.personal-message {
	.addto-message {
		@include Univers($UniversRoman);
		color:$navy-blue; 
	}
}
.fields-address {    
    .character-count {
		@include Univers($UniversRoman);
    }
	.form-row {
    	input[type="text"] {
			@include Univers($UniversRoman);
			border: 2px solid $navy-blue;
    	}    	
    	.input-textarea {
			@include Univers($UniversRoman);
			border: 2px solid $navy-blue;
    	}
	}
}
