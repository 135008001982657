@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.pt_customer-service .legacy-sitegen {
	.primary-content {
		max-width: 964px;
		margin: 0 auto;
		float: none;
	}
	.qa-section {
		border-top: none;
		.question-title {
			padding: 10px 0 25px 0;
			letter-spacing: 0;
		}
		.qa-content {
			padding: 22px 38px 22px 22px;
			.question {
				letter-spacing: 1px;
			    cursor: pointer;
			    &::after {
			    	background-position: 100% 5px;
			    }
			    &.active {
			    	cursor: pointer;
			    	&::after {
    					background-position: 100% 5px;
			    	}
			    }
			}
		}
	}
}
.pt_customer-service .legacy-sitegen, .pt_article-page .legacy-sitegen {
	.content-asset {
		letter-spacing: .5px;
	}
	.make-label-absolute {
		.form-row {
			.field-wrapper {
				.custom-select {
					&.current_item {
						.selection-list {
							border: 1px solid $dark-gray;
							border-top: none;
						}
					}
					.selected-option {
						border: 1px solid $dark-gray;
						border-radius: 0;
					}
				}
			}
		}
	}
}
.contact-us-page {
	.form-wrapper {
		.solid-wine-berry,input[type="button"].solid-wine-berry:hover {
			letter-spacing: .5px;
		}
	}
}
.contactpage-right-content {
	h1 {
		@include Univers($UniversBoldCondensed);
	    font-size: $base-font + 13px;
	}
}
.contactpage-right-content {
	.text-content, .text-content p {
		@include Univers($UniversRoman);
	}
}
.customer-service-landing-contacts {
	margin: 0 0 3% 0;
}
.customerservice-name {
	text-align: center;
	font-size: $base-font + 49px;
    text-transform: uppercase;
    letter-spacing: 14px;
    margin: 0 0 29px 0;
    padding: 13px 0 0 0;
}
.tiles-section {
	width: 100%;
	display: inline-block;
	margin: 0 0 10px 0;
}
.content-asset-info {
	float: left;
	background-color: $light-skygrey;
	text-align: center;
	margin: 0 19px 20px 0;
	&:nth-child(3n) {
		margin: 0 0 20px 0;
	}
	&:hover {
		background-color: $navy-blue;
		.hovered-content {
			display: table;
			height: 200px;
			width: 67%;
		    vertical-align: middle;
		    margin: 0 auto;
		   a {
		   		display: table-cell;
		    	vertical-align: middle;
		   }
		    .hovered-text {
		    	display: table-cell;
		    	vertical-align: middle;
		    }
			ul {
				font-size: $base-font + 3px;
				@include Univers($UniversCondensed);
				margin: 0;
				padding: 0;
				text-align: left;
				color: $white;
			}
			li {
				margin: 0 0 17px 0;
			}
		}
		.inner-asset-info {
			display: none;
		}
	}
	.hovered-content {
		display: none;
	}
	.hovered-title {
		display: none;
	}
	.inner-asset-info {
	    display: table;
	}
	.title {
		font-size: $base-font + 9px;
		text-transform: capitalize;
		@include Univers($UniversCondensed);
   		display: inline-block;
   		width: 100%;
   		margin: 30px 0 0 0;
	}
}
.inner-asset-info {
	
	height: 200px;
    width: 100%;
    display: table;
    .icon {
    	width: 108px;
    	height: 72px;
    	display: inline-block;
    }
    a {
    	vertical-align: middle;
    	display: table-cell;
    	img {
    		max-width: 100%;
    		max-height: 100%;
    	}
    }
}
.inner-service-landing-contacts {
	padding: 23px 0 50px 0;
    text-align: center;
    background-color: $light-skygrey;
    h2 {
    	font-size: $base-font + 13px;
	    padding: 11px 0 4px 0;
	    text-transform: none;
	    @include Univers($UniversCondensed);
	   	width: 100%;
	   	display: inline-block;
	   	margin-top: 0;
	   	margin-bottom: 28px;
    }
}
.ways-contacts {
	width: 100%;
	display: inline-block;
}
.media-contacts {
	border-right: 1px solid $KS-dark-grey;
    padding: 20px 42px 20px 0;
    letter-spacing: .5px;
}
.chat-contact {
	padding: 20px;
	display: inline-block;
	div{
		text-align: center;
	}
}
.mail-contact {
	padding: 20px 42px 20px 26px;
	letter-spacing: .5px;
	border-left: 1px solid $KS-dark-grey;
}
.chat-title {
	display: block;
}
.contact-title,.chat-title,.mail-title {
	font-size: $base-font + 3px;
	@include Univers($UniversCondensed);
}
.contact-text {
	font-size: $base-font + 3px;
	@include Univers($UniversCondensed);
	margin: 0 0 0 12px;
}
.chat-text {
	font-size: $base-font + 3px;
	@include Univers($UniversCondensed);
	div {
		display:inline;
	}
}
.chat-text-available {
	color: #002953;
	font-size: 14px;
	padding: 0 5px;
	text-transform: uppercase;
	@include Univers($UniversBoldCondensed);
}
.chat-text-unavailable {
	color: #bd030c;
	padding: 0 5px;
	text-transform: uppercase;
}
.mail-text {
	font-size: $base-font + 3px;
	@include Univers($UniversCondensed);
}
.view-all-faq {
	background: $navy-blue;
	color: $white;
	@include Univers($UniversBoldCondensed);
	font-size: $base-font + 3px;
	text-transform: uppercase;
	text-align: center;
	padding: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	a {
		width: 100%;
		color: $white;
		padding: 14px 30px;
		float: left;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		&:hover {
			color: $white;
		}
	}
}
.call-image,.chat-image,.mail-image {
	display: inline-block;
	margin: 0 5px 0 0;
}
.available-image {
	display: inline-block;
	margin: 0 5px;
}
.pt_article-page {
	&.cs-faq-ww .legacy-sitegen {
		.customerservice-name {
			padding: 29px 0 46px 12px;
			 box-sizing: border-box;
		    -webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;
		}
		.article-heading {
			margin: 25px 0 0 0;
		}
	}
}
//Frequently Asked Questions
.faq-content {
	margin: 0 0 30px 0;
	h2 {
	    margin: 16px 0 10px 0;
		text-transform: capitalize;
		font-size: $base-font + 6px;
	    font-family: $Lora;
	    font-weight: 400;
		@include Univers($UniversBold);
	}
	.qa-section {
		.qa-desc {
			.qa-content {
				padding: 23px 39px 20px 29px;
				&.active {
					padding: 23px 39px 33px 29px;
				}
				.question {
					display: inline-block;
					width: 100%;
					span {
						width: 95%;
    					float: left;
					}
					&::after {
						background-image: url('../../../images/ww/closed-arrow-FAQ.png');
						background-repeat: no-repeat;
						background-size: 12px;
						background-position: 100% 4px;
					}
					&.active {
						margin: 0 0 9px;
						&::after {
							background-image: url('../../../images/ww/open-arrow-FAQ.png');
						}
					}
				}
				.answer {
					padding: 2px 0 0 0 !important;
				}
			}
		}
	}
}
//Article-pages
.pt_article-page .legacy-sitegen {
	@media screen and (min-width: 1024px) {
		#main {
			padding: 0 20px;
		}
		.primary-content {
			width: 66.7%;
    		float: left;
		}
	}
	#secondary {
		width: 26.3%;
		box-shadow:none;
		 padding:0 15px;
		 float:left;
		nav {
			border-bottom: none;
		}
		.secondary-navigation {
			max-width: 154px;
		    margin: 0;
		    text-align: left;
		    ul {
		    	font-size: $base-font;
		    	@include Univers($UniversCondensed);
		    	margin: 0;
		    	li {
		    		margin: 0 0 17px 0;
		    		a {
		    			color: $black;
		    		}
		    		.currentpage {
		    			color: $navy-blue;
		    		}
		    		&:hover {
		    			a {
		    				color: $navy-blue;
		    			}
		    		}
		    	}
		    }
		}
	}
	.customerservice-name {
	    border-bottom: 1px solid $alto;
	    margin: 0;
	    padding: 0 0 44px 0;
    	letter-spacing: .5px;
	}
	.qa-section {
		border-top: none;
		.qa-content {
			padding: 22px 39px 22px 25px;
			.question {
				cursor: pointer;
				&::after {
					background-position: 100% 5px;
				}
				&.active {
					cursor: pointer;
					&::after {
						background-position: 100% 5px;
					}
				}
			}
			.answer {
				font-size: $base-font + 2px;
				p {
					font-size: $base-font + 2px;
					margin: 3px 0 1px 0;
					&:last-child {
						margin: 3px 0 12px 0;
					}
				}
				.sub-heading {
					font-size: $base-font + 3px;
					padding: 10px 0 14px 0;
				}
			}
			&:nth-child(1n) {
				.answer {
					padding: 0;
				}
			}
		}
	}
	.account-nav-asset {
		display: none;
	}
	// Global elements in article pages
	.make-label-absolute {
		label {
			span {
				@include Univers($UniversCondensed);
	    		font-size: $base-font + 1px;
	    		color: $dim-gray;
			}
		}
	}
	input[type="text"], input[type="number"], input[type="password"], input[type="date"],textarea, select {
	    border-radius: 3px;
	    @include Univers($UniversCondensed);
		font-size: $base-font + 1px;
		border: 1px solid $silver;
	}
}
//Contact page global elements
.contactpage-right-content {
	.contactpage-chat-module {
		h1 {
			margin: 3px 0 17px;
		}
	}
	.thankyou-section {
		.contactusthankyouforcontacting {
			 @include Univers($UniversCondensed);
			 font-size: $base-font + 7px;
		}
	}
}
.contact-us-page {
	.form-wrapper {
		margin-top: 26px;
		padding: 7.3% 6.5% 7.3% 7.5%;
		@media screen and (min-width: 768px) {
			.left-cont {
				max-width: 289px;
				width: 39.6%;
			}
			.right-cont {
				max-width: 386px;
		    	width: 52.3%;
		    	.form-row-button {
	    		    margin-bottom: 0;
		    	}
			}
		}
		.solid-wine-berry {
			min-width: 180px;
		}
	}
}
.article-heading {
	margin: 26px 0 0 0;
    padding: 0 0 14px 0;
    border-bottom: 1px solid $navy-blue;
    font-size: $base-font + 12px;
    @include Univers($UniversCondensed);
    text-transform: uppercase;
    .menu-word {
    	display: none;
    }
}
.secondary-article-heading {
	margin: 29px 0 23px 0;
	@include Univers($UniversBoldCondensed);
	font-size: $base-font + 1px;
	display: inline-block;
}
.shipping-info-section {
	margin: 20px 0 27px 0;
}
.merchandise-structure {
	width: 66.8%;
	display: inline-block;
	.col-1 {
		width: 54%;
		margin: 6px 0 6px 0;
	}
	.col-2 {
		width: 38%;
		margin: 6px 0 6px 0;
	}
	span {
		width: 100%;
		display: inline-block;
		margin: 0 0 14px 0;
		font-size: $base-font + 3px;
		@include Univers($UniversCondensed);
	}
}
.shipping-heading {
	margin: 0 0 15px 0;
	font-size: $base-font + 2px;
	@include Univers($UniversBoldCondensed);
}
.restriction-bold {
	@include Univers($UniversBoldCondensed);
	font-size: $base-font + 2px;
	margin: 10px 0 0 0;
}
.menu-word {
	display: none;
}
//Privacy Policy
.privacy-policy-ww {
	.primary-content {
		width: 100%;
	}
}
.privacy-policy {
	margin: 0 0 20px 0;
	.back-to-top {
		font-size: 13px;
		@include Univers($UniversBoldCondensed);
		text-transform: uppercase;
	}
	a {
		&[href], &.anchor {
			color: $navy-blue;
		}
	}
	.heading {
	    margin: 0;
		padding: 24px 0 14px 10px;
		border-bottom: 1px solid $alto;
		border-top: 1px solid $alto;
		font-size: 24px;
		@include Univers($UniversRoman);
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
	}
	.para-description-section {
		padding: 0 10px;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
	}
	.sub-heading {
		font-size: 14px;
		@include Univers($UniversRoman);
		text-transform: none;
		margin: 16px 3px 17px;
	}
	.para-description {
		font-size: 13px;
		@include Univers($UniversRoman);
		padding: 0 30px;
		p {
			font-size: 13px;
			margin: 30px 0 15px 0 ;
			line-height: 23px;
		}
		&.section-1 {
		    padding: 12px 30px 50px;
			background: $light-skygrey;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;
			.list {
				h2 {
					text-transform: none;
					margin: 19px 0 0 2px;
					font-size: 13px;
				}
				ul {
					margin: 14px 0 0 0;
					padding: 0 0 0 18px;
					li {
						margin: 0 0 12px 0;
						@include Univers($UniversBoldCondensed);
						color: $navy-blue;
						list-style: disc outside none;
						a {
							color: $navy-blue;
						}
						p {
							margin: 10px 25px;
						}
					}
				}
			}
		}
		&.section5 {
			.back-to-top {
				margin-top: 15px;
				display: inline-block;
			}
			h2 {
				margin: 20px 0;
				font-size: $base-font + 1px;
				text-transform: none;
			    width: auto;
			    display: inline-block;
			}
		}
		h1 {
			margin: 40px 0 0 0;
			font-size: 24px;
			@include Univers($UniversRoman);
			text-transform: capitalize;
			padding: 0 0 18px 0;
    		border-bottom: 1px solid $alto;
		}
		ul {
			padding: 0;
			margin: 10px 0 15px 0;
			line-height: 22px;
			li {
				list-style: inside disc;
    			margin-bottom: 40px;
    			&:last-child {
    				margin-bottom: 15px;
    			}
			}
		}
	}
	.qa-content {
	    padding: 23px 39px 20px 29px;
	    background-color: $light-skygrey;
	    margin: 0 0 2px 0;
	    &.active {
	    	padding: 23px 39px 19px 29px;
	    }
	}
	.question {
	    font-size: $base-font + 2px;
	    width: 100%;
	    display: inline-block;
	    cursor: pointer;
		@include Univers($UniversBoldCondensed);
	    text-transform: uppercase;
	    &::after {
		    background-repeat: no-repeat;
		    background-size: 15px;
		    background-position: 100% 5px;
	    }
	    &.active {
	    	margin: 0 0 15px;
	    }
	}
	.answer {
		display: none;
		&.active {
			display: block;
			font-size: $base-font + 1px;
			@include Univers($UniversRoman);
			div {
				margin: 0 0 15px 0;
			}
			.visit-link {
				a {
					border-bottom: 1px solid $black;
				}
			}
			.postal-address {
				.label {
				    display: inline-block;
				    width: auto;
				    vertical-align: top;
				}
				.value {
					width: auto;
				    display: inline-block;
				    line-height: 20px;
				}
			}
			.customer-service {
				.contact {
				    border-right: 1px solid $black;
					padding-right: 10px;
				}
				.hearing {
					padding-left: 10px;
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.pt_article-page{
		&.privacy-policy-ww .legacy-sitegen {
			.primary-content {
				width: 100%;
			}
		}
	}
	.media-contacts {
		padding: 20px 13px 20px 0;
	}
	.chat-contact {
		padding: 20px 8px 20px 8px;
	}
	.mail-contact {
		padding: 20px 15px 20px 7px;
	}
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
	}
	.merchandise-structure {
		width: 80.8%;
		.col-1 {
			width: 67%;
			margin: 0;
		}
		.col-2 {
			margin: 0;
			width: 33%;
		}
	}
}
@media screen and (max-width:1023px) {
	.pt_customer-service .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
		.qa-section  {
			.question-title {
			    width: auto;
			    float: left;
			    display: inline-block;
			}
		}
		.qa-desc {
			width: 100%;
			display: inline-block;
		}
	}
	.customer-service-faq {
		&:last-child {
	   		display: inline-block;
	   		margin: 20px 0 30px 0;
	   	}
	    .view-all-faq {
	    	a {
	    		padding: 14px 42.5px;
	    	}
	    }
	}
	.content-asset-info {
		&:hover {
			background-color: $light-skygrey;
			.hovered-content {
				display: none;
			}
			.inner-asset-info {
				display: table;
			}
		}
	}
	.customerservice-name {
		font-size: $base-font + 39px;
		margin: 30px 0 40px 0;
	}
	.content-asset-info {
		.title {
			font-size: $base-font + 5px;
		}
	}
	.media-contacts {
		padding: 20px 8px 16px 0;
	}
	.chat-contact {
		padding: 20px 13px 20px 20px;
	}
	.inner-service-landing-contacts {
		padding: 30px 0 40px 0;
	}
	.mail-contact {
		padding: 10px;
	}
	//Article-page
	.pt_article-page .legacy-sitegen {
		.second-section {
			width: 100%;
			display: inline-block;
		}
		#secondary {
			width: 49.5%;
			margin: 15px 20px 14px;
			.secondary-left-section {
				border: 3px solid $navy-blue;
				border-top: none;
				width: 100%;
				display: none;
				background-color: $light-skygrey;
				&.active {
					display: block;
					z-index: 10;
    				position: absolute;
    				box-sizing: border-box;
				}
				ul {
					@include Univers($UniversBoldCondensed);
					font-size: $base-font + 7px;
				}
			}
			.secondary-navigation {
				max-width: 100%;
				background-color: $light-skygrey;
				position: relative;
				.secondary-aticle-content {
					width: 100%;
					display: inline-block;
					border: 3px solid $navy-blue;
					background-color: $light-skygrey;
					box-sizing: border-box;
					&.activee {
						padding: 0 0 3px 0;
						border-bottom: none;
					}
				}
				ul {
					li {
						margin: 4px 20px 21px 20px;
						.currentpage {
			    			color: 	$black;
			    		}
					}
				}
			}
		}
		.customerservice-name{
			display: inline-block;
    		width: 100%;
    		padding: 30px 0 30px 0;
    		border-top: 1px solid $alto;
    		&.hide-tablet {
    			display: none
    		}
    		&.hide-mobile {
    			display: none;
    		}
		}
		.page-content {
			padding: 0 20px;
			box-sizing: border-box;
		}
	}
	.secondary-article-heading {
		margin: 12px 18px;
		@include Univers($UniversBoldCondensed);
		font-size: $base-font + 7px;
		text-transform: capitalize;
	    display: block;
		background: {
			image: url(../../../images/ks/carat-down-blue.svg);
			position: 100% 3px;
			repeat: no-repeat;
		    size: 25px;
		}
	    &.active {
	    	background-image: url(../../../images/ks/carat-up-blue.svg);
	    }
	}
	.opacity-coloring {
		background-color: $white;
		opacity: 0.5;
	}
	.merchandise-structure {
		width: 100%;
		.col-1 {
			width: 46%;
		}
		.col-2 {
			width: 45%;
		}
	}
	.menu-word {
		display: inline;
		@include Univers($UniversCondensed);
		font-size: $base-font + 1px;
	}
	.pt_article-page {
		&.cs-faq-ww .legacy-sitegen {
			.customerservice-name {
				padding: 29px 0 15px 12px;
				border-bottom: 0;
			}
			.article-heading {
				padding-left: 18px;
			}
		}
	}
	//Frequently Asked Questions
	.faq-content {
		.qa-section {
			.qa-desc {
				.qa-content {
					padding: 23px 29px 20px 29px;
					&.active {
						padding: 23px 29px 17px 29px;
					}
				}
			}
		}
	}
	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 16px 0 14px 18px;
		}
		.para-description {
			padding: 0;
		}
		.para-description-section {
			padding: 0;
		}
		.para-description {
			&.section-1 {
				margin: 20px 0 0 0;
				padding: 12px 30px 20px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.pt_customer-service .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}
		.qa-section {
			.question-title {
				padding-right: 0;
				font-size: $base-font + 10px;
			}
			.qa-content {
				padding: 20px 10px 20px 10px;
			}
		}
	}
	.customerservice-name {
		font-size: $base-font + 13px;
		margin: 30px 0 16px 0;
	}
	.content-asset-info {
		width: 100%;
		margin: 0 0 5px 0;
		&:nth-child(3n) {
			margin: 0 0 5px 0;
		}
		.inner-asset-info {
			padding: 0;
			display: inline-block;
			height: auto;
			a {
				width: 85%;
				display: table;
				vertical-align: middle;
				margin: 0 auto;
				margin-top: 15px;
    			margin-bottom: 15px;
			}
		}
		.icon {
			float: none;
			display: table-cell;
			vertical-align: middle;
			width: 35.5px;
    		height: 25px;
		}
		.title {
			width: auto;
			float: none;
			display: table-cell;
			vertical-align: middle;
			text-align: left;
    		padding: 0 0 0 32px;
		}
	}
	.inner-service-landing-contacts {
		h2 {
			padding: 12px 0 4px 0;
			font-size: $base-font + 10px;
		}
	}
	.media-contacts {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    padding: 20px 0 20px 0;
	    border-right: none;
	}
	.call-image {
		width: 100%;
		margin: 0 0 10px 0;
	}
	.mail-image {
		width: 100%;
		margin: 0 0 10px 0;
	}
	.chat-image {
		width: 100%;
	}
	.contact-title {
	    display: inline-block;
	    padding: 0 0 5px 0;
	}
	.contact-text {
		margin: 0;
		display: inline;
		@include Univers($UniversBoldCondensed);
	}
	.chat-text {
		@include Univers($UniversBoldCondensed);
	}
	.mail-text {
		@include Univers($UniversBoldCondensed);
	}
	.chat-contact {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    border-right: none;
	}
	.mail-contact {
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    padding: 20px 0 0 0;
	}
	.customer-service-faq {
		&:last-child {
		    margin: 20px 0;
		}
	}
	.tiles-section {
		margin: 0 0 20px 0;
	}
	//Article-page
	.secondary-article-heading {
		font-size: $base-font + 5px;
	}
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
		.primary-content {
			display: inline-block;
		}
		#secondary {
			width: 100%;
    		margin: 15px 0;
    		nav {
    			margin: 0;
    		}
			.secondary-navigation {
				ul {
					font-size: $base-font + 5px;
				}
			}
		}
		.customerservice-name {
			display: none;
		}
		.qa-section {
			.qa-content {
				padding: 25px 15px;
			}
		}
		.col-1 {
			width: 30%;
		    float: left;
		    margin: 0 9% 0 0;
		}
		.col-2 {
			width: 21%;
    		float: left;
    		margin: 0;
		}
		.qa-section {
			.qa-content {
				.answer {
					font-size: $base-font + 1px;
					.sub-heading {
						font-size: $base-font + 1px;
					}
					p {
						font-size: $base-font + 1px;
					}
				}
				.question {
					font-size: $base-font + 2px;
				}
			}
		}
		.mail-contact {
			display: block;
		}
	}
	.merchandise-structure {
		font-size: $base-font + 1px;
		span {
			font-size: $base-font + 1px;
		}
	}
	.restriction-bold {
		font-size: $base-font + 1px;
	}
	.article-heading {
		margin: 0;
		font-size: $base-font + 10px;
	}
	.shipping-heading {
		font-size: $base-font + 1px;
	}
	.contact-us-page .form-wrapper {
	    padding: 18px 10px 53px;
	}
	.pt_article-page {
		&.cs-faq-ww .legacy-sitegen {
			.article-heading {
				margin: 10px 0 0 0;
				padding: 0 0 14px 0;
			}
		}
	}
	//Frequently Asked Questions
	.faq-content {
		.qa-section {
			.qa-desc {
				.qa-content {
					padding: 20px 10px;
					&.active {
						padding: 20px 10px;
					}
				}
			}
		}
	}
	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 24px 0 14px;
		}
		.sub-heading {
			font-size: 13px;
		}
		.para-description {
			p {
				font-size: 14px;
			}
			&.section-1 {
			    padding: 12px 15px 20px;
		 	 	p {
		 	 		font-size: 13px;
		 	 		margin: 0;
		 	 	}
				.list {
					h2 {
						font-size: 13px;
					}
					ul {
						padding: 0 0 0 15px;
					}
				}
			}
			h1 {
				font-size: 22px;
			}
			ul {
				margin: 10px 0 15px 0;
				li {
					margin-bottom: 20px;
				}
			}
			.qa-content {
			    padding: 20px 10px;
			    &.active {
			    	padding: 20px 10px;
			    }
			}
			.answer {
				&.active {
					font-size: $base-font + 1px;
					.customer-service {
						.contact {
						    border-right: 0;
							padding-right: 0;
						}
						.hearing {
							padding-left: 0;
							margin: 10px 0 0 0;
							width: 100%;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}
		.qa-section {
			.qa-content {
				padding: 25px 13px;
			}
		}
		.col-1 {
			width: 50%;
	    	margin: 0;
		}
		.col-2 {
			width: 45%;
		}
	}
	.merchandise-structure {
		width: 100%;
	}
}
