@mixin HomepageStyles() {
    .recommendation-heading span {
        width: auto;
        @include Univers($UniversBoldCondensed);
        text-transform: uppercase;
        font-style: inherit;
    }
}

@mixin cartStyles() {
    .you-may-like {
        background: transparent;
        border: 1px solid $light-gray;

        @media screen and (max-width: 1023px) {
            background: transparent;
            border: 0;
        }
    }
    h2.trending {
        @include Univers($UniversBoldCondensed);
        font-size: $base-font + 6px;
        text-align: center;
        text-transform: uppercase;
        padding: 17px 0 13px;
        margin: 0 0;

        @media screen and (max-width: 1023px) {
            font-size: $base-font + 16px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            text-transform: capitalize;
            text-align: center;
            padding-top: 0;
        }

        @media screen and (max-width: 767px) {
            font-size: $base-font + 12px;
        }
    }

    .product-tile {
        color: $black;
        border: 0;
        min-height: 210px;

        .product-name {
            font-size: $base-font;
            @include Univers($UniversBoldCondensed);
            margin: 3px 3% 6px;
            width: 94%;
            line-height: normal;
        }

        .product-price {
            font-size: $base-font + 1px;
            margin: 5px 0;
            color: $night-rider;
            @include Univers($UniversCondensed);

            .price-standard {
                padding-right: 6px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .recommendations-heading {
            font-size: $base-font + 18px;
        }
        .product-tile {
            .product-name {
                font-size: $base-font + 3px !important;
            }
        }
    }
}

@mixin orderConfirmationStyles() {
    .confirmation-recommendations {
        margin-top: 1rem;
        .you-may-like {
            background: transparent;
            border: 1px solid $light-gray;

            @media screen and (max-width: 1023px) {
                background: transparent;
                border: 0;
            }
        }
        h2.trending {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 6px;
            text-align: center;
            padding: 17px 0 13px;
            margin: 0 0;
            color: $black;
            font-style: normal;
            font-weight: normal;
            text-transform: upppercase;

            @media screen and (max-width: 1023px) {
                font-size: $base-font + 16px;
                padding-bottom: 20px;
                margin-bottom: 20px;
                text-transform: capitalize;
                text-align: center;
                padding-top: 0;
            }

            @media screen and (max-width: 767px) {
                font-size: $base-font + 12px;
            }
        }

        .product-tile {
            color: $black;
            border: 0;

            .product-name {
                @include Univers($UniversBoldCondensed);
                font-size: $base-font;
                margin: 3px 3% 6px;
                width: 94%;
                line-height: normal;
            }

            .product-price {
                margin: 5px 0;
                @include Univers($UniversCondensed);
                font-size: $base-font + 1px;
                color: $night-rider;

                .price-standard {
                    padding-right: 6px;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            .recommendations-heading {
                font-size: $base-font + 18px;
            }
            .product-tile {
                .product-name {
                    font-size: $base-font + 3px !important;
                }
            }
        }
    }
}

.div-trending-section .you-may-like,
.pt_storefront #main .div-trending-section .you-may-like,
.cart-empty .product-slot-recommendation .you-may-like,
.trending-section .you-may-like,
.last-visited-section .last-visited,
.pt_offerscoupons .offers-below-content {
    @include HomepageStyles();
}

.pdp-main {
    .recommendation {
        .recommendations-heading {
            @include Univers($UniversBoldCondensed);
            text-transform: uppercase;
            color: $navy-blue;
        }
        .product-tile {
            .product-name {
                @include Univers($UniversBoldCondensed);
                @media screen and (min-width: 1025px) {
                    max-height: inherit;
                }
            }
        }
        @media screen and (max-width: 1160px) {
            .recommendations {
                max-width: 100%;
            }
            .product-tile {
                float: left;
            }
        }
    }
}

.pt_cart .cart-right-content .cart-recommendations,
.pt_order-confirmation
    .confirmation-right-content
    .confirmation-recommendations {
    @include cartStyles();
}
