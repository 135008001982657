.pt_account {
    .reset-message-notify {
        @include Univers($UniversCondensed);
    }
}
.email-trouble-message {
    @include Univers($UniversRoman);
    font-size: $base-font + 1px;
    color: $white;
    background: $dim-gray;
}
//My Account Overview
#secondary {
    .secondary-navigation {
        max-width: 1024px;
        .main {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 21px;
            color: $navy-blue;
            margin: 12px 0;
        }
        .navigation-links-row {
            li {
                margin: 20px 39px 0;
                letter-spacing: 1px;
                &.navigation-links-row-inner {
                    li {
                        margin: 30px 40px 0;
                        word-spacing: 2px;
                    }
                }
                a {
                    @include Univers($UniversCondensed);
                    font-size: $base-font + 4px;
                    color: $navy-blue;
                    &::after {
                        content: "";
                        display: block;
                        position: relative;
                        width: 0;
                        left: -4px;
                        background: transparent;
                        height: 4px;
                        border-left: solid 4px transparent;
                        border-right: solid 4px transparent;
                        z-index: 2;
                        border-bottom: none;
                        -webkit-transition: width 0.25s ease-in-out;
                        transition: width 0.25s ease-in-out;
                        top: calc(100% - 1px);
                    }
                    &:hover {
                        &::after {
                            width: 100%;
                            background: $navy-blue;
                            border-left: solid 4px #000;
                            border-right: solid 4px #000;
                        }
                    }
                }
            }
        }
    }
}
#wrapper {
    @each $pagename in $pagenames {
        &.#{nth($pagename, 1)} {
            #secondary {
                .secondary-navigation {
                    .secondary-navigation-links {
                        li {
                            &.#{nth($pagename, 2)} {
                                a {
                                    color: $navy-blue;
                                    @include Univers($UniversBoldCondensed);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #secondary {
        .secondary-navigation {
            .secondary-navigation-links {
                padding: 0 20px 20px 20px;
                background: $light-skygrey;
                &.active {
                    display: block;
                }
            }
            .heading {
                padding: 20px;
                background: $light-skygrey;
                @include Univers($UniversCondensed);
                font-size: $base-font + 3px;
                color: $navy-blue;
                letter-spacing: 1.5px;
            }
            .navigation-links-row {
                li {
                    padding: 0;
                    letter-spacing: 1px;
                    a {
                        &::after {
                            display: none;
                        }
                    }
                    &.navigation-links-row-inner {
                        li {
                            padding: 0;
                            word-spacing: normal;
                        }
                    }
                }
            }
        }
    }
}
