.wrapperopacity {
    opacity: 0.5;
    background-color: $white;
}
.return-label-heavy {
    &.ui-dialog {
        .ui-icon-closethick {
            background-image: url(../../../images/close-icon.png);
            width: 16px;
            height: 16px;
            top: 60%;
            left: 45%;
        }
    }
}
.heavy-item-dialog {
    &.ui-dialog-content {
        padding: 0 10% 23% 11%;
        box-sizing: border-box;
    }
}
.heavy-item {
    display: block;
    font-size: $base-font + 12px;
    font-family: $Lora;
    border-bottom: 1px solid $alto;
    padding: 0 0 10px 0;
}
.heavy-message {
    display: block;
    font-size: $base-font + 2px;
    font-family: $Lora;
    margin: 20px 0;
}
.heavy-cancel {
    margin: 0 20px 0 0;
}
@media screen and (max-width: 767px) {
    .heavy-item-dialog {
        &.ui-dialog-content {
            padding: 0 2% 12% 2%;
        }
    }
    .heavy-message {
        font-size: $base-font + 1px;
    }
    .heavy-cancel {
        width: 100%;
        margin: 0 0 20px 0;
    }
    .heavy-continue {
        width: 100%;
    }
    .heavy-item {
        letter-spacing: -1px;
    }
}
//Return Items
.return-items {
    margin: 20px 0 0 0;
    .item-error {
        font-size: $base-font - 1px;
        margin: 5px 0;
        color: $red;
        font-family: $HelveticaMedium;
    }
    .custom-select {
        &.error {
            .selected-option {
                color: $red !important;
            }
        }
    }
    .left-section {
        max-width: 530px;
    }
    .right-section {
        max-width: 380px;
        margin: 6.4% 0 0 0;
        &.secondary-details {
            margin: 7.4% 0 0 0;
        }
    }
    .return-order {
        font-size: $base-font + 12px;
        font-family: $Lora;
        margin: 10px 0 19px 0;
    }
    .return-message {
        font-size: $base-font + 2px;
        font-family: $Lora;
        line-height: 22px;
        margin-right: 16%;
    }
    .return-sub-header {
        font-size: $base-font + 2px;
        font-family: $Lora;
        margin-top: 7px;
    }
    button {
        &.submit-cancel,
        &.return-cancel {
            font-size: $base-font + 2px;
            @include WorkSansSemiBold($WorkSans);
            text-transform: uppercase;
            background: transparent;
            color: $black;
            margin-left: 15px;
        }
        &.return-list,
        &.return-submit {
            @include WorkSansSemiBold($WorkSans);
            padding: 17px 34px;
            background: $navy-blue;
            font-size: $base-font + 2px;
            text-transform: uppercase;
        }
    }
    .return-line-items {
        .heading {
            font-family: $Lora;
            font-size: $base-font + 12px;
            margin: 54px 0 0 0;
            padding: 0 0 13px 0;
            text-transform: inherit;
        }
    }
    .list-tems {
        padding: 30px 4px 10px;
        padding-right: 0;
        &.non-eligible-item {
            padding: 20px 33px;
        }
    }
    .non-eligible-list {
        .cart-row {
            .col-1 {
                width: 80%;
                max-width: 640px;
            }
            .col-2 {
                margin-top: 62px;
            }
        }
    }
    .returned-list {
        .non-eligible-item {
            .cart-row {
                .col-2 {
                    margin-top: 54px;
                }
                .col-1 {
                    width: 54%;
                    max-width: 457px;
                }
            }
        }
    }
    input[type="checkbox"] {
        margin: 0 15px 0 0;
    }
    .returned-list {
        .line-item-quantity {
            &.returned {
                width: 22%;
                max-width: 200px;
                margin-top: 60px;
                .label {
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    @include WorkSansSemiBold($WorkSans);
                    font-size: $base-font;
                }
                .value {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font;
                }
            }
        }
    }
    .line-item-quantity {
        display: inline-block;
    }
    .cart-row {
        width: 96.7%;
        .col-1 {
            width: 50%;
            max-width: 465px;
            .item-details {
                .returns-check {
                    margin: 0 10px 0 0;
                }
                .label {
                    margin-right: 3px;
                    @include WorkSansSemiBold($WorkSans);
                    margin-bottom: 9px;
                }
                .value {
                    margin-bottom: 9px;
                    @include WorkSansMedium($WorkSans);
                }
            }
        }
        .col-2 {
            margin-top: 50px;
            max-width: 120px;
            width: 14%;
            .item-total {
                .price-total {
                    @include WorkSansMedium($WorkSans);
                    font-size: $base-font + 2px;
                    &.price-promotions {
                        color: $radical-red;
                    }
                }
            }
        }
        .column-combine {
            width: 38%;
            max-width: 330px;
            margin-top: 54px;
            .col-3 {
                width: 47%;
                max-width: 125px;
            }
            .col-4 {
                width: 53%;
                max-width: 160px;
                float: right;
                margin-right: 10px;
            }
            .current_item {
                .selection-list {
                    li {
                        padding: 9px 10px 9px 15px;
                        font-size: $base-font - 1px;
                        @include WorkSansMedium($WorkSans);
                    }
                }
            }
            .selected-option {
                font-size: $base-font - 1px;
                @include WorkSansMedium($WorkSans);
            }
        }
    }
}
//Return Item Confirmation
.return-confirmation {
    .return-order {
        margin: 11px 0 24px 0;
    }
    .return-list-download {
        border: 0;
        background: $navy-blue;
        color: $white;
        letter-spacing: 1px;
        @include WorkSansSemiBold($WorkSans);
        padding: 17px 33px;
        font-size: $base-font + 2px;
    }
    .return-instructions {
        max-width: 750px;
        width: 100%;
        margin: 29px 2px;
        font-family: $Lora;
        font-size: $base-font + 2px;
        h1 {
            @include WorkSansSemiBold($WorkSans);
            font-size: $base-font + 2px;
            margin-bottom: 5px;
        }
    }
    .submission-text {
        margin-bottom: 33px;
    }
    ol {
        padding: 0 0 0 50px;
        margin: 0 0 55px 0;
        line-height: 25px;
        span {
            width: 100%;
            display: inline-block;
            line-height: 25px;
            padding-left: 22px;
            a {
                color: $torea-bay;
            }
        }
    }
    .item-returned {
        p {
            margin: 0 0 30px 0;
            line-height: 25px;
        }
    }
    .return-prod-section {
        width: 100%;
        display: table;
        margin-bottom: 15px;
        margin-left: 10px;
        margin-top: 39px;
        .return-prodimage-section {
            width: 11%;
            display: table-cell;
            a {
                display: inline-block;
                max-width: 60px;
            }
        }
        .return-prod-quantity {
            width: 88%;
            display: table-cell;
            vertical-align: middle;
            font-size: $base-font + 2px;
        }
    }
}
@media screen and (max-width: 1024px) {
    .return-items {
        .custom-cart-qty,
        .custom-return-reson {
            &.custom-select {
                select {
                    position: absolute;
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                    filter: alpha(opacity=0);
                    -moz-opacity: 0;
                    -khtml-opacity: 0;
                    opacity: 0;
                    padding: 0;
                }
                &.current_item {
                    .selection-list {
                        display: block;
                        z-index: 2;
                    }
                }
                .selected-option {
                    display: block;
                }
            }
        }
    }
}
@media screen and (max-width: 1023px) {
    .return-items {
        padding: 20px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding-top: 0;
        .returned-list {
            .line-item-quantity {
                display: block;
                &.returned {
                    display: none;
                }
            }
        }
        .return-header {
            button {
                &.submit-cancel {
                    display: none;
                }
            }
        }
        .left-section {
            width: 60%;
        }
        .right-section {
            width: 40%;
            &.secondary-details {
                width: 50%;
            }
            button {
                &.return-list {
                    padding: 14px 31px;
                    font-size: $base-font + 3px;
                }
            }
        }
        .cart-row {
            width: 95%;
            .column-combine {
                margin: 0;
                width: 36%;
                .col-3 {
                    width: 100%;
                    float: right;
                }
                .col-4 {
                    width: 100%;
                    margin-top: 20px;
                    margin-right: 0;
                }
            }
        }
    }
    //Return Item Confirmation
    .return-confirmation {
        .return-order {
            margin: 5px 0 23px 0;
        }
        .return-list-download {
            padding: 17px 33px;
        }
        .return-instructions {
            margin: 31px 3px;
            h1 {
                margin-bottom: 5px;
            }
        }
        .submission-text {
            margin-bottom: 32px;
        }
        ol {
            padding: 0 0 0 50px;
            margin: 0 0 55px 0;
            span {
                padding-left: 22px;
            }
        }
        .item-returned {
            p {
                margin: 0 0 30px 0;
            }
        }
        .return-prod-section {
            margin-bottom: 15px;
            margin-left: 15px;
            margin-top: 0;
            .return-prodimage-section {
                width: 13.5%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .return-items {
        padding: 10px;
        margin: 15px 0 0 0;
        padding-top: 0;
        .return-header {
            button {
                &.submit-cancel {
                    display: block;
                }
            }
        }
        .left-section {
            width: 100%;
            max-width: 100%;
        }
        .non-eligible-list {
            .cart-row {
                .col-1 {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        .returned-list {
            .non-eligible-item {
                .cart-row {
                    .col-1 {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
        .right-section {
            width: 100%;
            float: left;
            text-align: center;
            max-width: 100%;
            button {
                width: 100%;
                &.return-list {
                    float: none;
                    font-size: $base-font + 1px;
                }
                &.submit-cancel {
                    font-size: $base-font + 1px;
                    line-height: normal;
                    margin: 20px 0 0 0;
                }
            }
            &.secondary-details {
                width: 100%;
                button {
                    width: 100%;
                    &.return-list {
                        float: none;
                        font-size: $base-font + 1px;
                    }
                    &.submit-cancel {
                        font-size: $base-font + 1px;
                        line-height: normal;
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
        .return-order {
            margin: 0 0 15px 0;
        }
        .return-message {
            font-size: $base-font + 1px;
            margin-right: 0;
        }
        .return-sub-header {
            font-size: $base-font + 1px;
            color: $red;
        }
        .list-tems {
            padding: 15px 0;
            &.non-eligible-item {
                padding: 15px 0;
            }
        }
        .return-line-items {
            .heading {
                margin: 10px 0 0 0;
            }
        }
        .cart-row {
            width: 100%;
            .col-1 {
                width: 100%;
                .item-image {
                    width: 40%;
                }
                .item-details {
                    width: 60%;
                }
            }
            .col-2 {
                display: none;
            }
            .column-combine {
                width: 100%;
                margin: 15px 0 0 0;
                .col-4 {
                    .selected-option {
                        border-left: 1px solid transparent;
                        border-radius: 0 3px 3px 0;
                    }
                }
                .selected-option {
                    font-size: $base-font - 1px;
                }
                .current_item {
                    .selection-list {
                        li {
                            font-size: $base-font - 1px;
                            padding: 9px 10px;
                        }
                    }
                }
            }
        }
    }
    //Return Item Confirmation
    .return-confirmation {
        .return-order {
            margin: 5px 0 23px 0;
        }
        .return-instructions {
            margin: 17px 4px;
            h1 {
                margin-bottom: 5px;
            }
        }
        .submission-text {
            margin-bottom: 25px;
        }
        ol {
            padding: 0 0 0 15px;
            margin: 0 0 25px 0;
            span {
                padding-left: 0;
            }
        }
        .return-prod-section {
            margin-left: 0;
            .return-prodimage-section {
                width: 30%;
            }
            .return-prod-quantity {
                font-size: $base-font;
            }
        }
    }
}
