.search-suggestion-wrapper {
    z-index: 23;
    padding: 0;
    .suggested-phrase {
        font-size: $base-font + 4px;
        color: $black;
    }
    @media screen and (min-width: 1024px) {
        &.full {
            .product-suggestions {
                padding-left: 15px;
            }
        }
    }
    @media screen and (max-width: 1023px) {
        z-index: 23;
        padding-top: 0;
    }
    .hitgroup {
        .hit {
            margin-left: 10px;
            @include Univers($UniversRoman);
            font-size: $base-font + 1px;
            color: $navy-blue;
            .searched-key {
                @include Univers($UniversBoldCondensed);
                color: $navy-blue;
            }
        }
        .parent-category {
            @include Univers($UniversRoman);
        }
    }
}
.product-suggestion {
    padding: 5px 0 4px;
    border-left: 5px solid transparent;
    &:hover {
        background-color: rgba(0, 41, 83, 0.15);
        border-left: 5px solid $navy-blue;
    }
    //for ipad in portrait mode
    @media screen and (min-width: 768px) and (max-width: 1023px) {
        border-left: none;
        &:hover {
            border-left: none;
        }
    }
    //for ipad in landscape mode
    @media screen and(width:1024px) and (orientation: landscape) {
        border-left: none;
        &:hover {
            border-left: none;
        }
    }
    .product-image {
        img {
            float: none;
            margin: 0 0 0 8px;
            //for ipad in portrait mode
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                margin: 0;
            }
            //for ipad in landscape mode
            @media screen and(width:1024px) and (orientation: landscape) {
                margin: 0;
            }
        }
    }
    .product-details {
        .product-name {
            @include Univers($UniversBoldCondensed);
            font-size: $base-font + 2px;
            color: $navy-blue;
            text-transform: uppercase;
        }
        .product-price {
            @include Univers($UniversCondensed);
            font-size: $base-font + 1px;
            span {
                &.subunit {
                    left: -4px;
                }
            }
        }
    }
}
.search-phrase {
    @include Univers($UniversRoman);
    font-size: $base-font + 2px;
    color: $navy-blue;
    a {
        span {
            @include Univers($UniversBold);
            color: $navy-blue;
        }
    }
    .completed {
        @include Univers($UniversRoman);
        .original {
            @include Univers($UniversBoldCondensed);
        }
    }
}
