$white-smoke: #f4f4f4;
$white-smokedip: #f5f5f5;
$navy-blue: #002953;
$red: #891635;
$KS-dark-grey: #7d7d7d;
$KS-sky-blue: #014f9f;
$KS-light-blue: #2d94fe;
$KS-Btn-radius: 50px;
$KS-Btn-half-radius: 25px;
$mobile-menu-bar-color: #004a8d;
