// Order History - Order Details Styling
.pt_order,
.confirmation {
    .actions {
        padding: .5em 1%;
        a {
            margin-right: 2rem;
        }
    }
}
.catalog-item-number {
	@include Univers($UniversCondensed);
}
.pt_order {
	.primary-content {
		width: 100%;
    	float: left;
	}
	
	
	#secondary{ 
		.secondary-navigation{
			@media screen and (min-width: 768px) {	
				margin-bottom: 37px;
			}
		}
	}
	
	.order-payment-summary {
		.actions {
			float: left;
		}
	}
	
	.internation-shipment-method {
		@include Univers($UniversCondensed);
		font-size: $base-font + 3px;
		background: $light-black-two;
		color: $white;
		text-align: center;
		line-height: 18px;
    	padding: 6px 0 8px;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
		
		@media screen and (max-width: 767px) {
		    text-align: left;
		    padding: 6px 9px 8px;
		    
		    .shipping-method {
		    	margin-right: 15px;
		    }
		}
	}
	
	.order-details-section {
		.qa-section {
    		max-width: 1020px;
    		margin: 0 auto;
    		display: block;
    		
    		.question-title {
    			text-transform: inherit;
    			padding: 25px 40px;
       		}
    		
    		.qa-content {
    			padding: 25px 40px;
 	 			@include Univers($UniversBoldCondensed);
    			font-size: $base-font + 1px;
 	 			.question {
 	 				letter-spacing: 1.5px;
 	 			}
 	 			
 	 			.answer {
 	 				font-size: $base-font + 3px;
 	 				padding: 18px 0 0 0;
 	 			}
    		}
    	}
	}
	
	.orderdetails {
    	margin: 25px auto 60px;
    	
    	.heading {
			@include Univers($UniversBoldCondensed);
			width: 100%;
			margin-bottom: 10px;
			text-transform: capitalize;
			display: inline-block;
		}
    	   	
    	.actions {
		    padding: 0;
		    margin-top: 35px;
		    
		    a {
				padding: 16px 36px;
				text-transform: uppercase;
				@include Univers($UniversBoldCondensed);
				letter-spacing: 2.5px;
				font-size: $base-font + 3px;
				border-color: $navy-blue;
				color: $navy-blue;
				border-radius: 3px;
		    }
    	}
    	
    	.optioninfo {
    		.option-info {
    			font-family: $Helvetica;
    			font-size: $base-font;
    			color: $red;
    			
    			span {
    				text-transform: capitalize;
    				letter-spacing: 0;
    			}
    		}
    	}
    	
    	.order-information {
    		padding: 5px 0 0 0;
    		
    		.order-print {
    			h1 {
    				@include Univers($UniversCondensed);
    				font-size: $base-font + 13px;
    				margin: 0 0 10px 0;
    			}
    		}
    		
    		.order-status {
    			width: auto;
    			display: inline-block;
    			margin: 13px 0 0 3px;
    			
    			.order-status {
    				display: none;
    			}
    			
	    		.mail-box {
					background: url('../../../images/mail-black.svg') no-repeat;
					float: left;
				}
				
				.receipt-message {
					@include Univers($UniversRoman);
					font-size: $base-font + 2px;
					margin: 0 0 0 8px;
					float: left;
					line-height: 14px;
				}
			}
    	}
    	
    	.order-detail-section {
    		padding: 16px;
    		
    		.section {
    			width: 33%;
    			
    			.heading {
    				margin: 0 0 10px 0;
    				text-transform: capitalize;
    			}
    			
    			.value {
    				margin: 0 0 10px 0;
    				display: inline-block;
    				width: 100%;
    			}
    		}
    		
    		&.order-detail-response {
    			.order-status {
    				width: 25%;
    			}
    			
    			span {
    				&.img {
    					float: right;
    					margin-left: 10px;
    				}
    			}
    		
    			.order-payment-instruments {
    				width: 32%;
    			
    				.inner-block {
    					max-width: 200px;
    				}
    			}
    			
    			.order-coupon-reward {
    				width: 20%;
    			}
    		
    			.order-billing {
    				width: auto;
    			}	
    		}
    		
    		.order-payment-instruments{
    			label{
    				line-height: 18px;
    			}
    			&.section{
    				width: 31.6%;
    			}
    		}
    	}
    	
    	.order-shipments {
    		.order-shipment-table {
				&.e-gift-card {
					.order-shipment-section {
						.method {
						    width: 86%;
							float: left;
							
							@media screen and (max-width: 767px) {
								width: 100%;
							}
							
							.shipment-method {
							    max-width: 700px;
							    
							    @media screen and (max-width: 767px) {
									max-width: 100%;
									margin-left: 0;
								}
								
								.value {
									padding-bottom: 12px;
									padding-left: 5px;
								}
							}
						}
					}
					
					@media screen and (min-width: 768px) and (max-width: 1023px) {
						.line-items {
							.line-item {
								.col-1 {
									.item-details {
										.line-item-quantity {
											display: none;
											
											&.gift-card {
												display: block;
											}
										}
									}
								}
							}
						}
					}
				}
				
				@media screen and (min-width: 768px) and (max-width: 1023px) {
					&.shipping-exception {
						.order-shipment-section {
							.order-shipment-address {
								width: 82%;
							}
							.order-shipping-status {
								width: 18%;
							}
						}
					}
				}
				
				&.gift-card {
					.order-shipment-details {
						.method {
						    width: 20%;
							float: left;
							max-width: 160px;
							
							@media screen and (max-width: 767px) {
								width: 100%;
								max-width: 100%;
							}
						}
						
						@media screen and (max-width: 1023px) {
							.order-shipment-address{
								width: 82%;
							}
							.order-shipping-status{
								width: 18%;
							}
						}
						
						.order-shipment-section {
						    width: 80%;
							float: left;
							
							@media screen and (max-width: 767px) {
								width: 100%;
							}
						}
					}
					
					@media screen and (min-width: 768px) and (max-width: 1023px) {
						.line-items {
							.line-item {
								.col-1 {
									.item-details {
										.line-item-quantity {
											display: none;
											
											&.gift-card {
												display: block;
											}
										}
									}
								}
							}
						}
					}
					
					.order-shipment-details {
						.method {
						    font-size: $base-font + 1px;
						    letter-spacing: 1px;
							
							@media screen and (max-width: 767px) {
								font-size: $base-font - 1px;
							}
							
							.shipment-method {
								.value {
									@media screen and (min-width: 768px) {
										padding-left: 5px;
									}
								}
							}
						}
					}					
				}
			}	
    		
    		.shipmentnumber {
				padding: 0 0 14px 2px;
				border-bottom: 1px solid $alto;
				
				h2 {
					padding: 0;
					border: 0;
					width: auto;
					float: left;
					@include Univers($UniversCondensed);
					font-size: $base-font + 13px;
				}
				
				.trackingnumber {
					@include Univers($UniversBoldCondensed);
					font-size: $base-font + 1px;
					text-transform: uppercase;
					line-height: 30px;
					letter-spacing: 1.5px;
					
					.value {
						font-family: $HelveticaMedium;
						color: $navy-blue;
					}
				}
			}
    			
    		.order-shipment-section {
    			@include Univers($UniversCondensed);
    			font-size: $base-font + 2px;
				text-transform: capitalize;
    			
				.heading {
					@include Univers($UniversBoldCondensed);
				}
    			
    			.method, .order-shipping-status {
    				width: 15%;
    				float: left;
    				
    				.shipment-method {
    					max-width: 100px;
    				}
    			}
    			.order-shipping-status {
    				width: 14%;
    				.heading{
    					margin-left: 0;
    				}
    			}
    			
    			.order-shipment-address {
    				width: 86%;
    				float: left;
    				
    				.shipment-address {
    					max-width: 730px;
    				}
    			}
    		}
    		
    		.line-item {
    			width: 100%;
			    display: inline-block;
			    border-top: 0px;
			    padding: 20px;
			    box-sizing: border-box;
			    -webkit-box-sizing: border-box;
 	 			-moz-box-sizing: border-box;
			    
    			.col-1 {
    				max-width: 500px;
    				width: 50%;
    				margin: 0;
    				float: left;
    				
    				.line-item-price {
    					display: none;
    				}
    				
    				.item-image {
					    width: 100px;
					    text-align: center;
					    
					    @media screen and (max-width: 767px) {
					    	width: 37%;
					    }
    				}
    				
    				.item-details {
    					float: left;
					    max-width: 66%;
					    padding-left: 38px;
					    box-sizing: border-box;
					    -webkit-box-sizing: border-box;
 	 					-moz-box-sizing: border-box;
					    font-size: $base-font - 1px;
					    
					     @media screen and (max-width: 1024px) {
					    	width: 63%;
					    	max-width: 63%;
					    }
					    
    					.giftmessage {
    						word-break: break-word;
    					}
    				}
    				
    				.quick-order-badge {
    					display: none;
    					
    					@media screen and (max-width: 767px) {
    						display: block;
    						width: 100% !important;
    						margin: 0;
    					}
    				}
    			}
    			
    			.col-2 {
    				max-width: 196px;
    				width: 21.6%;
    				margin: 54px 0 0 0;
    				float: left;
    				
    				.line-item-quantity {
    					@include Univers($UniversCondensed);
    					font-size: $base-font;
    					text-transform: uppercase;
    					
    					span {
    						width: auto;
    						display: inline-block;
    						
    						&.label {
	    						@include Univers($UniversBoldCondensed);
	    					}
    					}
    				}
    			}
    			
    			.col-3 {
    				max-width: 180px;
    				width: 16.7%;
    				margin: 54px 0 0 0;
    				float: left;
    				
    				.label {
    					display: none;
    				}
    				
    				.line-item-price {
    					@include Univers($UniversCondensed);
    					font-size: $base-font + 3px;
    					display: inline-block;
    					
    					&.discount-price {
    						color: $red;
    					}
    				}
    			}
    			
    			.col-4 {
				    text-align: right;
				    width: 30%;
				    @include Univers($UniversBoldCondensed);
				    font-size: $base-font;
    				text-transform: uppercase;
    				letter-spacing: 1.5px;
    				float: left;
    				
    				&.quick-order-item {
    					margin: 0;
    					
    					.details-review {
    						margin-top: 15px;
    						display: inline-block;
    					}
    				}
    				
    				.buy-again {
    					margin-top: 9px;
    				}
    				
    				.quick-order-badge {
    					&.quick-order-badge-confirmation {
    						width: 95px !important;
						    text-align: right !important;
						    float: right !important;
    					}
    					
    					.quick-order-badge-link {
    						padding-right: 18px;
							margin-bottom: 5px;
							
							.heading{
								display: block;
							}
    					}
    				}
    			}
    		}
    	}
    	
    	.mini-address-name {
    		margin: 0 0 5px 0;
    	}
    	
    	.mini-address-location {
	    	address {
			    font-style: normal;
				line-height: 25px;
	    	}
    	}
	}
	
	//order History page
	.order-history {
		margin: 30px auto 20px;
		@media screen and (max-width: 767px){
			margin-top: 0;
		}
		.subheading {
			font-size: $base-font + 2px;
		}
		.top-content {
			h1 {
				font-size: $base-font + 15px;
			}
		}
		
		.order-history-table {
			.order-history-header {
				@include Univers($UniversBoldCondensed);
			}
			
			.order-list-wrapper {
				@include Univers($UniversCondensed);
				
				.order-list {
				    padding: 30px 20px 38px;
				}
				
				.col-last {
					padding-top: 2px;
				}
				.button-text {
					@include Univers($UniversBoldCondensed);
					&.view-detials,&.return {
						border-radius: 0;
						border: none;
						background: transparent;
						&:hover{
							border-radius: 0;
							border: none;
							background: transparent;
						}
					}
			    }
			}
		}
	}
}
.confirmation-left-section:not(.ot) {
    max-width: 840px;
    float: left;
    margin-top: 20px;
    width: 75%;
    
    h1 {
    	@include Univers($UniversRoman);
    	font-size: $base-font + 12px;
    	margin: 0;
    	@media screen and (max-width: 1023px) {
    		font-size: 24px;
    	}
    }
    
    .specialmessaging {
		clear: both;
	}
}
.confirmation-message {
    border-bottom: 1px solid $alto;
    padding: 25px 0 20px 0;
}
.order-confirmation-details {
    .order-print {
    	width: 100%;
	    display: inline-block;
	    padding: 30px 0;
	    
	    .order-number {
	    	float: left;
	    }
	    
	    .print-page {
	    	border: none;
		    float: right;
		    padding: 10px 35px;
		    @include Univers($UniversCondensed);
		    font-size: $base-font + 1px;
		    background-color: $white;
    		color: $navy-blue;
    		border-radius: 0;
	    }
    }
}
.orderdetails {
	.notshipped-message {
		@include Univers($UniversRoman);
		font-size: $base-font + 2px;
		color: $red;
		border-top: 1px solid $alto;
		width: 100%;
		display: inline-block;
		padding: 19px 0 0 0;
		margin: 10px 0 0 0;
	}
	
	.login-create-account {
		h1 {
			margin: 0 0 20px 0;
		}
		
		.login-box-content {
			padding: 30px;
		    box-sizing: border-box;
		    -webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;
		    background: $light-skygrey;
		    border-top: 1px solid $alto;
		}
		
		.login-box-left-content {
			width: 55%;
			display: inline-block;
			vertical-align: top;
    		
    		.inner-block {
    			max-width: 385px;
    			@include Univers($UniversRoman);
    			font-size: $base-font + 1px;
    			
    			h2 {
	    			text-transform: inherit;
				    margin: 0 0 20px 0;
				    @include Univers($UniversBoldCondensed);
					font-size: $base-font + 2px;
					border-top: none;
					padding-top: 0;
					color: #000;
				}
				h3 {
	    			text-transform: inherit;
				    @include Univers($UniversBoldCondensed);
					font-size: $base-font + 2px;
					margin-top: 0;
				}
    		}
		}  
		
		.login-box-right-content {
			width: 44%;
			display: inline-block;
		    
		    .inner-block {
    			max-width: 295px;
    			
    			&.make-label-absolute {
	    			.form-row {
	    				position: relative;
	    			}
	    			
	    			label {
	    				font-weight: normal;
					    font-size: $base-font;
					    padding: 0;
					    position: absolute;
					    -webkit-transform: translate(5px, 20px);
					    -ms-transform: translate(5px, 20px);
					    transform: translate(5px, 20px);
					    left: 12px;
					    margin: 0;
					    top: -3px;
					    width: auto;
					    color: $dim-gray;
					    z-index: 1;
					    -webkit-transition: -webkit-transform .3s ease,font-size .3s,color .3s;
					    -ms-transition: -webkit-transform .3s ease,font-size .3s,color .3s;
					    transition: transform .3s ease,font-size .3s,color .3s;
					    
					    &.input-focus {
					    	-webkit-transform: translate(5px, 11px);
					    	-ms-transform: translate(5px, 11px);
    						transform: translate(5px, 11px);
					    }
					    
					    span {
					    	@include Univers($UniversBoldCondensed);
						    text-transform: capitalize;
						    
					    	&.required-indicator {
					    		display: none;
					    	}
					    }
	    			}
	    			
	    			.field-wrapper {
	    				box-sizing: border-box;
	    				-webkit-box-sizing: border-box;
	 	 				-moz-box-sizing: border-box;
					    margin-bottom: 20px;
					    width: 100%;
					    
	    				input[type="text"], input[type="number"], input[type="password"], input[type="date"] textarea {
		    				padding: 20px 15px 14px 15px;
						    height: auto;
						    border-radius: 0;
					    }
					    
					    .pwd-show {
						    @include Univers($UniversBoldCondensed);
					    }
	    			}
    			}
    		}
		}  
	}
}
.plcc-user {
	p {
		margin: 0 0 15px 0;
	}
	
	ul {
		margin: 0;
		@media screen and (max-width: 767px) {
			padding: 0 0 0 16px;
		}
		
		li {
			list-style: inherit;
		}
	}
}
.order-detail-section {
	width: 100%;
    display: inline-block;
    padding: 35px 45px;
    box-sizing: border-box;
    background: $white;
    box-shadow: -2px 6px 30px 8px $white-smoke;
    margin: 35px 0;
    
    .section {
    	width: 25%;
    	float: left;
    	
    	.inner-block {
    		max-width: 155px;
    		@include Univers($UniversCondensed);
    		font-size: $base-font + 2px;
    		word-wrap: break-word;
    		
    		.inner-block-section {
    			margin: 25px 0 0 0;
    			&.method{
    				margin-top: 15px;
    			}
    		}
    	}
    }
}
.order-shipments {
	.order-shipment-details {
		h2 {
			@include Univers($UniversRoman);
			font-size: $base-font + 16px;
			margin: 0;
			text-transform: capitalize;
			padding: 0 0 15px 0;
    		border-bottom: 1px solid $alto;
    		font-weight: normal;
    		border-top: none;
    		color: $black;
		}
		
		.heading {
			margin: 15px 0 10px 5px;
		}
		
		.address {
			@include Univers($UniversCondensed);
			font-size: $base-font + 2px;
			width: 100%;
			padding-bottom: 15px;
			margin-left: 4px;
			    
			.default {
				border-right: 1px solid $black;
			}
			
			div {
				width: auto;
				display: inline-block;
				text-transform: capitalize;
    			padding-right: 5px;
			}
		}
	}
}
.order-payment-summary {
	padding: 42px 11.2% 30px 35px;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    background: $white;
    box-shadow: -2px 6px 30px 8px $white-smoke;
    
    .order-detail-summary {
    	width: 50%;
	    float: right;
	    max-width: 285px;
	    
	    .order-detail {
		    width: 100%;
			display: inline-block;
			padding: 0 0 12px 0;
			
			.label {
				width: 60%;
				float: left;
				text-transform: capitalize;
				@include Univers($UniversCondensed);
				font-size: $base-font + 3px;
			}
			
			.value {
				width: 40%;
				float: right;
				text-align: right;
				@include Univers($UniversCondensed);
				font-size: $base-font + 3px;
			}
			
			&.order-total {
				border-top: 1px solid $alto;
				padding: 17px 0 10px 0;
				
				.label {
					@include Univers($UniversBoldCondensed);
					font-size: $base-font + 12px;
				}
				
				.value {
					@include Univers($UniversBoldCondensed);
					font-size: $base-font + 7px;
				}
			}
			
			&.discount {
				color: $red;
			}
			
			&.order-saving {
				.label {
					@include Univers($UniversRoman);
					font-size: $base-font + 2px;
				}
				.value {
					@include UniversBold($UniversCondensed);
				}
			}
		}
    }
    
    .actions {
    	width: 50%;
	    padding: 0;
	    display: inline-block;
	    margin: 20px 0;
	    
	    a {
    	    margin: 0;
		    padding: 16px 40px;
		    border: 1px solid;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    @include Univers($UniversRoman);
		    font-size: $base-font + 3px;
	    }
    }
}
.order-shipment-table {
	.line-items {
		width: 100%;
	    display: inline-block;
    	
    	.line-item {
    		width: 100%;
	    	display: inline-block;
	    	border-top: 1px solid $alto;
	    	padding: 20px;
    		box-sizing: border-box;
    	}
    	
		.personalized-message {
			font-size: $base-font - 1px;
			font-family: $HelveticaBold;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 0 0 15px 0;			
		}
	
		.giftcard {
			margin: 0 0 15px 0;
			
			.label {
				font-size: $base-font;
			    font-family: $HelveticaBold;
			    margin: 0 3px 0 0px;
			    letter-spacing: 1px;
			}
			
			.value {
				font-size: $base-font;
			    font-family: $Helvetica;
			    text-transform: capitalize;
			}
		}
		
		.giftmessage {
			font-size: $base-font;
			margin: 0 0 15px 0;
			word-break: break-all;
		}	
    	
    	.column {
			width: 33.33%;
			float: left;
			box-sizing: border-box;
			margin: 0 0;
			
			&.col-1 {
			    max-width: 555px;
    			width: 70%;
	   			
	   			.item-image {
					float: left;
					width: 100px;
					text-align: center;
				}
				
				.item-details {
					float: left;
					max-width: 66%;
				    padding-left: 35px;
					box-sizing: border-box;
					font-size:  $base-font - 1px;
					
					.name {
						@include Univers($UniversCondensed);
						font-size:  $base-font + 3px;
						margin: 1px 0 6px;
					}
					.sku {
						@include Univers($UniversCondensed);
						margin-bottom: 13px;
					}
					.attribute {
						margin-bottom: 4px; 
						
						.label {
						    margin-right: 3px;
						    @include UniversBold($UniversBoldCondensed);
						}
					}
					.price-promotion {
						.price-sales {
							color: $red;
						}
						.price-standard {
							margin: 0 5px 0 0;
							text-decoration: line-through;
						}
					}
					
					.product-option {
						margin-bottom: 9px;
					    letter-spacing: 1px;
					    text-transform: uppercase;
					    font-family: $HelveticaBold;
					}
					
					.optioninfo {
						@include Univers($UniversRoman);						
						
						.hemmable{
							margin-bottom:15px;
							margin-bottom:10px;
						}
						span {
							letter-spacing: 1px;
							font-size:13px;
							text-transform:uppercase;
							font-weight:normal;
							
							
		    				&.monogram, &.hemming {
		    					text-decoration: underline;
		    					margin: 10px 0;
		    					display: inline-block;
								cursor: pointer;
								text-transform: uppercase;
		    				}
						}
						
						.optionheading {
							text-transform: uppercase;
							font-family: $UniversBoldCondensed
						}
						
						.optionvalue {
							font-family: $HelveticaMedium;
							line-height: 20px;
						}
					}
				}
				.line-item-price {
					display: none;
					margin-top: 10px;
				}	
			}
			
			&.col-2 {
				max-width: 170px;
	    		width: 20%;
	    		margin: 59px 0 0 0;
			}
			
			&.col-3 {
				max-width: 95px;
	    		width: 10%;
	    	}
		}
	}
}
.pt_order-confirmation {
	.heading {
		@include Univers($UniversBoldCondensed);
		width: 100%;
		display: inline-block;
		text-transform: uppercase;
	}
	
	.receipt-message {
		@include Univers($UniversRoman);
		font-size: $base-font + 1px;
	}
	
	.line-item-price,.discount-price {
		@include Univers($UniversCondensed);
		font-size: $base-font + 3px;
	}
	
	address {
		@include Univers($UniversCondensed);
		font-size: $base-font + 2px;
	}
	
	.profile-summary {
		.profile-label {
			@include Univers($UniversBoldCondensed);
			font-size: $base-font + 1.5px;
			padding: 0 5px 0 0;
			text-transform: uppercase;
		}
		.profile-value {
			@include Univers($UniversRoman);
			font-size: $base-font + 1px;
		}
		.edit-profile {
			color: $navy-blue;
			@include Univers($UniversCondensed);
			font-size: $base-font + 2px;
		}
	}
	
	.login-box-right-content {
		button {
			width: 100%;
			background-color: $navy-blue;
			color: $white;
			border-color: $navy-blue;
			letter-spacing: 1px;
		}
		
	}
	.order-shipments {
		.heading {
			@include Univers($UniversBoldCondensed);
		}
		.line-items {
			.label {
				@include Univers($UniversBoldCondensed);
			}
			
			.qty-value {
				@include Univers($UniversCondensed);
			}	
			.value {
				@include Univers($UniversCondensed);
			}
			.mini-address-name {
				@include Univers($UniversBoldCondensed);
			}
		}
	}
	.order-payment-summary {
		.order-detail-summary {
			.discount {
				.label {
					@include UniversBold($UniversRoman);
					font-size: $base-font + 2px;
				}
			}
			.order-detail {
				.label {
					.shippingoverlay-link {
						background: url(../../../images/tips.svg) no-repeat;
						width:16px;
						height:16px;
						background-size:cover;
						vertical-align: bottom;
					}
				}
			}
		}
	}
	.additional-offers {
		h2 {
			@include Univers($UniversRoman);
			font-size: $base-font + 12px;
			font-weight: normal;
		}
	}
	.option-info {
		@include Univers($UniversRoman);
		font-size: $base-font + 1px;
	}
	.order-detail-section{
		margin-top: 9px;
    	.payment-type{
    		display: none;
    	}
    	.login-box-content{
			margin-bottom: 9px;
		}
	}
	.discount-price {
		color: $red;
	}
}
@media screen and (max-width: 1023px) {
	.pt_order {
		#secondary {
			display: block;
		}
		
		.order-details-section {
			margin: 0 20px;
		}
		
		.orderdetails {
			margin: 30px auto;
			
	    	.order-detail-section {
	    		padding: 35px 25px 40px 25px;
	    		
	    		&.order-detail-response {
	    			.order-status {
	    				width: 24%;
	    			}
	    		
	    			.order-payment-instruments {
	    				width: 30%;
	    			
	    				.inner-block {
	    					max-width: 190px;
	    				}
	    			}
	    		
	    			.order-billing {
	    				width: auto;
	    			}
	    			
	    			.order-coupon-reward {
	    				width: 23%;
	    			}
	    		}
	    	}
	    	
	    	.order-shipments {
	    		.line-item {
	    			.col-1 {
	    				width: 60%;
	    				
	    				.callout-message {
	    					&.hide-tablet {
	    						display: none;
	    					}
	    				}
	    				
	    				.hide-tablet {
	    					
	    					.label {
    						    margin-right: 3px;
    						    @include Univers($UniversBoldCondensed);
							    letter-spacing: 1px;
							    text-transform: uppercase;
							    font-size: $base-font;
							    display: inline-block;
	    					}
	    					
	    					.value {
	    						@include Univers($UniversCondensed);
							    font-size: $base-font;
	    					}
	    				}
	    			}
	    			
	    			.col-2 {
						display: none;
	    			}
	    			
	    			.col-3 {
	    				width: 20%;
	    			}
	    			
	    			.col-4 {
					    width: 20%;
	    			}
	    		}
	    	}
		}
	}
	.pt_order-confirmation{ 
		.order-detail-section{ 
			span{
				&.img{
					&.cc-number-symbol{
						display: block;
						margin-bottom: 15px;
					}
				}
			}
			.cc-number{
				span{
					&.img{
						display: none;
					}
				}
			}
		}
		.confirmation-left-section{
			margin-top: 0;
			width: 100%;
		}
		.confirmation-message{
			padding-top: 5px;
		}
		.order-date{
			span{
				word-wrap: break-word;
			}
		}
		
	}
}
@media screen and (max-width: 767px) {
	.pt_order {
		.order-history {
			.order-history-table {
				.order-list-wrapper {
					.order-list {
						padding: 15px 13.5px;
						
						.order-number {
		    				@include Univers($UniversCondensed);
		    				.only-for-mobile {
		    					@include Univers($UniversCondensed);
		    				} 
						}
						.only-for-mobile {
							@include Univers($UniversBoldCondensed);
						}
					}
					
					.col-last {
						padding-top: 15px;
					}
				}
			}
		}
	}
	.pt_order-confirmation {
		.orderdetails {
			.login-create-account {
				.login-box-content {
					padding: 30px 10px;		
				}
			}
		}
		
		.order-status {
			margin: 0 0 10px 0;	
		}
		
		.profile-summary {
			.edit-profile {
				margin: 5px 0 0 0;	
			}
		}
		
		.order-confirmation-details {
			.order-print {
				.print-page {
					padding: 7px 0;
					float: none;
				}
			}
		}
		
		.order-detail-section{ 
			span{
				&.img{
					&.cc-number-symbol{
						display: none;
						margin-bottom: 0;
					}
				}
			}
			.cc-number{
				span{
					&.img{
						display: inline-block;
					}
				}
			}
		}
		.receipt-message {
			 font-size: $base-font - 1px;
		}
		.plcc-user {
			p {
				font-size: $base-font;	
			}
		}
		.confirmation-left-section {
			padding: 0 10px;
			h1 {
				font-size: $base-font + 6px;
			}
			.create-heading {
				@include Univers($UniversRoman);
				font-size: $base-font + 12px;
			}
		}
		.order-detail-section {
			.view-more-section {
				.view-more-link {
					@include Univers($UniversBoldCondensed);
					font-size: $base-font + 4px;
				}
			}
		}
		.order-shipment-table {
			.line-item-price {
				@include Univers($UniversCondensed);
				font-size: $base-font;
				.subtotal-label {
					@include Univers($UniversBoldCondensed);
					display: inline-block;
				}		
			}
		}
		.login-box-right-content {
		 	button {
		 		padding: 12.5px 48px;	
		 	}
		 }
		 .order-shipment-table {
		 	.line-items {
		 		.column {
		 			&.col-1 {
		 				.line-item-price {
		 					display: block;				
		 				}
		 			}
		 		}
		 	}
		 }
		 .orderdetails {
		 	.order-shipments {
		 		.order-shipment-address {
		 			margin: 0;		
		 		}
		 	}
		 }
		 .additional-offers {
		 	h2 {
		 		font-size: $base-font + 3px;
		 	}
		 }
	}
	
	.pt_order {
		.order-details-section {
			margin: 0 10px;
			
			.qa-section {
	    		width: 100%;
    			display: inline-block;
	    		
	    		.question-title {
	    			padding: 25px 20px;
	    		}
	    		
	    		.qa-content {
	    			padding: 20px;
	 	 			
	 	 			.question {
	 	 				font-size: $base-font - 1px;
	 	 			}
	 	 			
	 	 			.answer {
	 	 				font-size: $base-font - 2px;
	 	 			}
	    		}
	    	}
		}
		
		.orderdetails {
	    	margin: 15px auto 30px;
	    	
	    	.actions {
			    width: 100%;
			    
			    a {
	    	        width: 100%;
				    display: inline-block;
				    box-sizing: border-box;
				    -webkit-box-sizing: border-box;
 	 				-moz-box-sizing: border-box;
				    text-align: center;
				    color: $navy-blue;
				    border: 1px solid $navy-blue;
				    padding: 18px 30px;
			    }
	    	}
	    	
	    	.optioninfo {
	    		.option-info {
	    			font-family: $Helvetica;
	    			font-size: $base-font;
	    			color: $red;
	    			
	    			span {
	    				text-transform: capitalize;
	    				letter-spacing: 0;
	    			}
	    		}
	    	}
	    	
	    	.order-information {
	    		.order-status {
					
					.receipt-message {
						font-size: $base-font;
						margin: 10px 0 0 0;
					}
				}
	    	}
	    	
	    	.order-payment-summary {
	    		padding: 25px 20px;
	    		
	    		.order-detail-summary {
	    			width: 100%;
	    			float: left;
	    			max-width: 100%;
	    		}
	    		
	    		.actions {
	    			display: none;
	    		}
	    	}
	    	
	    	.order-detail-section {
	    		padding: 25px 20px;
    			margin: 20px 0;
	    		
    			&.order-detail-response {
			    	.section {
			    		width: 100%;	
			    	}
			    }
	    		
	    		.expanded-section {
	    			.method {
	    				width: 100%;
    					float: left;
    					
    					.shipment-method {
    						max-width: 100%;
    						margin-top: 30px;
    						
    						.heading {
							    margin: 0 0 10px 0;
							    font-size: $base-font;
							    font-family: $HelveticaMedium;
							    letter-spacing: 1px;
    						}
    					}
	    			}
	    		}
	    		
	    		.view-more-section {
    			    width: 100%;
				    float: left;
				    text-align: center;
				    font-size: $base-font;
				    text-transform: uppercase;
				    letter-spacing: 1px;
				    font-family: $HelveticaBold;
				    margin: 20px 0 0 0;
	    		}
	    		
	    		.order-status {
	    			&.section {
		    			.inner-block {
		    				margin-top: 0;
		    			}
	    			}
	    			
	    			.order-date {
	    				width: 50%;
    					float: left;
	    			}
	    			
	    			.total {
	    				width: 50%;
					    float: right;
					    margin: 0;
					    padding-left: 15px;
					    box-sizing: border-box;
					    -webkit-box-sizing: border-box;
 	 					-moz-box-sizing: border-box;
	    			}
	    		}
	    		
	    		.section {
	    			width: 100%;
	    			
	    			.inner-block {
	    				max-width: 100%;
	    				margin-top: 30px;
	    			}
	    			
	    			.heading {
	    				margin: 0 0 10px 0;
	    				font-size: $base-font;
	    				font-family: $HelveticaMedium;
	    				letter-spacing: 1px;
	    			}
	    			
	    			span {
	    				margin: 0 0 7px 0;
	    				font-size: $base-font - 1px;
	    				font-family: $Helvetica;
	    				letter-spacing: 1px;
	    			}
	    		}
	    		
	    		.order-payment-instruments{
					&.section{
						width: 100%;	
					}
				}
	    	}
	    	
	    	.order-shipments {
	    		.shipmentnumber {
	    			border: 0;
	    			padding: 0;
	    			
					h2 {
						font-size: $base-font + 4px;;
					    letter-spacing: 1px;
					    width: 100%;
					    margin: 10px 0 0 0;
					}
					
					.trackingnumber {
						width: 100%;
						font-size: $base-font - 1px;
						line-height: normal;
						letter-spacing: 1px;
						
						.label, .value {
							width: auto;
							display: inline-block;
						}
						
						.value {
							font-family: $HelveticaMedium;
							color: $wine-berry;
						}
					}
				}
	    		
	    		.order-shipment-details {
	    			padding-bottom: 20px;
	    			border-bottom: 1px solid $alto;
	    			margin-bottom: 10px;
	    			width: 100%;
    				float: left;
	    		}
	    			
	    		.order-shipment-section {
	    			font-size: $base-font - 1px;
	    			letter-spacing: 1px;
	    			margin: 0;
	    			
	    			.heading {
	    				text-transform: uppercase;
	    				width: auto;
    					display: inline-block;
    					margin: 15px 0 0 0;
	    			}
	    			
	    			.value {
	    				font-family: $HelveticaMedium;
	    				font-size: $base-font - 1px;
	    				width: auto;
    					display: inline-block;
	    			}
	    			
	    			.address {
	    				font-size: $base-font - 1px;
	    				font-family: $HelveticaMedium;
	    				width: auto;
    					display: inline-block;
    					padding: 0;
    					line-height: 20px;
	    			}
	    			
	    			.method, .order-shipping-status {
	    				width: 100%;
	    				
	    				.shipment-method {
	    					max-width: 100%;
	    				}
	    				
	    				.heading {
	    					&:after {
	    						content: ':';
	    					}
    					}
	    			}
	    			
	    			.order-shipment-address {
	    				width: 100%;
	    				
	    				.shipment-address {
	    					max-width: 100%;
	    					margin: 0;
	    					
	    					.heading {
		    					&:after {
		    						content: ':';
		    					}
	    					}
	    				}
	    			}
	    		}
	    		
	    		.line-item {
	    			padding: 10px 0 30px;
	    			border-top: 0;
	    			border-bottom: 1px solid $alto;
	    			
	    			.callout-message {
	    				&.hide-mobile {
	    					display: block !important;
	    				}
	    			}
	    			
	    			&:last-child {
	    				border-top: 0;
	    			}
	    			
	    			.col-1 {
	    				max-width: 100%;
	    				width: 100%;
	    				
	    				.line-item-price {
	    					display: none;
	    				}
	    				
	    				.line-item-quantity {
	    					margin-bottom: 9px;
						}
						
						.quick-order-badge-link .heading {
							display: block;
						}
	    			}
	    			
	    			.col-3 {
	    				max-width: 100%;
	    				width: 63%;
	    				float: right;
	    				margin: 60px 0 0 0;
	    				padding-left: 35px;
	    				@include prefix(box-sizing, border-box, $prefixArray);
	    				
	    				.heading {
	    					width: auto;
						    display: inline-block;
						    text-transform: uppercase;
						    letter-spacing: 1px;
						    margin: 0;
							font-size: $base-font - 1px;
	    				}
	    				
	    				.line-item-price {
	    					font-size: $base-font - 1px;
	    					width: auto;
    						display: inline-block;
	    					
	    					.product-option-price {
	    						display: inline-block;
	    					}
	    					
	    					&.discount-price {
	    						color: $red;
	    					}
	    				}
	    			}
	    			
	    			.col-4 {
					    max-width: 100%;
					    text-align: left;
					    width: 37%;
					    display: inline-block;
					    margin: 25px 0 0 0;
					    font-size: $base-font - 1px;
	    				letter-spacing: 1px;
	    				
	    				.buy-again {
	    					margin-top:24px;
	    				}
	    			}
	    		}
	    	}
	    	
	    	.order-payment-summary {
	    		.actions {
	    			float: left;
	    		}
	    	}
	    	
	    	.mini-address-name {
	    		margin: 0 0 5px 0;
				display: inline-block;
				width: 100%;
	    	}
	    	
	    	.mini-address-location {
		    	address {
				    font-style: normal;
					line-height: 25px;
		    	}
	    	}
		}
	}
	.pt_order-confirmation {
		.order-shipment-table{ 
			.line-items{ 
				.column{
					&.col-1{ 
						.line-item-price{
							.subtotal-discount{
								color: $red;
							}
						}
					}
				}
			}
		}
	}
}
//Order Confirmation Right Column
.pt_order-confirmation {	
	.confirmation-right-content {
		max-width: 280px;
	    float: right;
	    margin-top: 20px;
	    width: 23.1%;
	    
	}
}