.product-tile {
    padding: 5px 5px 17px 5px;
    float: none;
    .quickview {
        width: calc(100% - 20px);
        padding: 10px 0.5em;
        transform: translate(-50%);
        color: $navy-blue;
        @include Univers($UniversBoldCondensed);
        letter-spacing: 1px;
        border-radius: $KS-Btn-radius;
        border: 2px solid $navy-blue;
        font-size: $base-font + 8px;
        background: rgba(255, 255, 255, 0.75);
    }
    .b_product_badge {
        top: 0;
        span {
            text-align: left;
        }
    }
    .product-name {
        overflow: auto;
        @include Univers($UniversBoldCondensed);
        text-transform: uppercase;
        font-size: $base-font + 3px;
        color: $navy-blue;
        margin: 10px auto;
    }
    .product-pricing {
        @include Univers($UniversCondensed);
        font-size: $base-font + 2px;
        display: table;
        margin: 0 auto;
        border-spacing: 12px 0;
        .product-sales-price {
            display: block;
            @include Univers($UniversBoldCondensed);
            color: $red;
            &.product-actual-price {
                color: $navy-blue;
            }
        }
        .product-actual-price {
            color: $navy-blue;
        }
        .product-standard-price {
            text-decoration: line-through;
            color: $red;
            position: relative;
            margin: 0 auto;
            .price-product-standard-price {
                color: $navy-blue;
            }
        }
    }
    .product-promo {
        font-size: 0.8em;
        .promotional-message {
            @include Univers($UniversCondensed);
            color: $red;
            font-size: $base-font + 1px;
        }
    }
    .clearence-msg {
        font-size: $base-font;
    }
    .product-feature-messages {
        font-size: $base-font - 2px;
    }
    .product-special-messages {
        font-size: $base-font;
        @include Univers($UniversRoman);
    }
    .product-swatches {
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        ul {
            display: table;
            padding: 0;
            text-align: center;
            margin: 0 auto;
            max-width: 201px;
            li {
                padding: 2px 1px 2px 1px;
            }
        }
        .swatch {
            width: 45px;
            min-width: 19px;
            height: 20px;
            float: none;
            padding: 2px;
            position: relative;
            @media screen and (max-width: 767px) {
                width: 40px;
            }
            img,
            img:hover {
                border: 1px solid $KS-dark-grey;
                margin: 0 auto;
                display: table;
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                top: 3px;
                left: 3px;
                &[src$="noimagefound-pdp-swatch.png"] {
                    height: 100%;
                    transform: translateY(0);
                }
            }
            &.selected,
            &:hover {
                border: 1px solid $navy-blue;
            }
        }
        @media screen and (max-width: 767px) {
            width: 92%;
            .swatch {
                max-width: 100%;
            }
        }
        .product-swatches-all,
        .product-swatches-all-showless {
            font-family: $WorkSans;
            font-size: $base-font - 1px;
        }
    }
    .product-swatches {
        display: block;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        @media screen and (max-width: 767px) {
            width: 92%;
        }
        .hideswatch {
            position: relative;
        }
        &.show-color {
            .product-swatches-all {
                visibility: hidden;
            }
            .swatch-list {
                .hideswatch {
                    display: inline-block;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            max-width: 207px;
            padding: 0 0 0 5px;
            li {
                display: inline-flex;
                float: none;
                padding: 2px 0;
                &.hideswatch {
                    display: none;
                }
            }
        }
        .product-swatches-all,
        .product-swatches-all-showless {
            font-family: $WorkSans;
            font-size: 11px;
            letter-spacing: 1px;
            text-transform: uppercase;
            display: inline;
            word-break: break-word;
        }
        .product-swatches-all {
            top: 50px;
        }
        .swatches-six {
            @include Univers($UniversRoman);
            color: $navy-blue;
        }
        .swatches-five {
            margin-top: 7px;
        }
        .swatches-four {
            margin-top: auto;
            @media screen and (max-width: 767px) {
                @include Univers($UniversRoman);
                color: $navy-blue;
            }
        }
        li.hideswatch:nth-child(-n + 6) {
            display: none;
        }
        li.hideswatch:nth-child(-n + 4) {
            display: inline-block;
        }
        &.desktop-top-buffer {
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}
.search-result-items {
    .grid-tile {
        &.two-space-tile {
            background: $light-skygrey;
        }
        span {
            &.text1 {
                @include WorkSansMedium($WorkSans);
                font-size: 15px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
            &.text3 {
                @include LoraItalic($Lora);
                font-size: 24px;
            }
        }
        .text-info {
            position: absolute;
            bottom: 11%;
            text-align: center;
            color: $white;
            width: 100%;
        }
        .limited-off-tile {
            h1 {
                @include WorkSansBlack($WorkSans);
                letter-spacing: 5px;
                font-size: 70px;
                padding-left: 2%;
                margin: 0 0;
                line-height: 64px;
                span {
                    @include WorkSansThin($WorkSans);
                    font-size: 70px;
                    letter-spacing: 20px;
                    text-transform: uppercase;
                }
            }
        }
        .clearance-off-tile {
            .text-info {
                bottom: auto;
                top: 25%;
            }
            h1 {
                @include WorkSansBlack($WorkSans);
                letter-spacing: 5px;
                letter-spacing: 15px;
                font-size: 115px;
                line-height: 100px;
                margin: 0;
            }
            h2 {
                @include WorkSansThin($WorkSans);
                font-size: 115px;
                letter-spacing: 30px;
                line-height: 100px;
                margin: 0 0 3px 0;
            }
        }
        &.one-space-tile {
            background: $light-pink;
            background: -moz-linear-gradient(
                -45deg,
                $light-pink 0%,
                $light-pink 39%,
                $light-blue 73%,
                $light-blue 100%
            );
            background: -webkit-linear-gradient(
                -45deg,
                $light-pink 0%,
                $light-pink 39%,
                $light-blue 73%,
                $light-blue 100%
            );
            background: linear-gradient(
                135deg,
                $light-pink 0%,
                $light-pink 39%,
                $light-blue 73%,
                $light-blue 100%
            );
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$light-pink', endColorstr='$light-blue',GradientType=1 );
            .onespace-tile-content {
                margin-top: 34%;
            }
            span {
                font-size: $base-font + 4px;
                @include LoraItalic($Lora);
            }
            h1 {
                font-size: 30px;
                @include WorkSansBold($WorkSans);
                margin: 4px 0 16%;
                line-height: 33px;
            }
            p {
                font-family: $Lora;
                font-size: $base-font + 2px;
            }
            button {
                font-size: $base-font + 2px;
                @include WorkSansSemiBold($WorkSans);
                background: $navy-blue;
                width: 78%;
                margin-top: 38px;
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    .product-tile {
        .product-swatches {
            ul {
                max-width: 180px;
            }
            li.hideswatch:nth-child(-n + 7) {
                display: none;
            }
            li.hideswatch:nth-child(-n + 5) {
                display: none;
            }
            li.hideswatch:nth-child(-n + 3) {
                display: inline-block;
            }
            &.show-color {
                li.hideswatch:nth-child(-n + 7) {
                    display: inline-block;
                }
            }
            &.tablet-top-buffer {
                margin-top: auto !important;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .product-tile {
        .product-name {
            font-size: $base-font + 1px;
        }
        .product-pricing {
            font-size: $base-font + 2px;
            display: block;
        }
        .product-promo {
            .promotional-message {
                font-size: $base-font + 1px;
            }
        }
        .product-swatches {
            ul {
                max-width: 145px;
            }
            .swatch {
                margin: 0 auto;
            }
            li.hideswatch:nth-child(-n + 7) {
                display: none;
            }
            li.hideswatch:nth-child(-n + 6) {
                display: none;
            }
            li.hideswatch:nth-child(-n + 3) {
                display: inline-block;
            }
            li.hideswatch:nth-child(6) {
                display: none;
            }
            &.show-color {
                li.hideswatch:nth-child(6) {
                    display: inline-block;
                }
            }
            &.mobile-top-buffer {
                margin-top: auto !important;
            }
        }
    }
    //content tile design
    .search-result-items {
        .grid-tile {
            .text-info {
                bottom: 9%;
            }
            &.one-space-tile {
                p {
                    font-size: 12px;
                }
            }
            &.two-space-tile {
                .limited-off-tile {
                    h1 {
                        font-size: 42px;
                        line-height: 42px;
                        span {
                            font-size: 42px;
                        }
                    }
                }
                h1 {
                    font-size: 70px;
                    line-height: 60px;
                }
                h2 {
                    font-size: 70px;
                    line-height: 60px;
                }
                .clearance-off-tile {
                    .text-info {
                        top: 25%;
                    }
                }
            }
            span {
                &.text1 {
                    font-size: 9px;
                }
                &.text3 {
                    font-size: 15px;
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .search-result-items {
        .grid-tile {
            &.two-space-tile {
                .limited-off-tile {
                    h1 {
                        padding-left: 2%;
                    }
                }
                .clearance-off-tile {
                    h2 {
                        margin: 0 0 10px 10px;
                    }
                }
            }
        }
    }
}
.product-list-page
    .search-result-content
    .search-result-items
    li:nth-child(3n + 1)
    .product-tile {
    float: none;
}
@media screen and (min-width: 1024px) {
    .level-1.add-cqo > li:last-child() {
        display: none;
    }
}
